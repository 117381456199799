import ModeIcon from '@mui/icons-material/Mode';
import {Avatar, Badge, Button, CircularProgress, IconButton, Switch} from "@mui/material";
import React, {useEffect, useState} from 'react';
import {Trans, useTranslation} from "react-i18next";
import {useProfile} from '../../context/ProfileContext';
import {useRouter} from '../../context/RouterContext';
import {AvatarIcon} from '../../utils/assets/AvatarIcon';
import {UserAvatar} from "../../utils/model/UserAvatar";
import ErrorResponseDialog from '../station/components/ErrorResponseDialog';
import AvatarDialog from './components/AvatarDialog';
import DisplayNameDialog from './components/DisplayNameDialog';
import DialogLoader from "../common/DialogLoader";

const ProfileFinalize = () => {
    const {navigate} = useRouter();
    const {profile, updateProfile} = useProfile();
    const {t} = useTranslation();

    const [avatar, setAvatar] = useState<boolean>(false);
    const [displayName, setDisplayName] = useState<boolean>(false);
    const [internalProfile, setInternalProfile] = useState(profile);
    const [error, setError] = useState<TemplateStringsArray | string | undefined>();
    const [loading, setLoading] = useState(false);

    const onAvatar = () => setAvatar(!avatar)
    const onDisplayName = () => setDisplayName(!displayName)

    const onClickAvatar = (userAvatar: UserAvatar) => {
        if (internalProfile) {
            setInternalProfile({
                ...internalProfile,
                publicProfile: {...internalProfile.publicProfile, avatar: userAvatar}
            });
            setAvatar(false);
        }
    }

    const onUpdateDisplayName = (displayName: string) => {
        if (internalProfile) {
            setInternalProfile({...internalProfile, publicProfile: {...internalProfile.publicProfile, displayName}});
            setDisplayName(false);
        }
    }

    const onChangeAcceptFuelMailing = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (internalProfile) {
            setInternalProfile({...internalProfile, acceptFuelMailing: event.target.checked});
        }
    }

    const onChangeAcceptWinnerMailing = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (internalProfile) {
            setInternalProfile({...internalProfile, acceptWinnerMailing: event.target.checked});
        }
    }

    const onChangeAcceptGeneralConditions = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (internalProfile) {
            setInternalProfile({...internalProfile, acceptGeneralConditions: event.target.checked});
        }
    }

    useEffect(() => {
        if (profile && !internalProfile) {
            setInternalProfile({...profile, acceptGeneralConditions: false})
        }
    }, [internalProfile, profile]);

    const onSubmit = async () => {
        try {
            if (internalProfile) {
                internalProfile.isCompleted = true;
                setLoading(true);
                await updateProfile(internalProfile);
                setLoading(false);
                navigate('/profile-thanks');
            } else {
                setLoading(false);
                setError("Error while finalize profile.");
            }
        } catch (error: any) {
            setLoading(false);
            setError(error.message);
        }
    }

    return (
        <>
            {
                loading &&
                <DialogLoader loading={loading} onClose={() => setLoading(!loading)}/>
            }
            {
                error &&
                <ErrorResponseDialog errorCode={error as TemplateStringsArray} onClose={() => {
                    setError(undefined)
                }}/>
            }
            {
                !internalProfile &&
                <CircularProgress/>
            }
            {
                internalProfile &&
                <div className="profile">
                    <h1>{t('Finalize registration')}</h1>
                    <p>{t('Please choose the name + avatar that will appear publicly on the leaderboard')}</p>
                    <div className="avatar-username">
                        <div className="avatar">
                            <IconButton onClick={onAvatar}>
                                <Badge color="primary" badgeContent={<ModeIcon></ModeIcon>}>
                                    <Avatar src={AvatarIcon.forAvatar(internalProfile.publicProfile.avatar)}></Avatar>
                                </Badge>
                            </IconButton>
                            {avatar &&
                                <AvatarDialog onClose={onAvatar} onSelect={onClickAvatar}></AvatarDialog>}
                        </div>
                        <div className="username">
                            <Button onClick={onDisplayName} variant='text' color='secondary'>
                                <Badge sx={{ textTransform: 'none' }} color="primary" badgeContent={<ModeIcon></ModeIcon>}>
                                    {internalProfile?.publicProfile.displayName}
                                </Badge>
                            </Button>
                            {displayName &&
                                <DisplayNameDialog onClose={onDisplayName} onUpdate={onUpdateDisplayName}
                                                   defaultValue={internalProfile.publicProfile.displayName!}></DisplayNameDialog>}
                        </div>
                    </div>
                    <div className="checkboxes">
                        <div className="checkbox">
                            <Switch checked={internalProfile.acceptFuelMailing} onChange={onChangeAcceptFuelMailing}/>
                            {t('I agree to receive information related to the Gas Price advisor contest')}
                        </div>
                        <div className="checkbox">
                            <Switch checked={internalProfile.acceptWinnerMailing || false}
                                    onChange={onChangeAcceptWinnerMailing}/>
                            {t('I agree to receive an email if I win a prize in the Gas Price Advisor contest')}
                        </div>
                        <div className="checkbox">
                            <Switch checked={internalProfile.acceptGeneralConditions || false}
                                    onChange={onChangeAcceptGeneralConditions}/>
                            <div>
                                <Trans i18nKey={'general_conditions_full'}>
                                    Accept the <a href={t('general_conditions_link')}>general
                                    conditions</a>
                                </Trans>
                            </div>
                        </div>
                    </div>
                    <Button disabled={!internalProfile.acceptGeneralConditions || !internalProfile.acceptWinnerMailing}
                            color='secondary' variant='contained' onClick={onSubmit}>{t('Create profile')}</Button>
                </div>
            }
        </>
    )
}

export default ProfileFinalize
