import {CircularProgress, Dialog, DialogContent} from "@mui/material";
import React from "react";

interface Props {
    loading: boolean,
    onClose(...args: any): void
}

export default function DialogLoader({loading, onClose}: Props) {
    return (
        <Dialog
            open={loading}
            onClose={onClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description">
            <DialogContent>
                <CircularProgress style={{ display: "block", margin: "auto" }} color={"secondary"}
                                  thickness={5}></CircularProgress>
            </DialogContent>
        </Dialog>
    )
}