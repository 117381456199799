import {
    Button,
    CircularProgress,
    MenuItem,
    Select,
    Stack, Table, TableBody, TableCell,
    TableContainer, TableHead,
    TablePagination, TableRow,
    TextField
} from "@mui/material";
import React, {useEffect, useState} from "react";
import {BrandValues, Station} from "../../utils/model/Station";
import ErrorResponseDialog from "../station/components/ErrorResponseDialog";
import {tcsBlueGray1, Theme} from "../../mui/Theme";
import SearchIcon from "@mui/icons-material/Search";
import {
    collection, endBefore,
    getCountFromServer,
    getDocs,
    limit, limitToLast,
    orderBy,
    query,
    QueryDocumentSnapshot,
    startAfter,
    where
} from "firebase/firestore";
import {firestore} from "../../config/firebase";
import {Query} from "@firebase/firestore";
import {FuelTypeValues} from "../../utils/model/FuelType";
import DoneIcon from '@mui/icons-material/Done';
import CloseIcon from '@mui/icons-material/Close';
import StationConverter from "../../utils/converters/StationConverter";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import {useNavigate, useSearchParams} from "react-router-dom";
import {useTranslation} from "react-i18next";

export default function AdminFindStation() {
    const [params, setParams] = useState<any>({
        text: "",
        field: "displayName",
        brandName: ""
    })

    const {i18n} = useTranslation();

    const [stations, setStations] = useState<Station[]>([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState<TemplateStringsArray | undefined>();
    const navigate = useNavigate();
    const [textError, setTextError] = useState(false);
    const [page, setPage] = useState(0);
    const [count, setCount] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [, setSearchParams] = useSearchParams();

    const [lastVisibleSnapshot, setLastVisibleSnapshot] = useState<QueryDocumentSnapshot | null | undefined>();
    const [firstVisibleSnapshot, setFirstVisibleSnapshot] = useState<QueryDocumentSnapshot | null | undefined>();

    const getStationsQuery = (
        startAfterSnapshost: QueryDocumentSnapshot | null | undefined,
        endBeforeSnapshot: QueryDocumentSnapshot | null | undefined,
        perPage?: number
    ): Query => {
        let queryStations;
        const text = params.text !== '' ? params.text : params.brandName;
        if (startAfterSnapshost) {
            queryStations = query(
                collection(firestore, "stations").withConverter(StationConverter),
                where(params.field, ">=", text),
                where(params.field, "<=", text + '\uf8ff'),
                orderBy(params.field, "asc"),
                startAfter(startAfterSnapshost),
                limit(rowsPerPage)
            );
        } else if (endBeforeSnapshot) {
            queryStations = query(
                collection(firestore, "stations").withConverter(StationConverter),
                where(params.field, ">=", text),
                where(params.field, "<=", text + '\uf8ff'),
                orderBy(params.field, "asc"),
                endBefore(endBeforeSnapshot),
                limitToLast(rowsPerPage)
            );
        } else {
            if (perPage) {
                queryStations = query(
                    collection(firestore, "stations").withConverter(StationConverter),
                    where(params.field, ">=", text),
                    where(params.field, "<=", text + '\uf8ff'),
                    orderBy(params.field, "asc"),
                    limit(perPage)
                );
            } else {
                queryStations = query(
                    collection(firestore, "stations").withConverter(StationConverter),
                    where(params.field, ">=", text),
                    where(params.field, "<=", text + '\uf8ff'),
                    orderBy(params.field, "asc"),
                    limit(rowsPerPage)
                );
            }

        }
        return queryStations;
    };

    const getStations = async (query: Query) => {
        try {
            const stationsSnapshot = await getDocs(query);
            const stations = stationsSnapshot.docs.map(station => station.data()) as Station[];
            setStations(stations);
            setLastVisibleSnapshot(stationsSnapshot.docs[stationsSnapshot.docs.length - 1]);
            setFirstVisibleSnapshot(stationsSnapshot.docs[0]);
        } catch (error) {
            console.log(error);
        }
    };

    const onSearchStations = async (event?: any) => {
        setLastVisibleSnapshot(null);
        setFirstVisibleSnapshot(null);
        if (event) {
            event.preventDefault();
        }
        if (params.text === '' && params.brandName === '') {
            setTextError(true);
        } else {
            setStations([]);
            setLoading(true);
            setPage(0);
            try {
                const text = params.text !== '' ? params.text : params.brandName;
                const queryTotalStations = query(
                    collection(firestore, "stations").withConverter(StationConverter),
                    where(params.field, ">=", text),
                    where(params.field, "<=", text + '\uf8ff')
                );

                const totalStations = await getCountFromServer(queryTotalStations);
                setCount(totalStations.data().count);

                const queryStations = getStationsQuery(null, null);
                await getStations(queryStations);
                setSearchParams(params);
            } catch (error: any) {
                setError(error.message);
            }
            setLoading(false);
        }
    }

    const onPageChanged = async (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
        setLoading(true);
        setPage(newPage);
        try {
            const queryStations = getStationsQuery(
                newPage > page ? lastVisibleSnapshot : null,
                newPage < page ? firstVisibleSnapshot : null
            );

            await getStations(queryStations);
        } catch (error: any) {
            setError(error.message);
        }
        setLoading(false);
    }

    const handleChangeRowsPerPage = async (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    ) => {
        setLoading(true);
        setRowsPerPage(parseInt(event.target.value));
        setPage(0);
        setLastVisibleSnapshot(null);
        setFirstVisibleSnapshot(null);
        try {
            const queryStations = getStationsQuery(null, null, parseInt(event.target.value));
            await getStations(queryStations);
        } catch (error: any) {
            setError(error.message);
        }
        setLoading(false);
    };

    useEffect(() => {
        const queryParameters = new URLSearchParams(window.location.search);
        const text = queryParameters.get("text");
        const field = queryParameters.get("field");
        const brandName = queryParameters.get("brandName");

        const searchStations = async () => {
            await onSearchStations();
        }

        if ((text !== '' || brandName !== '') && field) {
            params.text = text;
            params.field = field;
            params.brandName = brandName;
            searchStations().catch(console.error);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Stack direction='column' height={'100%'} sx={{
            padding: "1rem"
        }}>
            {error &&
                <ErrorResponseDialog errorCode={error} onClose={() => {
                    setError(undefined);
                }}/>
            }
            <Stack sx={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
                borderRadius: '5px',
                background: tcsBlueGray1,
                padding: 1,
                mb: 2,
                gap: 1,
                justifyContent: "space-between"
            }}>


                <form onSubmit={onSearchStations}>
                    <Stack sx={{
                        display: "flex",
                        flexDirection: "row",
                        gap: 1
                    }}>
                        {params.field !== "brand" &&
                            <TextField error={textError} value={params.text} name={"text"} id="outlined-basic"
                                       variant="outlined"
                                       onChange={(e) => setParams({...params, text: e.target.value})}/>
                        }
                        <Select
                            name={"field"}
                            value={params.field}
                            onChange={(e) => setParams({...params, field: e.target.value})}>
                            <MenuItem value={"__name__"}>Id</MenuItem>
                            <MenuItem value={"displayName"}>Display Name</MenuItem>
                            <MenuItem value={"formattedAddress"}>Address</MenuItem>
                            <MenuItem value={"brand"}>Brand</MenuItem>
                        </Select>
                        {params.field === "brand" &&
                            <Select
                                name={"brand"}
                                value={params.brandName}
                                onChange={(e) => setParams({...params, brandName: e.target.value, text: ""})}>
                                {BrandValues.map(brand => {
                                    return <MenuItem key={"fuelType-" + brand} value={brand}>{brand}</MenuItem>
                                })}
                            </Select>
                        }
                        <Button variant="contained" color="secondary" type="submit">
                            <SearchIcon/>
                        </Button>
                    </Stack>
                </form>
            </Stack>
            {loading &&
                <CircularProgress
                    color="secondary"
                    size={64}
                    disableShrink
                    thickness={4}
                    sx={{
                        margin: "auto",
                    }}
                />
            }
            {!loading &&
                <Stack direction='column' gap={1} marginBottom={1}>
                    <TableContainer>
                        <Table size="small">
                            <TableHead>
                                <TableRow>
                                    <TableCell key={"id"}>Id</TableCell>
                                    <TableCell key={"displayName"} align="right">Display Name</TableCell>
                                    <TableCell key={"address"} align="right">Address</TableCell>
                                    <TableCell key={"brand"} align="right">Brand</TableCell>
                                    {FuelTypeValues.map(fuelType => {
                                        return <TableCell key={"fuelType-" + fuelType} align="right">{fuelType}</TableCell>
                                    })}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {stations.map((station: Station) => {
                                    return <TableRow key={station.id} sx={{
                                        cursor: "pointer", ":hover": {background: Theme.palette.primary.main},'&:last-child td, &:last-child th': {border: 0},
                                    }} onClick={() => navigate(`/${i18n.language}/station/${station.id}/${FuelTypeValues[0]}`)}>
                                        <TableCell key={"id-" + station.id} component="th" scope="row">
                                            {station.id}
                                        </TableCell>
                                        <TableCell key={"displayName-" + station.id} align="right">{station.displayName}</TableCell>
                                        <TableCell key={"address-" + station.id} align="right">{station.formattedAddress}</TableCell>
                                        <TableCell key={"brand-" + station.id} align="right">{station.brand}</TableCell>
                                        {FuelTypeValues.map(fuelType => {
                                            if (Object.keys(station.fuelCollection).includes(fuelType)) {
                                                return <TableCell key={"fuelType-" + station.id + "-" + fuelType} align="right">
                                                    <DoneIcon color="success"/>
                                                </TableCell>
                                            }
                                            return <TableCell key={"fuelType-" + station.id + "-" + fuelType} align="right">
                                                <CloseIcon color="error"/>
                                            </TableCell>
                                        })}
                                    </TableRow>
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>

                    <TablePagination
                        component="div"
                        count={count}
                        page={page}
                        onPageChange={onPageChanged}
                        rowsPerPage={rowsPerPage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </Stack>
            }
            <Stack sx={{
                flexDirection: "row",
                gap: 1
            }}>
                <Button
                    variant="contained"
                    type="button"
                    onClick={() => navigate("/admin")}
                >
                    <ArrowBackIcon />
                </Button>
            </Stack>
        </Stack>
    )
}