import React, {useEffect, useState} from "react";
import {Profile} from "../../utils/model/Profile";
import {
    Avatar,
    Button,
    CircularProgress,
    MenuItem,
    Select,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    TextField
} from "@mui/material";
import {tcsBlueGray1, Theme} from "../../mui/Theme";
import SearchIcon from "@mui/icons-material/Search";
import ErrorResponseDialog from "../station/components/ErrorResponseDialog";
import {AvatarIcon} from "../../utils/assets/AvatarIcon";
import {useProfile} from "../../context/ProfileContext";
import {
    collection,
    endBefore,
    getCountFromServer,
    getDocs,
    limit,
    limitToLast,
    orderBy,
    query,
    QueryDocumentSnapshot,
    startAfter,
    where
} from "firebase/firestore";
import {firestore} from "../../config/firebase";
import ProfileConverter from "../../utils/converters/ProfileConverter";
import {Query} from "@firebase/firestore";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import {useNavigate, useSearchParams} from "react-router-dom";
import {UserType} from "../../utils/model/UserType";
import CloseIcon from "@mui/icons-material/Close";

export default function AdminFindUser() {
    const {printDisplayName} = useProfile();

    const [params, setParams] = useState<any>({
        text: "",
        field: "tcsRef"
    })

    const [users, setUsers] = useState<Profile[]>([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState<TemplateStringsArray | undefined>();
    const navigate = useNavigate();
    const [textError, setTextError] = useState(false);
    const [page, setPage] = useState(0);
    const [count, setCount] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [, setSearchParams] = useSearchParams();

    const [lastVisibleSnapshot, setLastVisibleSnapshot] = useState<QueryDocumentSnapshot | null | undefined>();
    const [firstVisibleSnapshot, setFirstVisibleSnapshot] = useState<QueryDocumentSnapshot | null | undefined>();

    const getUsersQuery = (
        startAfterSnapshost: QueryDocumentSnapshot | null | undefined,
        endBeforeSnapshot: QueryDocumentSnapshot | null | undefined,
        perPage?: number
    ): Query => {
        let queryUsers;
        if (startAfterSnapshost) {
            queryUsers = query(
                collection(firestore, "profiles").withConverter(ProfileConverter),
                where(params.field, ">=", params.text),
                where(params.field, "<=", params.text + '\uf8ff'),
                orderBy(params.field, "asc"),
                startAfter(startAfterSnapshost),
                limit(rowsPerPage)
            );
        } else if (endBeforeSnapshot) {
            queryUsers = query(
                collection(firestore, "profiles").withConverter(ProfileConverter),
                where(params.field, ">=", params.text),
                where(params.field, "<=", params.text + '\uf8ff'),
                orderBy(params.field, "asc"),
                endBefore(endBeforeSnapshot),
                limitToLast(rowsPerPage)
            );
        } else {
            if (perPage) {
                queryUsers = query(
                    collection(firestore, "profiles").withConverter(ProfileConverter),
                    where(params.field, ">=", params.text),
                    where(params.field, "<=", params.text + '\uf8ff'),
                    orderBy(params.field, "asc"),
                    limit(perPage)
                );
            } else {
                queryUsers = query(
                    collection(firestore, "profiles").withConverter(ProfileConverter),
                    where(params.field, ">=", params.text),
                    where(params.field, "<=", params.text + '\uf8ff'),
                    orderBy(params.field, "asc"),
                    limit(rowsPerPage)
                );
            }

        }
        return queryUsers;
    };

    const getUsers = async (query: Query) => {
        try {
            const usersSnapshot = await getDocs(query);
            const profiles = usersSnapshot.docs.map(profile => profile.data()) as Profile[];
            setUsers(profiles);
            setLastVisibleSnapshot(usersSnapshot.docs[usersSnapshot.docs.length - 1]);
            setFirstVisibleSnapshot(usersSnapshot.docs[0]);
        } catch (error) {
            console.log(error);
        }
    };

    const onSearchUsers = async (event?: any) => {
        setLastVisibleSnapshot(null);
        setFirstVisibleSnapshot(null);
        if (event) {
            event.preventDefault();
        }
        if (params.text === '') {
            setTextError(true);
        } else {
            setUsers([]);
            setLoading(true);
            setPage(0);
            try {
                const queryTotalUsers = query(
                    collection(firestore, "profiles").withConverter(ProfileConverter),
                    where(params.field, ">=", params.text),
                    where(params.field, "<=", params.text + '\uf8ff')
                );

                const totalUsers = await getCountFromServer(queryTotalUsers);
                setCount(totalUsers.data().count);

                const queryUsers = getUsersQuery(null, null);
                await getUsers(queryUsers);
                setSearchParams(params);
            } catch (error: any) {
                setError(error.message);
            }
            setLoading(false);
        }
    }

    const onPageChanged = async (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
        setLoading(true);
        setPage(newPage);
        try {
            const queryUsers = getUsersQuery(
                newPage > page ? lastVisibleSnapshot : null,
                newPage < page ? firstVisibleSnapshot : null
            );

            await getUsers(queryUsers);
        } catch (error) {
            console.log(error);
        }
        setLoading(false);
    }

    const handleChangeRowsPerPage = async (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setLoading(true);
        setRowsPerPage(parseInt(event.target.value));
        setPage(0);
        setLastVisibleSnapshot(null);
        setFirstVisibleSnapshot(null);
        try {
            const queryUsers = getUsersQuery(null, null, parseInt(event.target.value));
            await getUsers(queryUsers);
        } catch (error) {
            console.log(error);
        }
        setLoading(false);
    };

    const displayBan = (user: Profile) => {
        if (user.type === UserType.SUSPECT) {
            return user.banReason ?? 'UNDEFINED';
        }
        return <CloseIcon color="error"/>;
    }

    useEffect(() => {
        const queryParameters = new URLSearchParams(window.location.search);
        const text = queryParameters.get("text");
        const field = queryParameters.get("field");

        const searchUsers = async () => {
            await onSearchUsers();
        }

        if (text !== "" && field) {
            params.text = text;
            params.field = field;
            searchUsers().catch(console.error);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Stack direction='column' height={'100%'} sx={{
            padding: "1rem"
        }}>
            {error &&
                <ErrorResponseDialog errorCode={error} onClose={() => {
                    setError(undefined);
                }}/>
            }
            <Stack sx={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
                borderRadius: '5px',
                background: tcsBlueGray1,
                padding: 1,
                mb: 2,
                gap: 1,
                justifyContent: "space-between"
            }}>
                <form onSubmit={onSearchUsers}>
                    <Stack sx={{
                        display: "flex",
                        flexDirection: "row",
                        gap: 1
                    }}>
                        <TextField error={textError} value={params.text} name={"text"} id="outlined-basic"
                                   variant="outlined"
                                   onChange={(e) => setParams({...params, text: e.target.value})}/>
                        <Select
                            name={"field"}
                            value={params.field}
                            onChange={(e) => setParams({...params, field: e.target.value})}>
                            <MenuItem value={"tcsRef"}>TCS Ref</MenuItem>
                            <MenuItem value={"publicProfile.displayName"}>Display Name</MenuItem>
                            <MenuItem value={"email"}>Email</MenuItem>
                        </Select>
                        <Button variant="contained" color="secondary" type="submit">
                            <SearchIcon/>
                        </Button>
                    </Stack>
                </form>
            </Stack>
            {loading &&
                <CircularProgress
                    color="secondary"
                    size={64}
                    disableShrink
                    thickness={4}
                    sx={{
                        margin: "auto",
                    }}
                />
            }
            {!loading &&
                <Stack direction='column' gap={1} marginBottom={1}>
                    <TableContainer>
                        <Table size="small">
                            <TableHead>
                                <TableRow>
                                    <TableCell>Avatar</TableCell>
                                    <TableCell align="right">Id</TableCell>
                                    <TableCell align="right">TCS Ref</TableCell>
                                    <TableCell align="right">Display Name</TableCell>
                                    <TableCell align="right">Ban</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {users.map((user: Profile) => {
                                    return <TableRow key={user.id} sx={{
                                        cursor: "pointer", ":hover": {background: Theme.palette.primary.main},'&:last-child td, &:last-child th': {border: 0},
                                    }} onClick={() => navigate("/admin/user/" + user.id)}>
                                        <TableCell component="th" scope="row">
                                            <Avatar sizes="small" alt={printDisplayName(user.publicProfile)}
                                                    src={AvatarIcon.forAvatar(user.publicProfile?.avatar)}/>
                                        </TableCell>
                                        <TableCell align="right">{user.id}</TableCell>
                                        <TableCell align="right">{user.tcsRef}</TableCell>
                                        <TableCell align="right">{user.publicProfile.displayName}</TableCell>
                                        <TableCell align="right">{displayBan(user)}</TableCell>
                                    </TableRow>
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <TablePagination
                        component="div"
                        count={count}
                        page={page}
                        onPageChange={onPageChanged}
                        rowsPerPage={rowsPerPage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </Stack>
            }
            <Stack sx={{
                flexDirection: "row",
                gap: 1
            }}>
                <Button
                    variant="contained"
                    type="button"
                    onClick={() => navigate("/admin")}
                >
                    <ArrowBackIcon/>
                </Button>
            </Stack>
        </Stack>
    )
}