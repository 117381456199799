import {CircularProgress} from "@mui/material"
import {ReactComponent as LocateMeLogo} from './locateme.svg';


interface Props {
    onClick(): void;
    loading: boolean;
}

function CenterOnMe({onClick, loading}: Props) {
    return (
        <div className="gmapcontainer" onClick={onClick}>
            {!loading && <LocateMeLogo className="locatemelogo" width={28} height={28}/>}
            {loading && <CircularProgress
                className="gmapbutton"
                disableShrink
                color={"secondary"}
                size={"24px"}
                sx={{p: "2px", m: 0, minWidth: 0, minHeight: 0}}
                thickness={8}
            ></CircularProgress>}
        </div>
    )
}

export default CenterOnMe
