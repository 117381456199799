import { Typography } from '@mui/material'
import { useTranslation } from 'react-i18next';

const LegalMentionsTitle = () => {
  const { t } = useTranslation();
  return (
    <Typography variant="h1">
      {t('Mentions Title')}
    </Typography>
  )
}

export default LegalMentionsTitle
