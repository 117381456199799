import {useRouter} from "../../context/RouterContext";
import {Button, CircularProgress, Grid, Stack, Typography} from "@mui/material";
import {useCollectionOnce} from "react-firebase-hooks/firestore";
import {collection, orderBy, query} from "firebase/firestore";
import {firestore} from "../../config/firebase";
import ContestConverter from "../../utils/converters/ContestConverter";
import React, {useEffect, useState} from "react";
import {Contest} from "../../utils/model/Contest";
import {tcsBlueGray1} from "../../mui/Theme";
import {useTranslation} from "react-i18next";
import moment from "moment";
import {langFromi18nLang} from "../../utils/helpers/Lang";
import EditIcon from "@mui/icons-material/Edit";

export default function AdminActiveContests() {
    const {navigate} = useRouter();

    const {i18n} = useTranslation();
    moment.locale(i18n.language);
    const lang = langFromi18nLang(i18n.language)

    const [contests, setContests] = useState<Contest[] | undefined>();
    const [contestsSnapshot, loadingContests] = useCollectionOnce(query(
        collection(firestore, "contests").withConverter(ContestConverter),
        orderBy("startDate", "desc")
    ));

    useEffect(() => {
        if (contestsSnapshot) {
            setContests(contestsSnapshot.docs
                .map(contest => contest.data() as Contest)
                .filter((contest: Contest) => contest.endDate > new Date()) as Contest[]);
        }
    }, [contestsSnapshot]);

    return (
        <>
            <Stack direction='column' height={'100%'} sx={{
                padding: "1rem"
            }}>
                {loadingContests &&
                    <CircularProgress
                        color="secondary"
                        size={64}
                        disableShrink
                        thickness={4}
                        sx={{
                            margin: "auto",
                        }}
                    />
                }
                {!loadingContests && contests &&
                    <Grid container direction="column" rowSpacing={2} marginBottom={1}>
                        {contests.map((contest: Contest) => {
                            return <Grid item key={contest.id}>
                                <Stack key={contest.id} justifyContent='flex-end' sx={{
                                    width: "100%",
                                    display: "flex",
                                    flexDirection: "row",
                                    borderRadius: '5px',
                                    background: tcsBlueGray1,
                                    justifyContent: "space-between",
                                    padding: 1
                                }}>
                                    <Stack gap={1} alignItems={'center'} sx={{
                                        display: "flex",
                                        flexDirection: "row"
                                    }}>
                                        <Typography variant="h4">{contest[`displayName_${lang}`]}</Typography>
                                        <Typography>{moment(contest.startDate).format('L')} - {moment(contest.endDate).format('L')}</Typography>
                                    </Stack>
                                    <Stack gap={1} sx={{
                                        display: "flex",
                                        flexDirection: "row"
                                    }}>
                                        <Button
                                            variant={'contained'}
                                            color={'warning'}
                                            onClick={() => navigate("/admin/contest/update/" + contest.id)}>
                                            <EditIcon/>
                                        </Button>
                                    </Stack>
                                </Stack>
                            </Grid>
                        })}
                    </Grid>
                }
                <Stack sx={{
                    flexDirection: "row",
                    gap: 1
                }}>
                    <Button
                        variant="contained"
                        type="button"
                        onClick={() => navigate("/admin")}
                    >
                        {"Back"}
                    </Button>
                </Stack>
            </Stack>
        </>
    )
}