import {Button, Dialog, DialogActions, DialogContent, DialogTitle} from "@mui/material"

import {useTranslation} from "react-i18next"
import {useAuth} from "../../../context/AuthContext"
import {useProfile} from "../../../context/ProfileContext"
import {useRouter} from "../../../context/RouterContext"
import {sendDeleteUser} from "../../../utils/Api"
import React, {useState} from "react";
import DialogLoader from "../../common/DialogLoader";
import ErrorResponseDialog from "../../station/components/ErrorResponseDialog";

interface Props {
    onClose(): void
}

const DeleteDialog = ({onClose}: Props) => {
    const {t} = useTranslation();

    const {logout} = useAuth();
    const {profile, printDisplayName} = useProfile();
    const {navigate} = useRouter();

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState<TemplateStringsArray | undefined>();

    const internalClose = () => {
        onClose();
    }

    const onDelete = async () => {
        setLoading(true);
        try {
            await sendDeleteUser();
        } catch (error: any) {
            setError(error.message);
        }
        setLoading(false);
        logout();
        navigate();
    }

    return (
        <>
            {
                loading &&
                <DialogLoader loading={loading} onClose={() => setLoading(!loading)}/>
            }
            {
                error &&
                <ErrorResponseDialog errorCode={error} onClose={() => setError(undefined)}/>
            }
            <Dialog open={true} onClose={internalClose} className="dialog">
                <DialogTitle>
                    <div className='dialog-title'>
                        <div className='dialog-title-left'>
                            {t('Delete account')}
                            {profile && <>{printDisplayName(profile.publicProfile)}</>}
                        </div>
                    </div>
                </DialogTitle>
                <DialogContent>

                    {t('Delete message')}
                </DialogContent>
                <DialogActions>
                    <Button variant="outlined" color="secondary" onClick={internalClose}>{t('Close')}</Button>
                    <Button variant="contained" color="secondary" onClick={onDelete}>{t('Delete account')}</Button>
                </DialogActions>
            </Dialog>
        </>
    )
}

export default DeleteDialog
