import { WithFieldValue, DocumentData, QueryDocumentSnapshot, SnapshotOptions } from "firebase/firestore";
import { Profile } from "../model/Profile";
import PublicProfileConverter from "./PublicProfileConverter";
import DateConverter from "./DateConverter";

export const ProfileConverter = {
  toFirestore(profile: WithFieldValue<Profile>): DocumentData {
    return ProfileConverter.toData(profile)
  },
  toData(data: any): Profile{
    return { ...data, publicProfile: PublicProfileConverter.toData(data.publicProfile) }
  },
  fromFirestore(
    snapshot: QueryDocumentSnapshot,
    options: SnapshotOptions
  ): Profile {
    const data = snapshot.data();
    if (data.banDate) {
      return {
        ...data,
        id: snapshot.id,
        publicProfile: PublicProfileConverter.fromData(data.publicProfile),
        banDate: DateConverter.fromData(data.banDate)
      } as Profile
    } else {
      return {
        ...data,
        id: snapshot.id,
        publicProfile: PublicProfileConverter.fromData(data.publicProfile)
      } as Profile
    }
  }
}
export default ProfileConverter
