import { Chip, Paper, Typography } from "@mui/material"
import Grid from "@mui/material/Unstable_Grid2"
import { useTranslation } from "react-i18next"
import { Station } from "../../utils/model/Station"
import { Cluster } from "../../utils/MapTypes"
import { getDisplayPrice } from "../../utils/helpers/LocalPrice"
import { FuelType } from "../../utils/model/FuelType"
import { Stack } from "@mui/system"
import { lightGreenBackgroundColor, fontFamilyMuseo700 } from "../../mui/Theme"
import { Color } from "../../utils/assets/ColorFromFiability"
import moment from "moment"
import { momentRelativeTimeTranslationsForLocale } from "../../utils/helpers/MomentRelativeTimeTranslation"

interface Props {
  lat: number
  lng: number

  cluster: Cluster;

  cheapestStation?: Station,
  fuelType: FuelType;

  isCheapest: boolean;

  onClusterClick: CallableFunction
}

const GMapCluster = ({ cluster, cheapestStation, fuelType, onClusterClick, isCheapest }: Props) => {
  const { t, i18n } = useTranslation();
  const pointCount = cluster.properties.point_count;

  const onClick = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    onClusterClick({ e, cluster })
  }

  const fuelToDisplay = cheapestStation?.fuelCollection[fuelType]
  const priceToDisplay = fuelToDisplay ? getDisplayPrice({ station: cheapestStation, fuel: fuelToDisplay, fuelType: fuelToDisplay.type }) : null

  const fiabilityColor = Color.forFiabilityLevel(fuelToDisplay?.fiability.level)
  const border = fuelToDisplay ? 2 : 0;
  const background = isCheapest ? lightGreenBackgroundColor : "white"

  const displayCount = pointCount < 99 ? pointCount.toString() : '99+';

  moment.locale(i18n.language, momentRelativeTimeTranslationsForLocale(i18n.language));
  const momentLastPriceUpdate = moment(fuelToDisplay?.fiability.lastPriceUpdate).fromNow(true)

  const chipColor = isCheapest ? "success" : "primary"

  return (
    <div onClick={onClick} className={"cluster"}>
      <div className="cluster-inner">
        <Paper elevation={12} sx={{
          background: background,
          p: 0.4,
          borderRadius: '32px',
          border: border,
          borderColor: fiabilityColor
        }}>
          <Grid container gap={0.5} wrap='nowrap' alignItems={'center'}>
            <Grid>
              <Chip label={displayCount}
                onClick={onClick}
                color={chipColor}
                sx={{
                  fontSize: 14,
                  fontFamily: fontFamilyMuseo700,
                }}
              />
            </Grid>
            {fuelToDisplay &&
              <Stack mr={0.75} gap={0.2}>
                <Typography mb={0} fontSize={10} noWrap lineHeight={1}>
                  {t('cheapestPrice')}
                </Typography>

                <Stack direction={'row'} alignItems={'flex-end'} gap={0.25}>
                  <Stack direction={'row'} alignItems={'baseline'} gap={0.5}>
                    <Typography fontSize={14} fontFamily={fontFamilyMuseo700} lineHeight={1.2}>
                      {priceToDisplay}
                    </Typography>
                    <Typography mb={0} fontSize={10} noWrap lineHeight={1} color={fiabilityColor} fontWeight={'bold'}>
                      {momentLastPriceUpdate}
                    </Typography>

                  </Stack>
                 
                </Stack>
              </Stack>
            }
          </Grid>
        </Paper>
      </div>
    </div >
  )
}

export default GMapCluster
