import {
    signInWithCustomToken
} from 'firebase/auth';
import React, {createContext, useContext, useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import {Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText} from "@mui/material";
import {useMsal} from "@azure/msal-react";
import {auth} from "../config/firebase";
import {ClientAuthError, InteractionRequiredAuthError} from "@azure/msal-browser";
import {createOrGetProfile} from "../utils/Api";
import {Profile} from "../utils/model/Profile";
import {FirebaseError} from "@firebase/util";
import {AuthErrorCodes} from "@firebase/auth";
import {SilentRequest} from "@azure/msal-browser/src/request/SilentRequest";

const AuthContext = createContext<any>({});

export const useAuth = () => useContext(AuthContext);

const API_GET_CUSTOM_TOKEN = process.env.REACT_APP_URL_GET_CUSTOM_TOKEN ?? "";

const AZURE_CLIENT_ID = process.env.REACT_APP_AZURE_CLIENT_ID ?? "";
const AZURE_SCOPES = ["openid", AZURE_CLIENT_ID, "offline_access"];

let loginRequestObject = {
    scopes: AZURE_SCOPES,
    extraQueryParameters: {}
};

const tokenRequestObject = {
    scopes: AZURE_SCOPES
}

export const AuthContextProvider = ({children}: { children: React.ReactNode }) => {
    const [userUid, setUserUid] = useState<string | null>(null);
    const [loading, setLoading] = useState(false);
    const {i18n} = useTranslation();
    const [error, setError] = useState<string | null>();
    const {instance, accounts} = useMsal();

    const tcsSignIn = async () => {
        loginRequestObject.extraQueryParameters = {"ui_locales": i18n.language};
        try {
            await instance.loginRedirect(loginRequestObject)
        } catch(error) {
            setError("Error while sign in with Azure B2C.");
            console.log(error);
        }
    }

    const handleSignInError = (error: any) => {
        if (error instanceof FirebaseError) {
            switch (error.code) {
                case AuthErrorCodes.INVALID_CUSTOM_TOKEN:
                    setError("The provided token is invalid or expired.");
                    break;
                case AuthErrorCodes.CREDENTIAL_MISMATCH:
                    setError("The custom token is for a different Firebase project.");
                    break;
                case AuthErrorCodes.TOKEN_EXPIRED:
                    setError("The provided token expired.");
                    break;
                default:
                    setError(`${error.code}: ${error.message}`);
                    break;
            }
        } else {
            setError("Error while signing in with Firebase.");
        }
    }

    const logout = async () => {
        auth.signOut().then(async () => {
            setLoading(true);
            try {
                await instance.logoutRedirect({
                    postLogoutRedirectUri: document.location.origin,
                });
            } catch (error) {
                console.error(error);
                setError("Error while logout with Azure. Please retry.");
            }
            setUserUid(null);
            const fuelType = localStorage.getItem('my-fuel');
            localStorage.clear();
            if (fuelType) {
                localStorage.setItem('my-fuel', fuelType);
            }
            setLoading(false);
        }).catch((error) => {
            console.error(error);
            setError("Error while logout with Firebase. Please retry.");
        });
    }

    useEffect(() => {
        if (accounts.length > 0) {
            const getAccessTokenAndSignInWithFirebase = async () => {
                setLoading(true);

                // 1 - Get Azure token
                let azureData;
                const accessTokenRequest: SilentRequest = {...tokenRequestObject, account: accounts[0]};
                try {
                    azureData = await instance.acquireTokenSilent(accessTokenRequest);
                } catch (error) {
                    if (error instanceof InteractionRequiredAuthError) {
                        const newAccessTokenRequest: SilentRequest = {...accessTokenRequest};
                        if (error.claims) {
                            newAccessTokenRequest.claims = error.claims;
                        }
                        await instance.acquireTokenRedirect({...tokenRequestObject, account: accounts[0]});
                    } else if (error instanceof ClientAuthError) {
                        setError("Error while logging. Please retry.");
                    }
                    else {
                        setError("Error while getting token with Azure.");
                    }
                    setLoading(false);
                    return;
                }

                // 2 - Get custom token from API Gateway
                let customToken;
                try {
                    const res = await fetch(API_GET_CUSTOM_TOKEN, {
                        headers: {Authorization: "Bearer " + azureData.accessToken}
                    });
                    const response = await res.json();
                    customToken = response.customToken;
                } catch (error) {
                    setError("Error while fetching custom token from server.");
                    setLoading(false);
                    return;
                }

                // 3 - Sign In with custom token on Firebase
                try {
                    await signInWithCustomToken(auth, customToken);
                } catch (error) {
                    handleSignInError(error);
                    setLoading(false);
                    return;
                }

                // 4 - Get profile from Firebase
                try {
                    const profile = await createOrGetProfile() as Profile;
                    setUserUid(profile.id);
                } catch (error) {
                    setError("Error while fetching or creating profile.");
                    setUserUid(null);
                }

                setLoading(false);
            }
            getAccessTokenAndSignInWithFirebase();
        }
    }, [accounts, instance]);

    return (
        <AuthContext.Provider value={{user: userUid, logout, tcsSignIn}}>
            {error &&
                <Dialog
                    open={true}
                    onClose={() => setError(null)}
                    className='dialog'>
                    <DialogContent>
                        <DialogContentText>
                            {error}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button variant="contained" color="secondary" onClick={() => setError(null)}>Close</Button>
                    </DialogActions>
                </Dialog>
            }
            {loading &&
                <Dialog
                    open={loading}
                    onClose={() => setLoading(!loading)}>
                    <DialogContent>
                        <CircularProgress style={{display: "block", margin: "auto", color: "#005aa0"}}
                                          thickness={5}></CircularProgress>
                    </DialogContent>
                </Dialog>
            }
            {!loading && !error &&
                children
            }
        </AuthContext.Provider>
    )
}
