import {ChevronRight} from '@mui/icons-material'
import {Link, ListItem, Stack, Typography} from '@mui/material'
import Button from '@mui/material/Button'
import moment from 'moment'
import React, {useState} from 'react'
import {useTranslation} from 'react-i18next'
import {useParams} from "react-router-dom"
import {useAuth} from '../../context/AuthContext'
import {useProfile} from '../../context/ProfileContext'
import {ReactComponent as CheckIcon} from '../../icons/Icon_Check.svg'
import {ReactComponent as PenIcon} from '../../icons/Icon_Pen.svg'
import {deleteFuelAdmin, sendCorrectionUserAction, sendUserAction} from "../../utils/Api"
import {Color} from '../../utils/assets/ColorFromFiability'
import {canContribute, canCorrect, getDisplayPrice, nextParticipation} from '../../utils/helpers/LocalPrice'
import {Fuel} from '../../utils/model/Fuel'
import {Location} from "../../utils/model/Location"
import {Reward} from "../../utils/model/Reward"
import {Station} from '../../utils/model/Station'
import {UserActionRequestDTO} from "../../utils/model/UserAction"
import {UserActionType} from "../../utils/model/UserActionType"
import DialogLoader from "../common/DialogLoader"
import Feedback from "../navigation/Feedback"
import ConfirmPriceDialog from './components/ConfirmPriceDialog'
import ErrorResponseDialog from "./components/ErrorResponseDialog"
import FiabilityChip from './components/FiabilityChip'
import LoginDialog from './components/LoginDialog'
import PriceHistoryDialog from './components/PriceHistoryDialog'
import UpdatePriceDialog from './components/UpdatePriceDialog'
import CorrectionDialog from "./components/CorrectionDialog";
import {FuelType} from "../../utils/model/FuelType";
import {tcsBeige3, Theme} from '../../mui/Theme';
import {UserType} from "../../utils/model/UserType";
import DeleteIcon from '@mui/icons-material/Delete';
import ValidateDeleteFuelAdminDialog from "../admin/ValidateDeleteFuelAdminDialog";
import OperationAdminDialog from "../admin/OperationAdminDialog";

interface Props {
    station: Station,
    fuel: Fuel,
}

const GasPrice = ({station, fuel}: Props) => {
    const fuelType = fuel.type

    const {t, i18n} = useTranslation()
    const {user} = useAuth()
    const {profile} = useProfile()

    const [openLogin, setOpenLogin] = useState(false);
    const [openUpdate, setOpenUpdate] = useState(false);
    const [openConfirm, setOpenConfirm] = useState(false);
    const [openCorrection, setOpenCorrection] = useState(false);
    const [openPriceHistory, setOpenPriceHistory] = useState(false);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState<TemplateStringsArray | undefined>();
    const [reward, setReward] = useState<Reward | null>(null);
    const [resultResponse, setResultResponse] = useState(false);
    const [correction, setCorrection] = useState(false);
    const [resultAdmin, setResultAdmin] = useState(false);
    const [messageAdmin, setMessageAdmin] = useState("");
    const [openValidateAdmin, setOpenValidateAdmin] = useState(false);

    const {fuelId} = useParams();

    const onConfirm = (_e: React.MouseEvent<HTMLElement>) => {
        setOpenConfirm(!openConfirm);
    }

    const onUpdate = (_e: React.MouseEvent<HTMLElement>) => {
        setOpenUpdate(!openUpdate);
    }

    const onCorrection = (_e: React.MouseEvent<HTMLElement>) => {
        setOpenCorrection(!openCorrection);
    }

    const onLogin = (_e: React.MouseEvent<HTMLElement>) => {
        setOpenLogin(!openLogin);
    }

    const onPriceHistory = (_e: React.MouseEvent<HTMLElement>) => {
        setOpenPriceHistory(!openPriceHistory);
    }

    const onDeleteFuel = (_e: React.MouseEvent<HTMLElement>) => {
        setOpenValidateAdmin(!openValidateAdmin);
    }

    const attributes = {
        station,
        fuel,
        fuelType
    }
    moment.locale(i18n.language)
    const momentLastPriceUpdate = moment(fuel.fiability.lastPriceUpdate)

    const isSelectedFuel = fuelType === fuelId
    const fiabilityColor = Color.forFiabilityLevel(fuel.fiability.level)
    const borderColor = isSelectedFuel ? fiabilityColor : "#e9f1f5"

    const className = (): string => {
        let className = ""
        if (!canContribute({profile, station, fuelType})) {
            className += 'blocked-fuel'
        }
        if (isSelectedFuel) {
            className += ' selected-fuel'
        }
        return className
    }

    const onConfirmPriceConfirmation = (e: React.MouseEvent<HTMLElement>, coords: { latitude: number, longitude: number }) => {
        setOpenConfirm(false);

        const location: Location = {
            lat: coords?.latitude!,
            lng: coords?.longitude!
        }

        const userActionRequestDTO: UserActionRequestDTO = {
            stationId: station.id,
            actionType: UserActionType.CONFIRM_PRICE,
            fuelType: fuelType,
            price: fuel.displayPrice,
            location: location
        }

        createUserAction(userActionRequestDTO);
    }

    const onUpdatePriceConfirmation = (e: React.MouseEvent<HTMLElement>, coords: { latitude: number, longitude: number }, price: number) => {
        setOpenUpdate(false);

        const location: Location = {
            lat: coords?.latitude!,
            lng: coords?.longitude!
        }

        const userActionRequestDTO: UserActionRequestDTO = {
            stationId: station.id,
            actionType: UserActionType.UPDATE_PRICE,
            fuelType: fuelType,
            price: price,
            location: location
        }

        createUserAction(userActionRequestDTO);
    }

    const onCorrectionConfirmation = (e: React.MouseEvent<HTMLElement>, coords: { latitude: number, longitude: number }, price: number) => {
        setOpenCorrection(false);
        createCorrectionUserAction(station.id, fuelType, price);
    }

    const createCorrectionUserAction = async (stationId: string, fuelType: FuelType, price: number) => {
        setLoading(true);
        try {
            await sendCorrectionUserAction(stationId, fuelType, price);
            localStorage.setItem(`${stationId}-${fuelType}-correction`, "");
            setCorrection(true);
        } catch (error: any) {
            setError(error.message);
        }
        setLoading(false);
    }

    const createUserAction = async (userActionRequestDTO: UserActionRequestDTO) => {
        setLoading(true);
        try {
            const result = await sendUserAction(userActionRequestDTO);
            const reward = result.data.reward as Reward;
            setReward(reward);
            setResultResponse(true);
        } catch (error: any) {
            setError(error.message);
        }
        setLoading(false);
    }

    const onCloseFuelConfirmation = async (e: React.MouseEvent<HTMLElement>, stationId: string, fuelType: FuelType) => {
        setOpenValidateAdmin(false);
        setLoading(true);
        try {
            const result = await deleteFuelAdmin(stationId, fuelType);
            setMessageAdmin(result.data.message)
            setResultAdmin(true);
        } catch (error: any) {
            setError(error.message);
        }
        setLoading(false);
    }

    return (
        <>
            {openValidateAdmin &&
                <ValidateDeleteFuelAdminDialog
                    onValidate={onCloseFuelConfirmation}
                    onClose={() => setOpenValidateAdmin(!openValidateAdmin)}
                    {...attributes}/>
            }
            {resultAdmin &&
                <OperationAdminDialog message={messageAdmin} onClose={() => setResultAdmin(false)}/>
            }
            {
                loading &&
                <DialogLoader loading={loading} onClose={() => setLoading(!loading)}/>
            }
            {
                error &&
                <ErrorResponseDialog errorCode={error} onClose={() => {
                    setError(undefined);
                }}/>
            }
            {
                correction &&
                <CorrectionDialog onClose={() => setCorrection(false)}/>
            }
            {
                resultResponse &&
                <Feedback reward={reward} onClose={() => setResultResponse(false)}/>
            }
            {
                openCorrection &&
                <UpdatePriceDialog
                    onUpdate={onCorrectionConfirmation}
                    onClose={() => setOpenCorrection(!openCorrection)} {...attributes} />
            }
            {
                openUpdate &&
                <UpdatePriceDialog
                    onUpdate={onUpdatePriceConfirmation}
                    onClose={() => setOpenUpdate(!openUpdate)} {...attributes} />
            }
            {
                openConfirm && <ConfirmPriceDialog
                    onConfirm={onConfirmPriceConfirmation}
                    onClose={() => setOpenConfirm(!openConfirm)} {...attributes} />
            }
            {
                openLogin && <LoginDialog onClose={() => setOpenLogin(!openLogin)}/>
            }
            {
                openPriceHistory && <PriceHistoryDialog
                    onClose={() => setOpenPriceHistory(!openPriceHistory)} {...attributes}></PriceHistoryDialog>
            }

            <ListItem className={className() + " fuel-list-item"}
                      sx={{
                          px: 1,
                          border: 4,
                          borderColor: borderColor,
                          justifyContent: 'space-between'
                      }}>

                <Stack alignItems={'flex-start'} flexGrow={1}>
                    <Typography variant={"body1Bold"} fontSize={15}>
                        {t(fuelType)}&nbsp;:&nbsp;{fuelType === FuelType.CNG ? t("Price_Label_CNG") : "CHF/l"}&nbsp;{getDisplayPrice(attributes)}
                    </Typography>
                    <Link onClick={onPriceHistory}
                          underline="none"
                          sx={{cursor: "pointer"}}
                          fontSize={12}
                          color={fiabilityColor}
                          lineHeight={1.25}
                          mb={1}>
                        <Stack gap={0.25} direction={'row'} alignItems={'center'}>
                            <Typography fontSize={11}
                                        fontWeight={'bold'}>{t('GasPrice_LastUpdate')} {momentLastPriceUpdate.fromNow()}</Typography>
                            <ChevronRight color="disabled" fontSize="small"/>
                        </Stack>
                    </Link>
                    <FiabilityChip onClick={onPriceHistory} fiability={fuel.fiability}/>
                </Stack>

                {canContribute({profile, station, fuelType}) ?
                    <Stack direction={'row'} gap={0.5}>
                        <Button
                            variant="contained"
                            color="secondary"
                            onClick={user ? onConfirm : onLogin}
                            sx={{minWidth: 0, px: 2, py: 1.25, fontSize: 14}}
                        >
                            <CheckIcon className={"customSVGIcon whiteStroke"}/>
                            <Typography fontSize={12} sx={{pl: 1, display: {xs: 'none', sm: 'block'}}}
                            >{t('Confirm')}</Typography>
                        </Button>

                        <Button
                            variant="contained"
                            color="secondary"
                            onClick={user ? onUpdate : onLogin}
                            sx={{minWidth: 0, px: 2, py: 1.25, fontSize: 14}}
                        >
                            <PenIcon className={"customSVGIcon"}/>
                            <Typography fontSize={12} sx={{pl: 1, display: {xs: 'none', sm: 'block'}}}
                            >{t('update')}</Typography>
                        </Button>
                        {profile?.type === UserType.ADMIN &&
                            <Button
                                color="error"
                                sx={{
                                    borderStyle: "dashed",
                                    borderWidth: "1px"
                                }}
                                onClick={user ? onDeleteFuel : onLogin}>
                                <DeleteIcon />
                            </Button>
                        }
                    </Stack>
                    :
                    <Stack
                        justifyContent="flex-end"
                        alignItems="flex-end"
                        gap={1}
                        sx={{
                            width: {
                                xs: '40%',
                                sm: 'auto'
                            },
                            maxWidth: {
                                xs: 'auto',
                                sm: '400px'
                            },
                            py: 1.5,
                            px: 1.5,
                            borderRadius: '8px',
                            background: tcsBeige3
                        }}>
                        <Typography fontSize={12}
                                    lineHeight={1}>{t('GasPrice_Participation_Next') + " " + nextParticipation({
                            station,
                            fuelType
                        })}</Typography>
                        {canCorrect({station, fuelType}) &&
                            <Link
                                onClick={user ? onCorrection : onLogin}
                                underline="none"
                                variant='body2'
                                sx={{cursor: "pointer", color: Theme.palette.grey[800], lineHeight: 1}}
                                fontSize={12}
                            >
                                {t("correct_my_price") + " >"}
                            </Link>
                        }
                    </Stack>
                }
            </ListItem>
        </>
    )
}

export default GasPrice
