import Login from '../components/login/Login';
import SimplePage from '../components/SimplePage';

const LoginPage = () => {

  return (
    <SimplePage>
      <Login></Login>
    </SimplePage>
  )
}

export default LoginPage
