import { WithFieldValue, DocumentData, QueryDocumentSnapshot, SnapshotOptions } from "firebase/firestore";
import { UserAction } from "../model/UserAction";
import DateConverter from "./DateConverter";
import PublicProfileConverter from "./PublicProfileConverter";

export const UserActionConverter = {
  toFirestore(userAction: WithFieldValue<UserAction>): DocumentData {
    return { ...userAction };
  },
  fromFirestore(
    snapshot: QueryDocumentSnapshot,
    options: SnapshotOptions
  ): UserAction {
    const data = snapshot.data(options)!;
    return UserActionConverter.fromData(data);
  },
  fromData(data: any) {
    return {
      ...data,
      date: DateConverter.fromData(data.date),
      publicProfile: PublicProfileConverter.fromData(data.publicProfile)
    } as UserAction;
  }
}
export default UserActionConverter
