import { Avatar, Paper, Stack, Typography } from "@mui/material";
import Grid from '@mui/material/Unstable_Grid2';
import { FuelType } from "../../utils/model/FuelType";
import { getDisplayPrice } from "../../utils/helpers/LocalPrice";
import { BrandIcon } from "../../utils/assets/BrandIcon";
import { Color } from "../../utils/assets/ColorFromFiability";
import moment from "moment";
import { useTranslation } from "react-i18next";
import FiabilityIcon from "../common/FiabilityIcon";
import { momentRelativeTimeTranslationsForLocale } from "../../utils/helpers/MomentRelativeTimeTranslation";
import { lightGreenBackgroundColor, fontFamilyMuseo700 } from "../../mui/Theme";
import { FiabilityLevel } from "../../utils/model/FiabilityLevel";
import { Station } from "../../utils/model/Station";

interface Props {
  station: Station,
  fuelType: FuelType;
  isCheapest: boolean;
  active: boolean
}
export const GMapInfo = ({ station, fuelType, isCheapest, active }: Props) => {
  const { i18n } = useTranslation();

  const fuelToDisplay = station.fuelCollection[fuelType]
  const fiability = fuelToDisplay?.fiability

  const priceToDisplay = fuelToDisplay ? getDisplayPrice({ station: station, fuel: fuelToDisplay, fuelType: fuelToDisplay.type }) : null

  const fiabilityColor = Color.forFiabilityLevel(fuelToDisplay?.fiability.level)
  const imageSize = active ? 36 : 32
  const border = active ? 4 : 2
  const background = isCheapest ? lightGreenBackgroundColor : "white"

  moment.locale(i18n.language, momentRelativeTimeTranslationsForLocale(i18n.language));
  const momentLastPriceUpdate = moment(fuelToDisplay?.fiability.lastPriceUpdate).fromNow()

  return (
    <Paper className='marker-info' elevation={2}
      sx={{
        background: background,
        p: '1px',
        borderRadius: '32px',
        border: border,
        borderColor: fiabilityColor
      }}>
      <Grid container gap={0.5} wrap='nowrap' alignItems={'center'}>
        <Grid flexShrink={0}>
          <Avatar alt="" src={BrandIcon.forStation(station)} sx={{
            width: imageSize, height: imageSize
          }} />
        </Grid>
        {fuelToDisplay &&
          <Stack mr={0.75}>
            <Stack direction={'row'} alignItems={'flex-end'} gap={0.5}>
              <Typography fontSize={14} fontFamily={fontFamilyMuseo700} lineHeight={1}>
                {priceToDisplay}
              </Typography>
              {fiability?.level === FiabilityLevel.CONFIDENT &&
                <FiabilityIcon fiabilityLevel={fiability?.level} iconSize={15} />
              }
            </Stack>
            <Typography mb={0} fontSize={10} noWrap lineHeight={1} color={fiabilityColor} fontWeight={'bold'}>
              {momentLastPriceUpdate}
            </Typography>
          </Stack>
        }
      </Grid>

    </Paper>
  )
}
