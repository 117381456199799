import GMapLoader from '../components/maps/GMapLoader';
import {useMatch} from "react-router-dom";

const Map = () => {

    const matchStation = useMatch('/:lang/station/:stationId/:fuelId');
    const matchMap = useMatch('/:lang/map/:fuelId');
    const className = 'google-map' + (matchStation || matchMap ? ' active' : '');

  return (
      <div className={className}>
        <GMapLoader />
      </div>
  )
}

export default Map
