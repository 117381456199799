import LegalMentions from "../components/legalMentions/LegalMentions";
import SimplePage from "../components/SimplePage";

const LegalMentionsPage = () => {

  return (
    <SimplePage>
     <LegalMentions></LegalMentions>
    </SimplePage>
  )
}

export default LegalMentionsPage
