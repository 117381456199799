import { useTranslation } from 'react-i18next';
import { Container, Typography } from '@mui/material';
import { Theme } from '../../mui/Theme';

export const ContestComingSoon = () => {
  const { t } = useTranslation();

  const backgroundColor = Theme.palette.warning.light
  const textColor = Theme.palette.text.primary

  return (
    <>
      <Container sx={{
        background: backgroundColor,
        borderRadius: 2,
        py: 2, mb: 2
      }}>
        <Typography variant="h4" color={textColor} className={'shine blue'}>
          {t('Contests_MonthlyPrizes_SubTitle')}
        </Typography>

        <Typography variant="body1" color={textColor} fontSize={14} sx={{ mt: 1 }}>
          {t('Contests_MonthlyPrizes_Description')}
        </Typography>

      </Container>
    </>
  )
}
