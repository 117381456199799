import {initializeApp} from "firebase/app";
import {getAnalytics} from "firebase/analytics";
import {connectAuthEmulator, getAuth} from 'firebase/auth';
import {connectDatabaseEmulator, getDatabase} from "firebase/database";
import {connectFirestoreEmulator, getFirestore} from "firebase/firestore";
import {connectFunctionsEmulator, getFunctions} from "firebase/functions";
import {fetchAndActivate, getRemoteConfig} from "firebase/remote-config";
import {getStorage} from "firebase/storage";
import remoteDefaultConfig from "./remote_config_defaults.json";
import {Analytics} from "@firebase/analytics";

const firebaseConfig = {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    databaseURL: process.env.REACT_APP_FIREBASE_FIRESTORE,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_FIREBASE_APP_ID,
    measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID
}

export const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const db = getDatabase(app);
export const firestore = getFirestore(app);
export const functions = getFunctions(app, 'europe-west6');
export const remoteConfig = getRemoteConfig(app);
export let analytics: Analytics | null = null;
export const storage = getStorage(app);

// import default config
remoteConfig.defaultConfig = remoteDefaultConfig

// Emulator connections.
if (process.env.REACT_APP_ENV === "dev") {
    try {
        connectAuthEmulator(auth, 'http://localhost:9099', {disableWarnings: true});
        connectDatabaseEmulator(db, 'localhost', 9000);
        connectFunctionsEmulator(functions, 'localhost', 5001);
        connectFirestoreEmulator(firestore, 'localhost', 8080);
        remoteConfig.settings.minimumFetchIntervalMillis = 1000;
    } catch (error) {
        console.log(error)
    }
}

fetchAndActivate(remoteConfig).then((res) => {
    /*console.log(res);*/
}).catch((err) => {
    console.log(err);
});

window.addEventListener('ucEvent', async function (event: any) {
    if (event.detail && event.detail.event === "consent_status") {
        if (event.detail['Google Analytics 4'] === true) {
            analytics = getAnalytics(app);
        } else {
            analytics = null;
        }
    }
});