import { Alert, AlertColor, AlertTitle, Box, Snackbar } from "@mui/material";
import { useState } from "react";
import { useProfile } from "../../context/ProfileContext";
import { useRouter } from "../../context/RouterContext";
import { BannerStateStorage, LOCALSTORAGE_BANNER_KEYS } from "../maps/BannerStateStorage";

export interface AdvertBanner {
    localStorageKey: LOCALSTORAGE_BANNER_KEYS
    minDate: Date
    maxDate: Date

    title: string
    description: string

    severity?: AlertColor
}

interface Props {
    advertBanners: AdvertBanner[]
}

export const MessageBanner = ({ advertBanners }: Props) => {
    const { profile } = useProfile();
    const { navigate } = useRouter();

    const [visibleBanners, setVisibleBanners] = useState<AdvertBanner[]>(() => {
        const date = new Date()
        return advertBanners
            .filter(banner => {
                const hasSeenBanner = BannerStateStorage.getHasSeenContestBanner(profile, banner.localStorageKey)
                if (hasSeenBanner) { return false }

                const isInsideDateRange = (date >= banner.minDate && date <= banner.maxDate)
                return isInsideDateRange
            })
            .sort((a, b) => a.minDate.getTime() - b.minDate.getTime())
    })
    const currentBanner: AdvertBanner | undefined = visibleBanners.length ? visibleBanners[0] : undefined
    const isSnackbarVisible = Boolean(currentBanner)

    const handleClick = (event: React.SyntheticEvent | Event) => {
        event?.stopPropagation()
        if (!currentBanner) { return }
        BannerStateStorage.saveHasSeenContestBanner(true, profile, currentBanner.localStorageKey)
        setVisibleBanners(visibleBanners.filter(x => x.localStorageKey !== currentBanner.localStorageKey))
        navigate("/contest")
    };

    const handleClose = (event: React.SyntheticEvent | Event) => {
        event?.stopPropagation()
        if (!currentBanner) { return }
        BannerStateStorage.saveHasSeenContestBanner(true, profile, currentBanner.localStorageKey)
        setVisibleBanners(visibleBanners.filter(x => x.localStorageKey !== currentBanner.localStorageKey))
    };

    return (
        <Snackbar
            sx={{ mt: { xs: '70px', sm: '50px' } }}
            open={isSnackbarVisible}
            anchorOrigin={{ vertical: "top", horizontal: "right" }}
            autoHideDuration={1000 * 3600 * 24 * 30} //1 month autohide
        >
            <Alert
                severity={currentBanner?.severity}
                variant="filled"
                onClose={handleClose}
                sx={{ cursor: 'pointer' }}
            >
                <Box onClick={handleClick}>
                    <AlertTitle sx={{ fontWeight: 'bold' }}>{currentBanner?.title}</AlertTitle>
                    {currentBanner?.description}&nbsp;&rsaquo;
                </Box>
            </Alert>
        </Snackbar>
    )
}