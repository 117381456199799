import {AdminStationMarkerInfo} from "./AdminStationMarkerInfo";
import {Theme, fontFamilyMuseo700, fontFamilyMuseo300} from "../../mui/Theme";
import {Dialog, DialogActions, DialogContent, DialogTitle, Stack, Typography, Box} from "@mui/material";
import Button from "@mui/material/Button";
import React, {useState} from "react";
import moment from "moment/moment";
import {Station} from "../../utils/model/Station";
import DialogLoader from "../common/DialogLoader";
import ErrorResponseDialog from "../station/components/ErrorResponseDialog";
import {Fuel} from "../../utils/model/Fuel";
import {FuelType, FuelTypeValues} from "../../utils/model/FuelType";

interface Props {
    lat: number;
    lng: number;
    station: Station;
    onRestoreStation(e: React.MouseEvent<HTMLElement>, stationId: string): void;
    onRestoreFuel(e: React.MouseEvent<HTMLElement>, stationId: string, fuelType: FuelType): void;
}

const pinLegWidth = 18;
const pinLegHeight = 14;
const points = `0,0 ${pinLegWidth / 2},${pinLegHeight} ${pinLegWidth},0`;

export const AdminStationMarker = ({station, onRestoreStation, onRestoreFuel}: Props) => {
    const [showDetailsDialog, setShowDetailsDialog] = useState(false);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState<TemplateStringsArray | undefined>();

    moment.locale("en");

    const onStationClick = () => {
        setShowDetailsDialog(true);
    }

    return (
        <>
            {
                loading &&
                <DialogLoader loading={loading} onClose={() => setLoading(!loading)}/>
            }
            {
                error &&
                <ErrorResponseDialog errorCode={error} onClose={() => setError(undefined)}/>
            }
            <Dialog
                open={showDetailsDialog}
                onClose={() => setShowDetailsDialog(false)}
                className='dialog'>
                <DialogTitle>
                    <Stack alignItems={'flex-start'}>
                        <Typography variant="h4" sx={{mb: 1}}>{station.id}</Typography>
                        <Typography variant="h6">{station.formattedAddress}</Typography>
                    </Stack>
                </DialogTitle>
                {!station.isDeleted &&
                    <DialogContent>
                        <Stack direction='column' width={'100%'} justifyContent={'space-around'} gap={1} paddingBottom={1}>
                            {FuelTypeValues.map((type) => {
                                 if (station.fuelCollection[type]) {
                                     const deletedItem = station.fuelCollection[type] as Fuel;
                                     if (deletedItem.isDeleted) {
                                         return <Box key={deletedItem.type}
                                                     sx={{
                                                         borderRadius: '5px',
                                                         background: Theme.palette.error.light
                                                     }}
                                                     padding={1}>
                                             <Stack direction={'row'}>
                                                 <Stack direction={'column'} flexGrow={1}>
                                                     <Typography variant="h6" fontFamily={fontFamilyMuseo700}>
                                                         {deletedItem.type}
                                                     </Typography>
                                                     <Typography fontSize={12} fontFamily={fontFamilyMuseo300}>
                                                         {deletedItem.displayPrice}
                                                     </Typography>
                                                 </Stack>
                                                 <Button variant="contained" color="success"
                                                         onClick={(e) => onRestoreFuel(e, station.id, type)}>Restore</Button>
                                             </Stack>
                                         </Box>
                                     }
                                     return null;
                                 }
                                return null;
                            })}
                        </Stack>
                    </DialogContent>
                }
                <DialogActions>
                    {station.isDeleted &&
                        <Button variant="contained" color="success"
                                onClick={(e) => onRestoreStation(e, station.id)}>Restore</Button>
                    }
                    <Button variant="contained" color="secondary"
                            onClick={() => setShowDetailsDialog(false)}>Close</Button>
                </DialogActions>
            </Dialog>
            <div onClick={onStationClick} className={"marker"}>
                <svg height={pinLegHeight} width={pinLegWidth}>
                    <g color={station.isDeleted ? Theme.palette.error.main : Theme.palette.warning.main}>
                        <polygon points={points} fill="currentcolor"/>
                    </g>
                </svg>
                <div className="marker-inner">
                    <AdminStationMarkerInfo station={station}/>
                </div>
            </div>
        </>
    )
}