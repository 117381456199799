import {
    Stack,
    Typography,
    useMediaQuery
} from "@mui/material"
import { useTranslation } from "react-i18next"
import { tcsBeige1, tcsBeige2, Theme } from "../../../mui/Theme"
import { ReactComponent as LotteryTicket } from '../../../icons/tcs-icon_lotteryticket.svg'
import { PrizeWithUserEntries } from "../../../context/ContestContext"


interface Props {
    prize: PrizeWithUserEntries;
}

export const PrizeStats = ({ prize }: Props) => {
    const { t } = useTranslation();

    const numberOfEnteredTokens = prize.userEntries
    const isAtLeastSm = useMediaQuery(Theme.breakpoints.up('sm'));

    return (
        <Stack direction={'column'} gap={0.5}>
            <Stack direction={"row"} sx={{ background: tcsBeige1 }} py={0.5} alignItems={'center'} justifyContent={"flex-end"} gap={1}>
                <Typography textAlign={'right'} sx={{ lineHeight: 1.2 }} fontSize={12}>{t("Entered tokens")}</Typography>
                <Stack direction={"row"} gap={0.5} alignItems={"center"} pr={1}>
                    <LotteryTicket className={"lotterySVG customSVGIcon " + (isAtLeastSm ? "size25" : "size20")} />
                    <Typography variant="body1Bold" fontSize={{ xs: 12, sm: 14 }} flexGrow={1}>{numberOfEnteredTokens}</Typography>
                </Stack>
            </Stack>

            <Stack direction={"row"} sx={{ background: tcsBeige2 }} p={0.5} alignItems={'center'} justifyContent={"flex-end"} gap={1}>
                <Typography textAlign={'right'} sx={{ lineHeight: 1.2 }} fontSize={12}>{t("Total pool")}</Typography>
                <Stack direction={"row"} gap={0.5} alignItems={"center"} pr={1}>
                    <LotteryTicket className={"lotterySVG customSVGIcon " + (isAtLeastSm ? "size25" : "size20")} />
                    <Typography variant="body1Bold" fontSize={{ xs: 12, sm: 14 }} flexGrow={1}>{prize.totalUsedTokens}</Typography>
                </Stack>
            </Stack>
        </Stack>
    )
}