import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    List,
    ListItemButton,
    Stack,
    Typography
} from "@mui/material"
import { getValue } from "firebase/remote-config"
import React, { useEffect, useState } from "react"
import { useGeolocated } from "react-geolocated"
import { useTranslation } from "react-i18next"
import { remoteConfig } from "../../../config/firebase"
import { useProfile } from "../../../context/ProfileContext"
import { canParticipate, displayPriceToFixed } from "../../../utils/helpers/LocalPrice"
import { FuelType, FuelTypeValues } from "../../../utils/model/FuelType"
import { Station } from "../../../utils/model/Station"
import GeolocationAcquireDialog from "./GeolocationAcquireDialog"
import ForbiddenParticipationDialog from "./ForbiddenParticipationDialog"
import GeolocationAvailableDialog from "./GeolocationAvailableDialog"
import PriceIncrementer from "./PriceIncrementer"
import GeolocationEnabledDialog from "./GeolocationEnabledDialog";
import { tcsBlueGray1, Theme } from '../../../mui/Theme'
import { ReactComponent as TrophyIcon } from '../../../icons/Icon_Pokal3.svg'
import {FuelCollection} from "../../../../functions/src/utils/model/FuelCollection";

interface Props {
    station: Station,
    onClose(): void,
    onNew(e: React.MouseEvent<HTMLElement>, coords: { latitude: number, longitude: number }, price: number, fuelType: any): void
}

const AddFuelDialog = ({ station, onClose, onNew }: Props) => {
    const { t } = useTranslation()

    const [price, setPrice] = useState<number>(0)
    const [firstStep, setFirstStep] = useState(false)
    const [fuelType, setFuelType] = useState<FuelType | false>(false)
    const [hasPermission, setHasPermission] = useState(false);
    const { profile } = useProfile();
    const [isPriceIncrementerShown, showPriceIncrementer] = useState(false);

    const { coords, isGeolocationAvailable, isGeolocationEnabled } =
        useGeolocated({
            positionOptions: {
                enableHighAccuracy: false,
            },
            userDecisionTimeout: 5000,
        });

    const onListItemClick = (e: React.MouseEvent<HTMLElement>, type: FuelType) => {
        e.stopPropagation()
        setPrice(getValue(remoteConfig, `${type}_DEFAULT_PRICE`).asNumber());
        setFuelType(type);
        showPriceIncrementer(true)
    }

    const onFirstStep = (e: React.MouseEvent<HTMLElement>) => {
        e.stopPropagation()
        if (fuelType) setFirstStep(!firstStep)
    }

    const onReset = (e: React.MouseEvent<HTMLElement>) => {
        e.stopPropagation()
        setFirstStep(false)
        onClose()
    }

    const existantFuels = station.fuelCollection
        ? Object.keys(station.fuelCollection)
            .filter(fuelType => !station.fuelCollection[fuelType as keyof FuelCollection]?.isDeleted)
        : undefined

    const textOptions = {
        station: station.displayName
    }

    const textOptions2 = {
        station: station.displayName,
        fuel: fuelType ? (t(fuelType) ?? fuelType) : ""
    }

    useEffect(() => {
        setHasPermission(canParticipate(coords, profile, station));
    }, [setHasPermission, coords, profile, station]);

    return !isGeolocationAvailable ?
        (<GeolocationAvailableDialog isGeolocationAvailable={isGeolocationAvailable} onClose={onReset} />) :
        !isGeolocationEnabled ?
            (<GeolocationEnabledDialog isGeolocationEnabled={isGeolocationEnabled} onClose={onReset} />) :
            coords ? (
                <>
                    {hasPermission ?
                        (<>
                            <Dialog open={!(Boolean(fuelType) && firstStep)} onClose={onReset} className='dialog'>
                                <DialogTitle>
                                    <Stack alignItems={'flex-start'}>
                                        <Typography variant="h4" sx={{ mb: 0.5 }}>{t('Add a new fuel type')}</Typography>
                                        <Typography variant="body1" sx={{ mt: 0.25 }}>{t('FuelNotInTheList', textOptions)}</Typography>
                                        <Typography variant="body1" sx={{ mt: 0.25 }}>{t('Add it below', textOptions)}</Typography>
                                    </Stack>
                                </DialogTitle>

                                <DialogContent>
                                    <List>
                                        {FuelTypeValues.map(item => {
                                            if (existantFuels?.indexOf(item) !== -1) {
                                                return <ListItemButton key={item} className={"addFuelListItem disabledFuel"} sx={{ px: 0.5 }}>
                                                    <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'} sx={{ width: '100%' }}>
                                                        <Stack>
                                                            <Typography variant={"body1Bold"} fontSize={12}>{t(item)}</Typography>
                                                            <Typography variant={"caption"}>
                                                                {t(("fuel_" + item + "_description") as unknown as TemplateStringsArray, "")}
                                                            </Typography>
                                                        </Stack>

                                                        <Typography variant={"body2"}>
                                                            {t("Already added")}
                                                        </Typography>
                                                    </Stack>
                                                </ListItemButton>
                                            } else {
                                                return <ListItemButton className={"addFuelListItem"} sx={{ px: 0.5 }} key={item} selected={fuelType === FuelType[item]}
                                                    onClick={(event) => onListItemClick(event, FuelType[item])}>
                                                    <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'} sx={{ width: '100%' }}>
                                                        <Stack>
                                                            <Typography variant={"body1Bold"} fontSize={12}>{t(item)}</Typography>
                                                            <Typography variant={"caption"}>
                                                                {t(("fuel_" + item + "_description") as unknown as TemplateStringsArray, "")}
                                                            </Typography>
                                                        </Stack>
                                                        {
                                                            (fuelType === FuelType[item]) &&
                                                            <CheckCircleIcon sx={{ ml: 1 }} color={'secondary'} fontSize={'large'} />
                                                        }
                                                    </Stack>
                                                </ListItemButton>
                                            }

                                        })}
                                    </List>
                                </DialogContent>
                                <DialogActions>
                                    <Button variant="outlined" color="secondary" sx={{ fontSize: 14 }} onClick={onReset}>{t('Cancel')}</Button>
                                    <Button variant="contained" color="secondary" sx={{ fontSize: 14 }} onClick={onFirstStep}>{t('Confirm')}</Button>
                                </DialogActions>
                            </Dialog>
                            {fuelType && isPriceIncrementerShown &&
                                <Dialog open={Boolean(fuelType) && firstStep} onClose={onReset} className='dialog'>
                                    <DialogTitle>
                                        <Stack alignItems={'flex-start'}>
                                            <Typography variant="h3" sx={{ mb: 0.5 }}>{t('Price of the new fuel type')}</Typography>
                                            <Typography variant="body1" sx={{ mt: 0.25 }}>{t('EnterDisplayedPriceFor', textOptions2)}</Typography>
                                        </Stack>
                                    </DialogTitle>

                                    <DialogContent>
                                        <Stack alignItems={'center'} justifyContent={'center'}
                                            sx={{ background: tcsBlueGray1, borderRadius: '8px', p: 1, pb: 2 }}>
                                            <Typography fontWeight={'bold'} fontSize={14} color={Theme.palette.grey[700]}>{textOptions2.fuel}</Typography>
                                            <Typography sx={{ mb: 0.5 }} variant="h3" fontSize={32} color="secondary">{t('CHF')}&nbsp;{displayPriceToFixed(price)}</Typography>
                                            <PriceIncrementer fuelType={fuelType} setPrice={setPrice}></PriceIncrementer>
                                        </Stack>
                                        <Stack direction={'row'} gap={1} sx={{p: 1, my: 1}}>
                                            <TrophyIcon className="customSVGIcon size48" />
                                            <Typography fontSize={11}>{t('PriceConfirmWin')}</Typography>
                                        </Stack>
                                    </DialogContent>
                                    <DialogActions sx={{mt:1}}>
                                        <Button variant="outlined" color="secondary" sx={{fontSize:14}} onClick={onReset}>{t('Cancel')}</Button>
                                        <Button variant="contained" color="secondary" sx={{fontSize:14}} onClick={(e) => onNew(e, coords, price, fuelType)}>{t('Confirm')}</Button>
                                    </DialogActions>
                                </Dialog>
                            }
                        </>) :
                        (<ForbiddenParticipationDialog hasPermission={hasPermission} onClose={onClose} />)
                    }
                </>
            ) : (<GeolocationAcquireDialog coords={coords} onClose={onReset} />);
}

export default AddFuelDialog
