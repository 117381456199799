import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    Stack,
    Typography
} from "@mui/material"
import { useTranslation } from "react-i18next"
import { tcsBlueGray1, Theme } from "../../../mui/Theme"
import { ReactComponent as LotteryTicket } from '../../../icons/tcs-icon_lotteryticket.svg'

import AddIcon from '@mui/icons-material/Add'
import RemoveIcon from '@mui/icons-material/Remove'
import { PrizeWithUserEntries } from "../../../context/ContestContext"
import { useState } from "react"
import { useProfile } from "../../../context/ProfileContext"
import { langFromi18nLang } from "../../../utils/helpers/Lang"


interface Props {
    prize: PrizeWithUserEntries;
    onClose(): void;
    onUseTickets(numberOfTokens: number): void;
}

const UseTokensDialog = ({ prize, onClose, onUseTickets }: Props) => {
    const { t, i18n } = useTranslation();
    const lang = langFromi18nLang(i18n.language)
    const [numberOfTokens, setNumberOfTokens] = useState<number>(1)
    const { profile } = useProfile()

    const availableTokens = profile?.lotteryTickets || 0
    const canAddTokens = numberOfTokens < availableTokens
    const canRemoveTokens = numberOfTokens > 1

    const prizeName = prize[`title_${lang}`]

    return (
        <Dialog open={true} onClose={onClose} className='dialog'>
            {/* Image */}
            <Box
                component="img"
                height="150px"
                src={prize.imageSrc}
                alt={prize[`title_${lang}`]}
                sx={{ background: tcsBlueGray1, objectFit: 'cover' }}
            />

            <DialogContent sx={{ pt: 1, px: 2 }}>
                <Typography variant="h4" sx={{ mb: 0.5 }}>{t('UseTokensDialog_Title')}</Typography>
                <Typography variant="h5" sx={{ mb: 0.5 }}>{prizeName}</Typography>

                <Typography fontSize={12}>{t('UseTokensDialog_Paragraph1', { numberOfPrizes: prize.numberOfPrizes, totalUsedTokens: prize.totalUsedTokens })}</Typography>
                <Typography fontSize={12}>{t('UseTokensDialog_Paragraph2', { numberOfUsedTickets: prize.userEntries, availableTokens: availableTokens })}</Typography>

                {availableTokens &&
                    <Stack alignItems={'center'} justifyContent={'center'}
                        sx={{ background: tcsBlueGray1, borderRadius: '8px', p: 1, pb: 2 }}>

                        <Typography variant="body1Bold" color={Theme.palette.grey[800]}>{t('UseTokensDialog_Add')}</Typography>

                        <Stack direction={"row"} gap={0.5} alignItems={"flex-start"}>
                            <Box py={'5px'}><LotteryTicket className={"lotterySVG customSVGIcon size25 blue stroke1"} /></Box>
                            <Typography sx={{ mb: 0.5 }} variant="h3" fontSize={32} color="secondary">{numberOfTokens}</Typography>
                        </Stack>

                        <Stack direction={'row'} gap={1} className={"buttons-incrementer"}>
                            <Button
                                variant='contained'
                                color='secondary'
                                disabled={!canRemoveTokens}
                                onClick={() => setNumberOfTokens(numberOfTokens - 1)}
                            >
                                <RemoveIcon />
                            </Button>
                            <Button
                                variant='contained'
                                color='secondary'
                                disabled={!canAddTokens}
                                onClick={() => setNumberOfTokens(numberOfTokens + 1)}
                            >
                                <AddIcon />
                            </Button>
                        </Stack>
                    </Stack>
                }
            </DialogContent>

            <DialogActions sx={{ mt: 3 }}>
                <Button variant="outlined" color="secondary" onClick={onClose}>{t('Cancel')}</Button>
                {availableTokens &&
                    <Button variant="contained" color="secondary" sx={{ fontWeight: 'bold' }} onClick={() => onUseTickets(numberOfTokens)}>
                        {t('UseTokensDialog_Button', {numberOfTokens: numberOfTokens})}
                    </Button>
                }
            </DialogActions>
        </Dialog >
    )
}

export default UseTokensDialog
