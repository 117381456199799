import {FiabilityLevel} from "./model/FiabilityLevel"
import {Fuel} from "./model/Fuel"
import {FuelType} from "./model/FuelType"
import {Station} from "./model/Station"

const DISCARD_PRICES_UNDER = 0.10

/*
We are using useSupercluster (https://github.com/leighhalliday/use-supercluster) 
by passing Point objects as parameter.
In return, the hook will give back 'clusters', that is an array of objects that can either be :
- Point
- Cluster
*/

export interface Cluster {
  id: number
  properties: {
    point_count: number
  },
  geometry: {
    coordinates: number[]
  }
}

export interface Point {
  properties: {
    station: Station
  };
  geometry: {
    coordinates: number[]
  }
}

export interface PointOrCluster {
  isCluster: boolean
  isCheapest: boolean

  cluster?: Cluster
  point?: Point

  station?: Station
}

export function findCheapestPointOrClusters(pointOrClusters: PointOrCluster[], fuelType: FuelType): PointOrCluster[] {
  let cheapestPrice: number = Infinity
  let cheapests: PointOrCluster[] = []
  pointOrClusters.forEach(poc => {
    //Sanity checks
    if (!poc.station) { return }
    const fuel = poc.station.fuelCollection[fuelType]
    if (!fuel) { return }

    //Only keep non-warning stations
    if (fuel.fiability.level === FiabilityLevel.OUTDATED_LAST_PRICE_UPDATE
      || fuel.fiability.level === FiabilityLevel.OLD_LAST_UPDATE) { return }

    //Do not consider stations with suspicious prices
    if (fuel.displayPrice <= DISCARD_PRICES_UNDER) { return false }

    //Keep only the cheapest prices (plural)
    const price = fuel.displayPrice
    if (price < cheapestPrice) {
      cheapests = [poc]
      cheapestPrice = price
    } else if (price === cheapestPrice) {
      cheapests.push(poc)
    }
  });

  return cheapests;
}

export function findCheapestStationInCluster(stations: Station[], fuelType: FuelType): Station | undefined {

  //First try to keep stations that are "fiable"
  const stationsWithFiableFuel = stations.filter(station => {
    const fuel = station.fuelCollection[fuelType];
    if (!fuel) { return false }

    //Discard stations with suspicious prices
    if (fuel.displayPrice <= DISCARD_PRICES_UNDER) { return false }

    return (fuel.fiability.level === FiabilityLevel.CONFIDENT
        || fuel.fiability.level === FiabilityLevel.FEW_RECENT_PRICES)
  });

  //If no "fiable" station found, take "old" stations
  let stationsToKeep = stationsWithFiableFuel.length
    ? stationsWithFiableFuel
    : stations.filter(station => {
      const fuel = station.fuelCollection[fuelType]
      if (!fuel) { return false }

      //Discard stations with suspicious prices
      if (fuel.displayPrice <= DISCARD_PRICES_UNDER) { return false }
      return (fuel.fiability.level === FiabilityLevel.OLD_LAST_UPDATE)
    })

  //Sort the stations
  const sortedStations = stationsToKeep.sort((stationA, stationB) => {
    const fuelA: Fuel = stationA.fuelCollection[fuelType]!
    const fuelB: Fuel = stationB.fuelCollection[fuelType]!

    // Cheapest price
    const priceDiff = fuelA.displayPrice - fuelB.displayPrice;
    if (priceDiff) { return priceDiff }

    //Higher fiability
    if (fuelA.fiability.level === FiabilityLevel.CONFIDENT && fuelB.fiability.level === FiabilityLevel.FEW_RECENT_PRICES) {
      return -1;
    } else if (fuelB.fiability.level === FiabilityLevel.CONFIDENT && fuelA.fiability.level === FiabilityLevel.FEW_RECENT_PRICES) {
      return 1;
    }

    // Fallback to most recent first
    return fuelA.fiability.lastPriceUpdate.getTime() - fuelB.fiability.lastPriceUpdate.getTime()
  });

  const cheapestStation: Station | undefined = sortedStations.length ? sortedStations[0] : undefined;
  return cheapestStation;
}