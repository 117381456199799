import { Avatar, Button, Chip, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, List, ListItem, Typography } from "@mui/material"
import { collection, limit, orderBy, query, where } from "firebase/firestore"
import { useCollectionOnce } from "react-firebase-hooks/firestore"
import { useTranslation } from "react-i18next"
import { firestore } from "../../../config/firebase"
import { useAuth } from "../../../context/AuthContext"
import { useProfile } from "../../../context/ProfileContext"
import RewardConverter from "../../../utils/converters/RewardConverter"
import { RewardReason } from "../../../utils/model/RewardReason"
import SimplePage from "../../SimplePage"
import { displayPriceToFixed } from "../../../utils/helpers/LocalPrice";
import { AvatarIcon } from "../../../utils/assets/AvatarIcon";
import { ReactComponent as TrophyIcon } from '../../../icons/Icon_Pokal3.svg';

interface Props {
  onClose(): void
}

const RewardDialog = ({ onClose }: Props) => {
  const { t } = useTranslation()

  const { user } = useAuth();
  const { profile, clearUnseenRewards, printDisplayName } = useProfile()

  const uid = user ? user : 'xxxxxxxx';

  const rewardsQuery = query(
    collection(firestore, "rewards").withConverter(RewardConverter),
    where("receiverUserId", "==", uid),
    orderBy("date", 'desc'),
    limit(100)
  )

  const [snapshots, loading, error] = useCollectionOnce(
    rewardsQuery
  );

  const internalClose = () => {
    clearUnseenRewards();
    onClose();
  }

  return (

    <Dialog open={true} onClose={internalClose} className="dialog">
      <DialogTitle>
        <div className='dialog-title'>
          <Typography variant="h4">
            {t('Participation points')}
          </Typography>
          <div className='dialog-title-right'>
            <TrophyIcon className={"customSVGIcon size20"} />{profile && t('{{count}} points', { count: profile.points })}
          </div>
        </div>
      </DialogTitle>
      <DialogContent>
        {error && <SimplePage>{error.message}</SimplePage>}
        {loading && <CircularProgress />}
        {
          !loading && snapshots &&
          <List>
            {
              snapshots?.docs.map(snapshot => {
                const reward = snapshot.data()
                const sentenceVaiables = {
                  stationDisplayName: reward.stationDisplayName,
                  stationFuelType: t(reward.stationFuelType),
                  userDisplayName: printDisplayName(reward.emitterPublicProfile),
                  price: displayPriceToFixed(reward.price) + '\xa0' + t('CHF')
                }
                let sentence = '';
                switch (reward.reason) {
                  case RewardReason.PEER_CONTRIBUTED_SIMILAR_PRICE:
                    sentence = t('{{userDisplayName}} did confirm your price for {{stationFuelType}} at {{stationDisplayName}}', sentenceVaiables)
                    break;
                  case RewardReason.USER_CONFIRM_PRICE:
                    sentence = t('You did confirm the price for {{stationFuelType}} ({{price}}) at {{stationDisplayName}}', sentenceVaiables)
                    break;
                  case RewardReason.USER_UPDATED_PRICE:
                    sentence = t('You did update the price for {{stationFuelType}} ({{price}}) at {{stationDisplayName}}', sentenceVaiables)
                    break;
                  case RewardReason.USER_CREATE_PRICE:
                    sentence = t('You did create a price for {{stationFuelType}} ({{price}}) at {{stationDisplayName}}', sentenceVaiables)
                    break;

                }
                return <ListItem key={snapshot.id} className='list-item'>
                  <div className='list-item-avatar'>
                    <Avatar src={AvatarIcon.forAvatar(reward.emitterPublicProfile.avatar)}></Avatar>
                  </div>
                  <div className='list-item-text'>
                    {sentence}
                  </div>
                  {profile?.unseenRewards?.includes(snapshot.id) &&
                    <div className='list-item-new'>
                      <Chip color="error" label={t('New')}></Chip>
                    </div>
                  }
                  <div className='list-item-points'>
                    <Chip color="success" label={'+' + reward.points}></Chip>
                  </div>
                </ListItem>
              })
            }
          </List>
        }
      </DialogContent>
      <DialogActions>
        <Button variant="contained" color="secondary" onClick={internalClose}>{t('Close')}</Button>
      </DialogActions>
    </Dialog>
  )
}

export default RewardDialog
