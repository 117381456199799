import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    List,
    ListItemButton,
    Stack,
    Typography
} from "@mui/material";
import Button from "@mui/material/Button";
import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {ReportType, ReportTypeValues} from "../../../utils/model/ReportType";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import {Station} from "../../../utils/model/Station";
import {FuelType, FuelTypeValues} from "../../../utils/model/FuelType";
import {useGeolocated} from "react-geolocated";
import {canParticipate} from "../../../utils/helpers/LocalPrice";
import GeolocationAvailableDialog from "./GeolocationAvailableDialog";
import GeolocationEnabledDialog from "./GeolocationEnabledDialog";
import ForbiddenParticipationDialog from "./ForbiddenParticipationDialog";
import GeolocationAcquireDialog from "./GeolocationAcquireDialog";
import {useProfile} from "../../../context/ProfileContext";

interface Props {
    station: Station,
    onNewReport(e: React.MouseEvent<HTMLElement>, fuelType: any, reportType: any, idStation: string): void,
    onClose(): void
}

export default function MakeReportDialog({station, onNewReport, onClose}: Props) {
    const {t} = useTranslation();
    const [firstStep, setFirstStep] = useState(false);
    const [fuelType, setFuelType] = useState<FuelType | false>(false);
    const [reportType, setReportType] = useState<ReportType | false>(false);
    const [hasPermission, setHasPermission] = useState(false);
    const {profile} = useProfile();

    const existantFuels = station.fuelCollection ? Object.keys(station.fuelCollection) : undefined;

    const {coords, isGeolocationAvailable, isGeolocationEnabled} =
        useGeolocated({
            positionOptions: {
                enableHighAccuracy: false,
            },
            userDecisionTimeout: 5000,
        });

    const onFuelTypeItemClick = (e: React.MouseEvent<HTMLElement>, type: FuelType) => {
        setFuelType(type);
    }

    const onReportTypeItemClick = (e: React.MouseEvent<HTMLElement>, type: ReportType) => {
        setReportType(type);
    }

    const onNextStep = (e: React.MouseEvent<HTMLElement>) => {
        switch (reportType) {
            case ReportType.STATION_CLOSED:
                onNewReport(e, fuelType, reportType, station.id);
                break;
            case ReportType.WRONG_FUEL:
                setFirstStep(!firstStep);
                break;
        }
    }

    const onReset = (e: React.MouseEvent<HTMLElement>) => {
        setFirstStep(false);
        onClose();
    }

    const getTitleNextStep = (reportType: ReportType) => {
        switch (reportType) {
            case ReportType.STATION_CLOSED:
                return t("Button_Send_Report_Dialog");
            case ReportType.WRONG_FUEL:
                return t("Button_Next_Report_Dialog");
        }
    }

    useEffect(() => {
        setHasPermission(canParticipate(coords, profile, station));
    }, [setHasPermission, coords, profile, station]);

    return !isGeolocationAvailable ?
        (<GeolocationAvailableDialog isGeolocationAvailable={isGeolocationAvailable} onClose={onReset}/>) :
        !isGeolocationEnabled ?
            (<GeolocationEnabledDialog isGeolocationEnabled={isGeolocationEnabled} onClose={onReset}/>) :
            coords ? (
                <>
                    {hasPermission ?
                        (<>
                            <Dialog
                                open={!(Boolean(reportType) && firstStep)}
                                onClose={onReset}
                                className='dialog'>
                                <DialogTitle>
                                    <Stack alignItems={'flex-start'}>
                                        <Typography variant="h4" sx={{mb: 0.5}}>{t("Dialog_Report_Title")}</Typography>
                                    </Stack>
                                </DialogTitle>
                                <DialogContent>
                                    <List>
                                        {
                                            ReportTypeValues.map(reportTypeItem => {
                                                return <ListItemButton className={"addFuelListItem"} sx={{px: 0.5}}
                                                                       key={reportTypeItem}
                                                                       selected={reportType === ReportType[reportTypeItem]}
                                                                       onClick={(event) => onReportTypeItemClick(event, ReportType[reportTypeItem])}>
                                                    <Stack direction={'row'} justifyContent={'space-between'}
                                                           alignItems={'center'}
                                                           sx={{width: '100%', height: '30px'}}>
                                                        <Stack>
                                                            <Typography variant={"body1Bold"} fontSize={12}>
                                                                {t(reportTypeItem as unknown as TemplateStringsArray)}
                                                            </Typography>
                                                        </Stack>
                                                    </Stack>
                                                    {
                                                        (reportType === ReportType[reportTypeItem]) &&
                                                        <CheckCircleIcon sx={{ml: 1}} color={'error'}
                                                                         fontSize={'large'}/>
                                                    }
                                                </ListItemButton>
                                            })
                                        }
                                    </List>
                                </DialogContent>
                                <DialogActions sx={{mt: 1}}>
                                    <Button variant="outlined" color="secondary" sx={{fontSize: 14}} onClick={onClose}>
                                        {t('Cancel')}
                                    </Button>
                                    {
                                        reportType &&
                                        <Button variant="contained" color="secondary" sx={{fontSize: 14}}
                                                onClick={onNextStep}>
                                            {getTitleNextStep(reportType)}
                                        </Button>
                                    }
                                </DialogActions>
                            </Dialog>

                            <Dialog
                                open={(Boolean(reportType) && firstStep)}
                                onClose={onReset}
                                className='dialog'>
                                <DialogTitle>
                                    <Stack alignItems={'flex-start'}>
                                        <Typography variant="h4" sx={{mb: 0.5}}>{t("Dialog_Report_Title")}</Typography>
                                    </Stack>
                                </DialogTitle>
                                <DialogContent>
                                    <List>
                                        {FuelTypeValues.map(item => {
                                            if (existantFuels?.indexOf(item) !== -1) {
                                                return <ListItemButton className={"addFuelListItem"} sx={{px: 0.5}}
                                                                       key={item}
                                                                       selected={fuelType === FuelType[item]}
                                                                       onClick={(event) => onFuelTypeItemClick(event, FuelType[item])}>
                                                    <Stack direction={'row'} justifyContent={'space-between'}
                                                           alignItems={'center'}
                                                           sx={{width: '100%', height: '30px'}}>
                                                        <Stack>
                                                            <Typography variant={"body1Bold"}
                                                                        fontSize={12}>{t(item)}</Typography>
                                                        </Stack>
                                                        {
                                                            (fuelType === FuelType[item]) &&
                                                            <CheckCircleIcon sx={{ml: 1}} color={'error'}
                                                                             fontSize={'large'}/>
                                                        }
                                                    </Stack>
                                                </ListItemButton>
                                            } else {
                                                return null;
                                            }
                                        })}
                                    </List>
                                </DialogContent>
                                <DialogActions sx={{mt: 1}}>
                                    <Button variant="outlined" color="secondary" sx={{fontSize: 14}} onClick={onReset}>
                                        {t('Cancel')}
                                    </Button>
                                    {fuelType &&
                                        <Button variant="contained" color="secondary" sx={{fontSize: 14}}
                                                onClick={(e) => onNewReport(e, fuelType, reportType, station.id)}>
                                            {t('Confirm')}
                                        </Button>
                                    }
                                </DialogActions>
                            </Dialog>
                        </>) :
                        (<ForbiddenParticipationDialog hasPermission={hasPermission} onClose={onClose}/>)
                    }
                </>
            ) : (<GeolocationAcquireDialog coords={coords} onClose={onReset}/>);
}