import {Dialog, DialogActions, DialogContent, DialogContentText} from "@mui/material";
import Button from "@mui/material/Button";
import React from "react";

interface Props {
    message: string,
    onClose(...args: any): void
}

export default function OperationAdminDialog({message, onClose}: Props) {
    return (
        <Dialog
            open={true}
            onClose={onClose}
            className='dialog'>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    {message}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button variant="contained" color="secondary" onClick={onClose}>Close</Button>
            </DialogActions>
        </Dialog>
    )
}