import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography, Stack } from "@mui/material"
import { useTranslation } from "react-i18next"
import { Color } from "../../../utils/assets/ColorFromFiability"
import { Text } from "../../../utils/assets/TextFromFiability"
import { getDisplayPrice } from "../../../utils/helpers/LocalPrice"
import { Fuel } from "../../../utils/model/Fuel"
import { FuelType } from "../../../utils/model/FuelType"
import { Station } from "../../../utils/model/Station"
import UserActionLoader from "./components/UserActionLoader"
import FiabilityChip from "./FiabilityChip"

interface Props {
    station: Station,
    fuel: Fuel,
    fuelType: FuelType,
    onClose(): void
}

const PriceHistoryDialog = ({ station, fuel, fuelType, onClose }: Props) => {
    const { t } = useTranslation()

    const props = {
        station, fuel, fuelType
    }

    const displayPrice = getDisplayPrice({ station, fuel, fuelType })
    const infoText = Text.explanationForFiability(fuel.fiability)
    const color = Color.iconColorForFiability(fuel.fiability.level)

    return (
        <Dialog open={true} onClose={onClose} className='dialog history'>
            <DialogTitle>
                <Stack alignItems={'flex-start'}>
                    <Typography variant="h3" sx={{ mb: 1 }}>{t('History {{fuel}}', { fuel: t(fuelType) })}</Typography>

                    <Typography variant={"body1Bold"} fontSize={15} sx={{ mb: 0.5 }}>{t('Price')}&nbsp;:&nbsp;CHF&nbsp;{displayPrice}</Typography>

                    <FiabilityChip fiability={fuel.fiability} />
                    <Typography variant="body2" fontWeight={'bold'} sx={{ mt: 0.25 }} color={color}>{infoText}</Typography>
                </Stack>
            </DialogTitle>
            <DialogContent>
                <div>
                    {props.fuelType && <UserActionLoader {...props} />}
                </div>
            </DialogContent>
            <DialogActions>
                <Button variant="contained" color="secondary" onClick={onClose}>{t('Close')}</Button>
            </DialogActions>
        </Dialog>
    )
}

export default PriceHistoryDialog
