import {Avatar, List, ListItem} from "@mui/material";
import {QuerySnapshot} from "firebase/firestore";
import moment from "moment";
import {useTranslation} from "react-i18next";
import {useProfile} from "../../../../context/ProfileContext";
import {displayPriceToFixed} from "../../../../utils/helpers/LocalPrice";
import {UserAction} from "../../../../utils/model/UserAction";
import {AvatarIcon} from "../../../../utils/assets/AvatarIcon";
import {UserType} from "../../../../utils/model/UserType";
import {useRouter} from "../../../../context/RouterContext";
import Button from "@mui/material/Button";
import SearchIcon from '@mui/icons-material/Search';
import React from "react";

interface Props {
    userActions: QuerySnapshot
}

const HistoryList = ({userActions}: Props) => {
    const {t, i18n} = useTranslation();
    moment.locale(i18n.language)
    const {printDisplayName, profile} = useProfile();
    const {navigate} = useRouter();

    return (
        <List className="history-list">
            {
                userActions.docs.map((userAction, index) => {
                    const userActionData = userAction.data() as UserAction
                    const date = moment(userActionData.date)
                    return <ListItem key={`userAction-${index}`}
                                     className="history-list-item">
                        <div className="price-time">
                            <div>
                                <span
                                    className="price">{displayPriceToFixed(userActionData.price) + '\xa0' + t('CHF')}</span>
                                <span className="time">{'\xa0-\xa0' + date.fromNow()}</span>
                            </div>
                        </div>
                        <div className="display-name">
                            {printDisplayName(userActionData.publicProfile)}
                        </div>
                        <div className="avatar">
                            <Avatar sizes="small" alt={printDisplayName(userActionData.publicProfile)}
                                    src={AvatarIcon.forAvatar(userActionData.publicProfile?.avatar)}/>
                        </div>
                        {(profile?.type === UserType.ADMIN || profile?.type === UserType.SUBADMIN) &&
                            <Button
                                color="error"
                                sx={{
                                    borderStyle: "dashed",
                                    borderWidth: "1px",
                                    minWidth: "auto"
                                }}
                                onClick={() => navigate("/admin/user/" + userActionData.userId)}>
                                <SearchIcon />
                            </Button>
                        }
                    </ListItem>
                })
            }
        </List>
    )
}
export default HistoryList
