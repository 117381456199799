import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Stack,
    Typography
} from "@mui/material"
import { useTranslation } from "react-i18next"
import { Fuel } from "../../../utils/model/Fuel"
import { FuelType } from "../../../utils/model/FuelType"
import { Station } from "../../../utils/model/Station"
import { canParticipate, displayPriceToFixed } from "../../../utils/helpers/LocalPrice";
import React, { useEffect, useState } from "react";
import { useGeolocated } from "react-geolocated";
import GeolocationAcquireDialog from "./GeolocationAcquireDialog";
import GeolocationAvailableDialog from "./GeolocationAvailableDialog";
import GeolocationEnabledDialog from "./GeolocationEnabledDialog";
import ForbiddenParticipationDialog from "./ForbiddenParticipationDialog";
import { useProfile } from "../../../context/ProfileContext";
import { tcsBlueGray1, Theme } from "../../../mui/Theme"
import { ReactComponent as TrophyIcon } from '../../../icons/Icon_Pokal3.svg'

interface Props {
    station: Station,
    fuel: Fuel,
    fuelType: FuelType,
    onClose(): void,
    onConfirm(e: React.MouseEvent<HTMLElement>, coords: { latitude: number, longitude: number }): void
}

const ConfirmPriceDialog = ({ station, fuel, fuelType, onClose, onConfirm }: Props) => {
    const { t } = useTranslation()
    const [hasPermission, setHasPermission] = useState(false);
    const { profile } = useProfile();

    const { coords, isGeolocationAvailable, isGeolocationEnabled } =
        useGeolocated({
            positionOptions: {
                enableHighAccuracy: false,
            },
            userDecisionTimeout: 5000,
        });

    const textOptions = {
        price: fuel.displayPrice ?? '{{price}}',
        fuel: t(fuelType) ?? '{{fuel}}',
        station: station.displayName ?? '{{station}}'
    }

    useEffect(() => {
        setHasPermission(canParticipate(coords, profile, station));
    }, [setHasPermission, coords, profile, station]);

    return !isGeolocationAvailable ?
        (<GeolocationAvailableDialog isGeolocationAvailable={isGeolocationAvailable} onClose={onClose} />) :
        !isGeolocationEnabled ?
            (<GeolocationEnabledDialog isGeolocationEnabled={isGeolocationEnabled} onClose={onClose} />) :
            coords ? (
                <>
                    {hasPermission ?
                        (<Dialog open={true} onClose={onClose} className='dialog'>
                            <DialogTitle>
                                <Stack alignItems={'flex-start'}>
                                    <Typography variant="h3" sx={{ mb: 0.5 }}>{t('Confirm price')}</Typography>
                                    <Typography variant="body1" sx={{ mt: 0.25 }}>{t('PriceConfirm', textOptions)}</Typography>
                                </Stack>
                            </DialogTitle>

                            <DialogContent>
                                <Stack alignItems={'center'} justifyContent={'center'}
                                    sx={{ background: tcsBlueGray1, borderRadius: '8px', p: 1 }}>
                                    <Typography fontWeight={'bold'} fontSize={14} color={Theme.palette.grey[700]}>{textOptions.fuel}</Typography>
                                    <Typography variant="h3" fontSize={32} color="secondary">{t('CHF')}&nbsp;{displayPriceToFixed(textOptions.price)}</Typography>
                                </Stack>

                                <Stack direction={'row'} gap={1} sx={{p: 1, my: 1}}>
                                    <TrophyIcon className="customSVGIcon size48" />
                                    <Typography fontSize={11}>{t('PriceConfirmWin')}</Typography>
                                </Stack>
                            </DialogContent>
                            <DialogActions sx={{mt:1}}>
                                <Button variant="outlined" color="secondary" sx={{fontSize:14}} onClick={onClose}>{t('Cancel')}</Button>
                                <Button variant="contained" color="secondary" sx={{fontSize:14}} onClick={(e) => onConfirm(e, coords)}>{t('Confirm')}</Button>
                            </DialogActions>
                        </Dialog>) :
                        (<ForbiddenParticipationDialog hasPermission={hasPermission} onClose={onClose} />)
                    }
                </>
            ) : (<GeolocationAcquireDialog coords={coords} onClose={onClose} />);
}

export default ConfirmPriceDialog
