import { DocumentData, QueryDocumentSnapshot, SnapshotOptions, WithFieldValue } from "firebase/firestore";
import { Reward } from "../model/Reward";
import DateConverter from "./DateConverter";
import PublicProfileConverter from "./PublicProfileConverter";

export const RewardConverter = {
  toFirestore(Reward: WithFieldValue<Reward>): DocumentData {
    return { ...Reward };
  },
  fromFirestore(
    snapshot: QueryDocumentSnapshot,
    options: SnapshotOptions
  ): Reward {
    const data = snapshot.data(options)! ;
    return RewardConverter.fromData(data);
  },
  fromData(data: any): Reward{
    return { ...data, date: DateConverter.fromData(data.date), emitterPublicProfile: PublicProfileConverter.fromData(data.emitterPublicProfile)} as Reward
  }

}
export default RewardConverter
