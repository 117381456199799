
interface Props {
  lat: number
  lng: number
}

const GMapUserMarker = (_props: Props) => {
  return (
    <div className="user-marker" />
  )
}

export default GMapUserMarker
