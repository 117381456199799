import {Dialog, DialogActions, DialogContent, DialogContentText} from "@mui/material";
import Button from "@mui/material/Button";
import React from "react";
import {Station} from "../../utils/model/Station";

interface Props {
    station: Station,
    onValidate(e: React.MouseEvent<HTMLElement>, stationId: string): void,
    onClose(): void
}

export default function ValidateDeleteStationAdminDialog({station, onValidate, onClose}: Props) {
    return (
        <Dialog
            open={true}
            onClose={onClose}
            className='dialog'>
            <DialogContent>
                <DialogContentText>
                    {"Are you sure to delete this station?"}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button variant="contained" color="secondary" onClick={(e) => onValidate(e, station.id)}>Yes</Button>
                <Button variant="contained" color="secondary" onClick={onClose}>No</Button>
            </DialogActions>
        </Dialog>
    )
}