import "./ContestItem.scss"
import { doesContestEndsInFuture, isContestActive } from "../../../utils/model/Contest"
import ContestPrizeItem from "./ContestPrizeItem";
import moment from "moment/moment";
import { useTranslation } from "react-i18next";
import { Grid, Paper, Typography } from "@mui/material";
import { Stack } from "@mui/system";
import { tcsBeige2, tcsBlueGray1, tcsBlueGray3, Theme } from "../../../mui/Theme";
import { ContestWithPrizes } from "../../../context/ContestContext";
import { langFromi18nLang } from "../../../utils/helpers/Lang";
import React, { useState } from "react";
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

interface Props {
    index: number,
    contest: ContestWithPrizes,
    preview?: boolean
}

const ContestListItem = ({ index, contest, preview = false}: Props) => {
    const { t, i18n } = useTranslation();
    moment.locale(i18n.language);
    const lang = langFromi18nLang(i18n.language)

    const isActive = isContestActive(contest)
    const endsInFuture = doesContestEndsInFuture(contest)

    const statusTextColor = isActive ? Theme.palette.success.main : Theme.palette.error.main
    const statusText = isActive ? t('ContestListItem_Active_Description') : t('ContestListItem_Inactive_Description')
    const drawingWinnerText = endsInFuture ? t('ContestListItem_FutureDrawing_Description') : t('ContestListItem_PastDrawing_Description')

    const [isPrizesVisible, setIsPrizesVisible] = useState(false);

    const togglePrizesVisibility = () => {
        setIsPrizesVisible(!isPrizesVisible);
    };

    return (
        <Paper elevation={1} sx={{ mb: 4, background: isActive || preview ? 'white' : (index % 2 === 0) ? tcsBlueGray1 : tcsBlueGray3, borderRadius: '8px 8px 8px 8px' }}>
            <Stack
                p={2}
                direction={{ xs: 'column', sm: 'row' }}
                alignItems={{ xs: 'flex-start', sm: 'center' }}
                justifyContent='space-between'
                flexWrap='wrap'
                gap={1}
                sx={{
                    background: isActive || preview ? tcsBeige2 : (index % 2 === 0) ? tcsBlueGray1 : tcsBlueGray3,
                    borderRadius: '8px 8px 8px 8px'
                }}
                {...(!isActive || !preview ? {onClick: togglePrizesVisibility} : {})}>
                <div>
                    <Typography variant={"h3"}>{contest[`displayName_${lang}`]}</Typography>
                    <Typography fontSize={12}>{moment(contest.startDate).format('L')} - {moment(contest.endDate).format('L')}</Typography>
                </div>
                <Stack direction='row'>
                    <Stack alignItems={{ xs: 'flex-start', sm: 'flex-end' }}>
                        <Typography variant="h4" color={statusTextColor} className={isActive ? 'shine green' : ''}>{statusText}</Typography>
                        <Typography fontSize={12}>{drawingWinnerText} {moment(contest.endDate).fromNow()}</Typography>
                    </Stack>
                    {!isActive &&
                        <div style={{padding: "8px", textAlign: "center", color: Theme.palette.secondary.main}}>
                            {isPrizesVisible ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                        </div>
                    }
                </Stack>
            </Stack>

            {(isPrizesVisible || isActive || preview) &&
                <Grid container spacing={1} py={1} px={{ xs: 0, sm: 1 }} alignItems="stretch">
                    {contest.prizes
                        .sort((a, b) => {
                            const orderA:number = a.order ?? Infinity
                            const orderB:number = b.order ?? Infinity
                            return orderA - orderB //lower first
                        })
                        .map((prize) => {
                            return <Grid key={prize.id} item xs={6} sm={4}>
                                <ContestPrizeItem
                                    contest={contest}
                                    prize={prize}
                                    preview={preview}
                                />
                            </Grid>
                        })}
                </Grid>
            }
        </Paper>
    )
}

export default ContestListItem
