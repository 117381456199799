import {useDocumentOnce} from "react-firebase-hooks/firestore";
import {doc} from "firebase/firestore";
import {firestore} from "../../config/firebase";
import {useParams} from "react-router-dom";
import StationConverter from "../../utils/converters/StationConverter";
import {Button, CircularProgress, Stack, TextField} from "@mui/material";
import {Brand, Station} from "../../utils/model/Station";
import React, {useEffect, useState} from "react";
import {tcsBlueGray1} from "../../mui/Theme";
import {updateStationAdmin} from "../../utils/Api";
import ErrorResponseDialog from "../station/components/ErrorResponseDialog";
import DialogLoader from "../common/DialogLoader";
import {StationAdminDTO} from "../../utils/model/StationAdminDTO";
import OperationAdminDialog from "./OperationAdminDialog";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { useNavigate } from 'react-router-dom';
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

export default function AdminUpdateStation() {
    const navigate = useNavigate();
    const {stationId} = useParams();
    const [stationSnapshot, loadingStation] = useDocumentOnce(doc(firestore, 'stations', stationId ?? "undefined").withConverter(StationConverter));
    const [station, setStation] = useState<Station | undefined>();
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState<TemplateStringsArray | undefined>();
    const [resultResponse, setResultResponse] = useState<string | undefined>(undefined);

    const [displayNameError, setDisplayNameError] = useState(false);
    const [formattedAddressError, setFormattedAddressError] = useState(false);
    const [latitudeError, setLatitudeError] = useState(false);
    const [longitudeError, setLongitudeError] = useState(false);

    const handleSaveStation = async () => {
        setIsLoading(true);

        if (station) {
            if (station.displayName === '') {
                setDisplayNameError(true);
            }

            if (station.formattedAddress === '') {
                setFormattedAddressError(true);
            }

            if (!station.location.lat || station.location.lat === 0) {
                setLatitudeError(true);
            }

            if (!station.location.lng || station.location.lng === 0) {
                setLongitudeError(true);
            }

            if (station.displayName !== ''
                && station.formattedAddress !== ''
                && (station.location.lat || station.location.lat === 0)
                && (station.location.lng || station.location.lng === 0))
            try {
                const stationDto: StationAdminDTO = {
                    id: station.id,
                    displayName: station.displayName,
                    brand: station.brand,
                    formattedAddress: station.formattedAddress,
                    location: station.location
                }
                const result = await updateStationAdmin(stationDto);
                setResultResponse(result.data.message);
            } catch (error: any) {
                setError(error.message);
            }
        }

        setIsLoading(false);
    }

    const handleChangeDisplayName = (title: string) => {
        setStation((prevStation: Station | undefined) => ({
            ...prevStation!,
            displayName: title
        }));
        if (title !== "") {
            setDisplayNameError(false);
        }
    }

    const handleChangeFormattedAddress = (address: string) => {
        setStation((prevStation: Station | undefined) => ({
            ...prevStation!,
            formattedAddress: address
        }));
        if (address !== "") {
            setFormattedAddressError(false);
        }
    }

    const handleChangeLatitude = (latitude: string) => {
        const coord = station?.location;
        if (coord) {
            coord.lat = parseFloat(latitude) || 0;
            setStation((prevStation: Station | undefined) => ({
                ...prevStation!,
                location: coord
            }));
        }
        if (latitude !== "") {
            setLatitudeError(false);
        }
    }

    const handleChangeLongitude = (longitude: string) => {
        const coord = station?.location;
        if (coord) {
            coord.lng = parseFloat(longitude) || 0;
            setStation((prevStation: Station | undefined) => ({
                ...prevStation!,
                location: coord
            }));
        }
        if (!longitude) {
            setLongitudeError(false);
        }
    }

    const handleChangeBrand = (brand: string) => {
        setStation((prevStation: Station | undefined) => ({
            ...prevStation!,
            brand: brand
        }));
    };

    useEffect(() => {
        if (stationSnapshot) {
            setStation(stationSnapshot.data() as Station);
        }
    }, [stationSnapshot, stationId]);

    return (
        <>
            {isLoading &&
                <DialogLoader loading={isLoading} onClose={() => setIsLoading(!isLoading)}/>
            }
            {error &&
                <ErrorResponseDialog errorCode={error} onClose={() => {
                    setError(undefined);
                }}/>
            }
            {resultResponse &&
                <OperationAdminDialog message={resultResponse} onClose={() => setResultResponse(undefined)}/>
            }
            <Stack direction='column' height={'100%'}>
                {loadingStation &&
                    <CircularProgress
                        color="secondary"
                        size={64}
                        disableShrink
                        thickness={4}
                        sx={{
                            margin: "auto",
                        }}
                    />
                }
                {!loadingStation && station &&
                    <form autoComplete="off" onSubmit={handleSaveStation}>
                        <Stack sx={{
                            display: "flex",
                            flexDirection: "column",
                            borderRadius: '5px',
                            background: tcsBlueGray1,
                            gap: 2,
                            padding: 2,
                            margin: 2
                        }}>
                            <h3>Station {station.id}</h3>
                            <TextField
                                value={station.displayName}
                                label="Display name"
                                variant="outlined"
                                required
                                error={displayNameError}
                                onChange={(e: any) =>
                                    handleChangeDisplayName(e.target.value)}
                            />
                            <TextField
                                value={station.formattedAddress}
                                label="Formatted address"
                                variant="outlined"
                                required
                                error={formattedAddressError}
                                onChange={(e: any) =>
                                    handleChangeFormattedAddress(e.target.value)}
                            />
                            <InputLabel id="brand-label">Brand</InputLabel>
                            <Select
                                labelId="brand-label"
                                label="Brand"
                                value={station.brand}
                                onChange={(e: any) =>
                                    handleChangeBrand(e.target.value)}
                            >
                                {Object.values(Brand).map((brand) => (
                                    <MenuItem key={brand} value={brand}>{brand}</MenuItem>
                                ))}
                            </Select>
                            <h4>Location</h4>
                            <Stack sx={{
                                display: "flex",
                                flexDirection: "row",
                                gap: 2
                            }}>
                                <TextField
                                    value={station.location.lat}
                                    type="number"
                                    label="Latitude"
                                    variant="outlined"
                                    required
                                    error={latitudeError}
                                    onChange={(e: any) =>
                                        handleChangeLatitude(e.target.value)}
                                />
                                <TextField
                                    value={station.location.lng}
                                    type="number"
                                    label="Longitude"
                                    variant="outlined"
                                    required
                                    error={longitudeError}
                                    onChange={(e: any) =>
                                        handleChangeLongitude(e.target.value)}
                                />
                            </Stack>

                            <Stack sx={{
                                flexDirection: "row",
                                gap: 1
                            }}>
                                <Button
                                    variant="contained"
                                    type="button"
                                    onClick={() => navigate(-1)}
                                >
                                    <ArrowBackIcon />
                                </Button>
                                <Button
                                    color="secondary"
                                    variant="contained"
                                    onClick={handleSaveStation}
                                >
                                    Save
                                </Button>
                            </Stack>
                        </Stack>
                    </form>
                }
            </Stack>
        </>
    )
}