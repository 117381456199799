import { MapViewport } from "./GMap"

const LOCALSTORAGE_LATESTMAPVIEWPORT_KEY = "latestMapViewport"
const LOCALSTORAGE_LATESTUSERPOSITION_KEY = "latestKnownPosition"
const LOCALSTORAGE_ISLATESTPOSITIONERROR_KEY = "isLatestGeolocationRequestAnError"

function getMapViewportFromStorage(key: string): MapViewport | undefined {
    const storageString = localStorage.getItem(key)
    if (!storageString) {
        return undefined
    }
    const storageZoomState = JSON.parse(storageString)
    if (storageZoomState.zoom
        && storageZoomState.bounds
        && storageZoomState.center
        && storageZoomState.viewportDate) {
        return {...storageZoomState, viewportDate: new Date(storageZoomState.viewportDate)}
    }
    return undefined
}

export class MapStateStorage {
    static getLatestMapViewport(): MapViewport | undefined {
        return getMapViewportFromStorage(LOCALSTORAGE_LATESTMAPVIEWPORT_KEY)
    }

    static saveMapViewport(state: MapViewport) {
        localStorage.setItem(LOCALSTORAGE_LATESTMAPVIEWPORT_KEY, JSON.stringify(state))
    }

    static getLatestKnownPosition(): MapViewport | undefined {
        return getMapViewportFromStorage(LOCALSTORAGE_LATESTUSERPOSITION_KEY)
    }

    static saveLatestKnownPosition(state: MapViewport) {
        localStorage.setItem(LOCALSTORAGE_LATESTUSERPOSITION_KEY, JSON.stringify(state))
    }

    static isLatestGeolocationRequestAnError(): boolean | undefined {
        const string = localStorage.getItem(LOCALSTORAGE_ISLATESTPOSITIONERROR_KEY)
        return string ? (string === "true") : undefined
    }

    static saveIsLatestGeolocationRequestAnError(isError: boolean) {
        localStorage.setItem(LOCALSTORAGE_ISLATESTPOSITIONERROR_KEY, String(isError))
    }

    static isViewPortRecent(viewport:MapViewport) : boolean {
        return (viewport.viewportDate.getTime() >= (new Date()).getTime() - 30 * 60 * 1000)
    }
}

