import 'moment/locale/de';
import 'moment/locale/fr';
import 'moment/locale/it';
import ReactDOM from "react-dom"
import {BrowserRouter} from "react-router-dom";

import * as serviceWorkerRegistration from "./serviceWorkerRegistration";

import App from './App';

import './i18n';

import {CssBaseline} from '@mui/material';
import {AuthContextProvider} from './context/AuthContext';
import {ProfileContextProvider} from './context/ProfileContext';
import {RouterContextProvider} from './context/RouterContext';
import reportWebVitals from './reportWebVitals';
import {AuthenticationResult, EventMessage, EventType, PublicClientApplication} from "@azure/msal-browser";
import {msalConfig} from "./config/azure";
import {MsalProvider} from "@azure/msal-react";

const root = document.getElementById('root');

const msalInstance = new PublicClientApplication(msalConfig);

msalInstance.initialize().then(() => {
    const accounts = msalInstance.getAllAccounts();
    if (accounts.length > 0) {
        msalInstance.setActiveAccount(accounts[0]);
    }

    msalInstance.addEventCallback((event: EventMessage) => {
        if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
            const payload = event.payload as AuthenticationResult;
            const account = payload.account;
            msalInstance.setActiveAccount(account);
        }
    });

    ReactDOM.render(
        <BrowserRouter>
            <CssBaseline/>
            <MsalProvider instance={msalInstance}>
                <AuthContextProvider>
                    <ProfileContextProvider>
                        <RouterContextProvider>
                            <App/>
                        </RouterContextProvider>
                    </ProfileContextProvider>
                </AuthContextProvider>
            </MsalProvider>
        </BrowserRouter>,
        root
    );
});

serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
