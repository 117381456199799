import AddIcon from '@mui/icons-material/Add'
import RemoveIcon from '@mui/icons-material/Remove'
import {Button, Stack} from '@mui/material'
import {Dispatch, SetStateAction, SyntheticEvent, useRef, useState} from 'react'
import {displayPriceToFixed, displayPriceToNumber} from '../../../utils/helpers/LocalPrice'
import {getValue, getString} from "firebase/remote-config";
import {remoteConfig} from "../../../config/firebase";
import {FuelType} from "../../../utils/model/FuelType";

interface Props {
    fuelType: FuelType,
    setPrice: Dispatch<SetStateAction<number>>
}

const PriceIncrementer = ({fuelType, setPrice}: Props) => {
    const increment: number = 0.01
    const allDefaultMaxPrice = 5;

    const [interval, setMyInterval] = useState<NodeJS.Timer | null>();
    const [timeout, setMyTimeout] = useState<NodeJS.Timer | null>();

    /* This is a workaround for a ReactJS bug:
    https://github.com/facebook/react/issues/9809#issuecomment-413978405
    The touchStart event with preventDefault() in its callback is supposed
    to cancel the mouseDown event, but it doesn't. Using the low level DOM API
    with a React ref fixes this.
    Without this workaround, the counters would increment/decrement 2 by 2
    on touch devices.
     */
    const incrementBtn = useRef<HTMLButtonElement>(null);
    const decrementBtn = useRef<HTMLButtonElement>(null);
    const defaultMinPrice = getString(remoteConfig, `${fuelType}_DEFAULT_MIN_PRICE`) !== ""
        ? getValue(remoteConfig, `${fuelType}_DEFAULT_MIN_PRICE`).asNumber()
        : 0;
    const defaultMaxPrice = getString(remoteConfig, `${fuelType}_DEFAULT_MAX_PRICE`) !== ""
        ? getValue(remoteConfig, `${fuelType}_DEFAULT_MAX_PRICE`).asNumber()
        : allDefaultMaxPrice;

    const priceDownStart = (event: SyntheticEvent | TouchEvent) => {
        event.preventDefault();
        setPrice(seconds => displayPriceToNumber(displayPriceToFixed((seconds - increment) >= defaultMinPrice ? seconds - increment : defaultMinPrice)));
        setMyTimeout(setTimeout(() => setMyInterval(setInterval(() => {
                setPrice(
                    seconds => displayPriceToNumber(displayPriceToFixed((seconds - increment) >= defaultMinPrice ? seconds - increment : defaultMinPrice))
                );
            },
            100
        )), 500))

    }

    const priceDownStop = () => {
        clearInterval(interval!);
        clearTimeout(timeout!);
        setMyInterval(null)
        setMyTimeout(null)
    }

    const priceUpStart = (event: SyntheticEvent | TouchEvent) => {
        event.preventDefault();
        setPrice(seconds => displayPriceToNumber(displayPriceToFixed((seconds + increment) <= defaultMaxPrice ? seconds + increment : defaultMaxPrice)));
        setMyTimeout(setTimeout(() => setMyInterval(setInterval(() => {
                setPrice(
                    seconds => displayPriceToNumber(displayPriceToFixed((seconds + increment) <= defaultMaxPrice ? seconds + increment : defaultMaxPrice))
                );
            },
            100
        )), 500))
    }
    const priceUpStop = () => {
        clearInterval(interval!);
        clearTimeout(timeout!);
        setMyInterval(null)
        setMyTimeout(null)
    }

    if (incrementBtn.current) {
        incrementBtn.current.ontouchstart = priceUpStart;
    }
    if (decrementBtn.current) {
        decrementBtn.current.ontouchstart = priceDownStart;
    }

    return (
        <Stack direction={'row'} gap={1} className={"buttons-incrementer"}>
            <Button
                ref={decrementBtn}
                variant='contained'
                color='secondary'
                onMouseDown={priceDownStart}
                onMouseUp={priceDownStop}
                onMouseLeave={priceDownStop}
                onTouchEnd={priceDownStop}>
                <RemoveIcon/>
            </Button>
            <Button
                ref={incrementBtn}
                variant='contained'
                color='secondary'
                onMouseDown={priceUpStart}
                onMouseUp={priceUpStop}
                onMouseLeave={priceUpStop}
                onTouchEnd={priceUpStop}>
                <AddIcon/>
            </Button>
        </Stack>
    )
}

export default PriceIncrementer
