const LOCALSTORAGE_ISWEBVIEW_KEY = "isWebview"

export class IsWebviewStorage {
    static getIsWebview(): Boolean {
        const storageString = localStorage.getItem(LOCALSTORAGE_ISWEBVIEW_KEY)
        if (!storageString) { return false }
        return (storageString === "true" || storageString === "1")
    }

    static saveIsWebview(isWebview: Boolean) {
        localStorage.setItem(LOCALSTORAGE_ISWEBVIEW_KEY, String(isWebview))
    }
}

