import {Dialog, DialogActions, DialogContent, DialogContentText} from "@mui/material";
import Button from "@mui/material/Button";
import React from "react";
import {useTranslation} from "react-i18next";

interface Props {
    onClose(...args: any): void
}

export default function ReportDialog({onClose}: Props) {
    const {t} = useTranslation();

    return (
        <Dialog
            open={true}
            onClose={onClose}
            className='dialog'>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    {t("report_station")}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button variant="contained" color="secondary" onClick={onClose}>{t('Close')}</Button>
            </DialogActions>
        </Dialog>
    )
}