import { ListItem, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { Color } from '../../utils/assets/ColorFromFiability'
import { FuelType } from '../../utils/model/FuelType'


interface Props {
  fuelType: FuelType
}

const NoGasPrice = ({ fuelType }: Props) => {
  const { t } = useTranslation()

  return (<>
    <ListItem sx={{
      py: 1.5,
      border: 4,
      borderColor: [Color.forFiabilityLevel(undefined)]
    }}
      className="fuel-list-item selected-fuel no-gas-price">
      <div className='fuel-list-item-left'>
        <Typography fontWeight="bold" fontSize={14}>{t(fuelType)}</Typography>
        <Typography fontSize={12} lineHeight={1.2}>
          {t("GasPrice_NoFuelPrice", {
            fuelType: t(fuelType)
          })}
        </Typography>
      </div>
    </ListItem >
  </>
  )
}

export default NoGasPrice
