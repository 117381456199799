import { t } from "i18next";
import { Fiability } from "../model/Fiability";
import { FiabilityLevel } from "../model/FiabilityLevel";

export class Text {
    static forFiability(fiability: Fiability): string {
        switch (fiability?.level) {
            case FiabilityLevel.CONFIDENT:
                return t('ChipText_FiabilityLevel_CONFIDENT')
            case FiabilityLevel.OUTDATED_LAST_PRICE_UPDATE:
                return t('ChipText_FiabilityLevel_OUTDATED_LAST_PRICE_UPDATE')
            case FiabilityLevel.OLD_LAST_UPDATE:
                return t('ChipText_FiabilityLevel_OLD_LAST_UPDATE')
            case FiabilityLevel.FEW_RECENT_PRICES:
                return t('ChipText_FiabilityLevel_FEW_RECENT_PRICES')
        }
        return "";
    }

    static explanationForFiability(fiability: Fiability): string {
        switch (fiability?.level) {
            case FiabilityLevel.CONFIDENT:
                return t('ExplanationText_FiabilityLevel_CONFIDENT')
            case FiabilityLevel.OUTDATED_LAST_PRICE_UPDATE:
                return t('ExplanationText_FiabilityLevel_OUTDATED_LAST_PRICE_UPDATE')
            case FiabilityLevel.OLD_LAST_UPDATE:
                return t('ExplanationText_FiabilityLevel_OLD_LAST_UPDATE')
            case FiabilityLevel.FEW_RECENT_PRICES:
                return t('ExplanationText_FiabilityLevel_FEW_RECENT_PRICES')
        }
        return "";
    }
}


