import SimplePage from "../components/SimplePage";
import HowToDo from "../components/prizeWonPage/HowToDo";
import WonPrizeBanner from "../components/prizeWonPage/WonPrizeBanner";
import WonPrizeList from "../components/prizeWonPage/WonPrizeList";
import {useContests} from "../context/ContestContext";
import {Theme} from "../mui/Theme";
import {CircularProgress, Container, Typography} from "@mui/material";
import {useTranslation} from "react-i18next";

export default function WonPrizePage() {
    const {isWonPrizesLoading, userWonPrizes} = useContests();
    const {t} = useTranslation();

    return (
        <div className="contest won-prizes">
            <SimplePage>
                {
                    !isWonPrizesLoading &&
                    <>
                        {
                            userWonPrizes.length > 0 ?
                                <>
                                    <section className="no-margin">
                                        <WonPrizeBanner/>
                                    </section>
                                    <section>
                                        <HowToDo/>
                                    </section>
                                    <section>
                                        <WonPrizeList/>
                                    </section>
                                </>
                                :
                                <>
                                    <section className="no-margin">
                                        <Container sx={{background: Theme.palette.primary.main, pt: 6, pb: 4}}>
                                            <Typography fontSize={{xs: 18, sm: 24}} sx={{
                                                mb: 2,
                                                maxWidth: {xs: '100%', sm: '70%', md: '500px'}
                                            }}>
                                                {t("Not_WonPrizes_Title")}
                                            </Typography>
                                        </Container>
                                    </section>
                                </>
                        }
                    </>
                }
                {
                    isWonPrizesLoading &&
                    <CircularProgress />
                }
            </SimplePage>
        </div>
    )
}