import { ThemeProvider } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { Theme } from './mui/Theme';
import Footer from './components/navigation/Footer';
import { Header } from './components/navigation/Header';
import './scss/App.scss';
import React, { useEffect, useState } from "react";
import Router from './router/Router';
import { t } from "i18next";
import PWAPrompt from 'react-ios-pwa-prompt'

import ErrorIndexedDBDialog from "./components/common/ErrorIndexedDBDialog";

declare module '@mui/material/styles' {
    interface TypographyVariants {
        body1Bold: React.CSSProperties;
    }

    // allow configuration using `createTheme`
    interface TypographyVariantsOptions {
        body1Bold?: React.CSSProperties;
    }
}

// Update the Typography's variant prop options
declare module '@mui/material/Typography' {
    interface TypographyPropsVariantOverrides {
        body1Bold: true;
    }
}

const App = () => {
    useEffect(() => { document.title = t("app_document_title") }, []);
    const [errorIndexedDB, setErrorIndexedD] = useState<boolean>(false);

    useEffect(() => {
        const request = window.indexedDB.open("testIndexedDB", 3);
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        request.onerror = (event) => {
            setErrorIndexedD(true);
        };
    }, []);

    return (
        <div className="App">
            {errorIndexedDB &&
                <ErrorIndexedDBDialog errorIndexedDB={errorIndexedDB} onClose={() => setErrorIndexedD(false)} />
            }
            <ThemeProvider theme={Theme}>
                <Grid container className='main' wrap='nowrap' direction='column' height='100%'>
                    <Grid flexShrink={1}>
                        <Header />
                    </Grid>
                    <Grid flexGrow={1} sx={{ minHeight: '0', overflowY: 'auto' }}>
                        <Router />
                    </Grid>
                    <Footer />
                    <PWAPrompt
                        copyTitle={t('PWAPrompt-copyTitle')}
                        copyBody={t('PWAPrompt-copyBody')}
                        copyShareButtonLabel={t('PWAPrompt-copyShareButtonLabel')}
                        copyAddHomeButtonLabel={t('PWAPrompt-copyAddHomeButtonLabel')}
                        copyClosePrompt={t('Close')}
                        promptOnVisit={3}
                        delay={5000}
                    />
                </Grid>

            </ThemeProvider>
        </div>
    );
}

export default App;
