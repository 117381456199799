import {Station} from "../../utils/model/Station";
import {CircularProgress, Stack} from "@mui/material";
import React, {useEffect, useMemo, useState} from "react";
import GoogleMapReact from "google-map-react";
import {AdminStationMarker} from "./AdminStationMarker";
import {MAP_OPTIONS_DEFAULT} from "./utils/map";
import {gethttpDeletedItemsAdmin, restoreFuelAdmin, restoreStationAdmin} from "../../utils/Api";
import ErrorResponseDialog from "../station/components/ErrorResponseDialog";
import {FuelType} from "../../utils/model/FuelType";
import Button from "@mui/material/Button";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import {useNavigate} from "react-router-dom";

export default function AdminStationsRemoved() {
    const [stations, setStations] = useState<Station[]>([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState<TemplateStringsArray | undefined>();
    const navigate = useNavigate();

    const googleMapReactOptions = {
        bootstrapURLKeys: {
            key: process.env.REACT_APP_GOOGLE_API!,
        },
        defaultCenter: {lat: 46.798543289186775, lng: 8.231108154514448},
        defaultZoom: 8,
        options: MAP_OPTIONS_DEFAULT,
        yesIWantToUseGoogleMapApiInternals: true,
    }

    const memoizedGetDeletedItemsAdmin = useMemo(() => {
        return async () => {
            setLoading(true);
            try {
                const result = await gethttpDeletedItemsAdmin();
                const stations = result as Station[];
                setStations(stations);
            } catch (error: any) {
                setError(error.message);
            }
            setLoading(false);
        }
    }, []);

    const onGetDeletedStations = async () => {
        setLoading(true);
        try {
            const result = await gethttpDeletedItemsAdmin();
            const stations = result as Station[];
            setStations(stations);
        } catch (error: any) {
            setError(error.message);
        }
        setLoading(false);
    }

    useEffect(() => {
        memoizedGetDeletedItemsAdmin();
    }, [memoizedGetDeletedItemsAdmin]);

    const onRestoreStation = async (e: React.MouseEvent<HTMLElement>, stationId: string) => {
        setLoading(true);
        try {
            await restoreStationAdmin(stationId);
            await onGetDeletedStations();
        } catch (error: any) {
            setError(error.message);
        }
        setLoading(false);
    }

    const onRestoreFuel = async (e: React.MouseEvent<HTMLElement>, stationId: string, fuelType: FuelType) => {
        setLoading(true);
        try {
            await restoreFuelAdmin(stationId, fuelType);
            await onGetDeletedStations();
        } catch (error: any) {
            setError(error.message);
        }
        setLoading(false);
    }

    return (
        <>
            <Stack direction='column' height={'100%'}>
                {error &&
                    <ErrorResponseDialog errorCode={error} onClose={() => {
                        setError(undefined);
                    }}/>
                }
                {loading &&
                    <CircularProgress
                        color="secondary"
                        size={64}
                        disableShrink
                        thickness={4}
                        sx={{
                            margin: "auto",
                        }}
                    />
                }
                {!loading &&
                    <div className='google-map active'>
                        <div className='google-map-inner'>
                            <GoogleMapReact {...googleMapReactOptions}>
                                {stations.map((station: Station) => {
                                    return <AdminStationMarker key={station.id}
                                                               lat={station.location.lat}
                                                               lng={station.location.lng}
                                                               station={station}
                                                               onRestoreStation={onRestoreStation}
                                                               onRestoreFuel={onRestoreFuel}
                                    ></AdminStationMarker>
                                })}
                            </GoogleMapReact>
                        </div>
                    </div>
                }
                <Stack sx={{
                    flexDirection: "row",
                    gap: 1,
                    padding: 1
                }}>
                    <Button
                        variant="contained"
                        type="button"
                        onClick={() => navigate("/admin")}
                    >
                        <ArrowBackIcon />
                    </Button>
                </Stack>
            </Stack>
        </>
    )
}