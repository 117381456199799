import {Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography} from "@mui/material"

import {useTranslation} from "react-i18next"
import {useRouter} from "../../../context/RouterContext"

interface Props {
    onClose(): void
}

const LoginDialog = ({onClose}: Props) => {
    const {navigate} = useRouter()
    const {t} = useTranslation()

    const tcsLogin = async (_e: React.MouseEvent<HTMLElement>) => {
        _e.preventDefault();
        _e.stopPropagation();
        navigate('/login');
    }

    return (
        <Dialog open={true} onClose={onClose} className='dialog'>
            <DialogTitle>{t('Please login')}</DialogTitle>
            <DialogContent>
                <Typography>{t('LoginText')}</Typography>
            </DialogContent>
            <DialogActions>
                <Button variant="outlined" color="secondary" onClick={onClose}>{t('Cancel')}</Button>
                <Button variant="contained" color="secondary" onClick={tcsLogin}>{t('Go to login page')}</Button>
            </DialogActions>
        </Dialog>
    )
}

export default LoginDialog
