import LegalMentionsTitle from './components/LegalMentionsTitle';
import LegalMentionsText from './components/LegalMentionsText';

const LegalMentions = () => {

  return (
    <>
    <LegalMentionsTitle></LegalMentionsTitle>
    <LegalMentionsText></LegalMentionsText>
    </>
  )
}

export default LegalMentions
