import Grid from "@mui/material/Unstable_Grid2";
import {Avatar, Paper} from "@mui/material";
import {BrandIcon} from "../../utils/assets/BrandIcon";
import {Theme} from "../../mui/Theme";
import {Station} from "../../utils/model/Station";

interface Props {
    station: Station;
}

export const AdminStationMarkerInfo = ({station}: Props) => {
    const imageSize = 32;
    const border = 2;
    const background = "white";

    return (
        <Paper className='marker-info' elevation={2}
               sx={{
                   background: background,
                   p: '1px',
                   borderRadius: '32px',
                   border: border,
                   borderColor: station.isDeleted ? Theme.palette.error.main : Theme.palette.warning.main
               }}>
            <Grid container gap={0.5} wrap='nowrap' alignItems={'center'}>
                <Grid flexShrink={0}>
                    <Avatar alt="" src={BrandIcon.forStation(station)} sx={{
                        width: imageSize, height: imageSize
                    }}/>
                </Grid>
            </Grid>

        </Paper>
    )
}