import { WithFieldValue, DocumentData, QueryDocumentSnapshot, SnapshotOptions } from "firebase/firestore";
import { ContestPrizeEntry } from "../model/ContestPrizeEntry";

export const ContestPrizeEntryConverter = {
  toFirestore(entry: WithFieldValue<ContestPrizeEntry>): DocumentData {
    return { ...entry };
  },
  fromFirestore(
    snapshot: QueryDocumentSnapshot,
    options: SnapshotOptions
  ): ContestPrizeEntry {
    const data = snapshot.data(options);
    return {
      ...data,
      id: snapshot.id
    } as ContestPrizeEntry
  }
}
export default ContestPrizeEntryConverter
