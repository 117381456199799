import { WithFieldValue, DocumentData, QueryDocumentSnapshot, SnapshotOptions } from "firebase/firestore";
import { FuelCollection } from "../model/FuelCollection";

import FuelConverter from "./FuelConverter";


const FuelCollectionConverter = {
  toFirestore(FuelCollection: WithFieldValue<FuelCollection>): DocumentData {
    return { ...FuelCollection };
  },
  fromFirestore(
    snapshot: QueryDocumentSnapshot,
    options: SnapshotOptions
  ): FuelCollection {
    const data = snapshot.data(options)!;
    return FuelCollectionConverter.fromData(data);
  },
  fromData(data:any){
    for (const key in data) {
      if (data[key].isDeleted) {
        delete data[key];
      } else {
        data[key] = FuelConverter.fromData(data[key]);
      }
    }
    return data as FuelCollection
  }
}
export default FuelCollectionConverter
