import { DocumentData, QueryDocumentSnapshot, SnapshotOptions, WithFieldValue } from "firebase/firestore";
import { Fiability } from "../model/Fiability";
import DateConverter from "./DateConverter";
import FiabilityLevelConverter from "./FiabilityLevelConverter";

export const FiabilityConverter = {
  toFirestore(Fiability: WithFieldValue<Fiability>): DocumentData {
    return { ...Fiability };
  },
  fromFirestore(
    snapshot: QueryDocumentSnapshot,
    options: SnapshotOptions
  ): Fiability {
    const data = snapshot.data(options)! ;
    return FiabilityConverter.fromData(data);
  },
  fromData(data:any){
    return { ...data, lastPriceUpdate: DateConverter.fromData(data.lastPriceUpdate), level: FiabilityLevelConverter.fromData(data.level) } as Fiability;
  }
}
export default FiabilityConverter
