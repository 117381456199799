import { DialogTitle, DialogContent, DialogActions, Button, Dialog, TextField } from "@mui/material"
import { useState } from "react"

import { useTranslation } from "react-i18next"
import { useProfile } from "../../../context/ProfileContext"
interface Props {
  onClose(): void
  onUpdate(value:string):void
  defaultValue: string
}
const DisplayNameDialog = ({ onClose, onUpdate, defaultValue }: Props) => {
  const { t } = useTranslation()

  const { profile } = useProfile()

  const internalClose = () => {
    onClose();
  }
  const internalUpdate = () => {
    if(displayName){
      onUpdate(displayName);
    }
  }

  const [displayName, setDisplayName] = useState(profile?.publicProfile.displayName)

  return (
    <Dialog open={true} onClose={internalClose} className="dialog">
      <DialogTitle>
        <div className='dialog-title'>
          <div className='dialog-title-left'>
            {t('Change displayed name')}
          </div>
        </div>
      </DialogTitle>
      <DialogContent>
        <TextField
          defaultValue={defaultValue}
          onChange={(e: any) =>
            setDisplayName(e.target.value)
          }
          label={t('Display name')}
          required
          type="text"
          placeholder={t('Display name')}
          sx={{ my: 2 }}
        />
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" color="secondary" onClick={internalClose}>{t('Close')}</Button>
        <Button variant="contained" color="secondary" onClick={internalUpdate}>{t('update')}</Button>
      </DialogActions>
    </Dialog >
  )
}

export default DisplayNameDialog
