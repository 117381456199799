import { DocumentData, QueryDocumentSnapshot, SnapshotOptions, WithFieldValue } from "firebase/firestore";
import DateConverter from "./DateConverter";
import { Contest, isContest } from "../model/Contest";

export const ContestConverter = {
  toFirestore(contest: WithFieldValue<Contest | undefined>): DocumentData {
    return { ...contest };
  },
  fromFirestore(
    snapshot: QueryDocumentSnapshot,
    options: SnapshotOptions
  ): Contest | undefined {
    try {
      const data = snapshot.data(options);
      if (!isContest(data)) {
        throw new Error("Received data does not conform to Contest type");
      }

      const contest: Contest = {
        ...data,
        id: snapshot.id,
        startDate: DateConverter.fromData(data.startDate),
        endDate: DateConverter.fromData(data.endDate)
      }
      return contest
    } catch (e) {
      console.error("ContestConverter error " + e)
      return undefined
    }
  }
}
export default ContestConverter
