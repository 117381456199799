import ProfileFinalize from '../components/profile/ProfileFinalize'
import SimplePage from '../components/SimplePage'

const ProfilePage = () => {
  return (
    <SimplePage>
      <ProfileFinalize></ProfileFinalize>
    </SimplePage>
  )
}

export default ProfilePage
