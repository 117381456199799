import {functions} from "../config/firebase";
import {httpsCallable, HttpsCallableResult} from "firebase/functions";
import {UserActionRequestDTO} from "./model/UserAction";
import {ContestPrizeEntryRequestDTO} from "./model/ContestPrizeEntryRequestDTO";
import {FuelType} from "./model/FuelType";
import {ReportDTO} from "./model/Report";
import {ContestAdminDTO} from "./model/ContestAdminDTO";
import {StationAdminDTO} from "./model/StationAdminDTO";

export const httpUserActionHandler = httpsCallable(functions, 'httpUserActionHandler');
export const sendUserAction = async (userActionRequestDTO: UserActionRequestDTO) => {
    try {
        const result = await httpUserActionHandler(userActionRequestDTO)
        localStorage.setItem(`${userActionRequestDTO.stationId}-${userActionRequestDTO.fuelType}-participate`, new Date().toJSON());
        localStorage.setItem(`${userActionRequestDTO.stationId}-${userActionRequestDTO.fuelType}-correction`, new Date().toJSON());
        localStorage.setItem(`${userActionRequestDTO.stationId}-${userActionRequestDTO.fuelType}-price-participate`, new Date().toJSON());
        localStorage.setItem(`${userActionRequestDTO.stationId}-${userActionRequestDTO.fuelType}-price`, userActionRequestDTO.price.toFixed(2));
        return result as HttpsCallableResult<{ reward: object }>;
    } catch (error) {
        throw error;
    }
}

export const httpReportHandler = httpsCallable(functions, 'httpReportHandler');
export const sendReport = async (reportDTO: ReportDTO) => {
    try {
        return (await httpReportHandler(reportDTO));
    } catch (error) {
        throw error;
    }
}

const httpCorrectionUserActionHandler = httpsCallable(functions, 'httpCorrectionUserActionHandler');
export const sendCorrectionUserAction = async (stationId: string, fuelType: FuelType, price: number) => {
    try {
        const result = await httpCorrectionUserActionHandler({stationId, fuelType, price});
        localStorage.setItem(`${stationId}-${fuelType}-price-participate`, new Date().toJSON());
        localStorage.setItem(`${stationId}-${fuelType}-price`, price.toFixed(2));
        return result as HttpsCallableResult<{ message: object }>;
    } catch (error) {
        throw error;
    }
}

const httpContestHandler = httpsCallable(functions, 'httpContestHandler');
export const sendContestUseTokenRequest = async (contestPrizeEntryRequestDTO: ContestPrizeEntryRequestDTO) => {
    try {
        return (await httpContestHandler(contestPrizeEntryRequestDTO))
    } catch (error) {
        throw error;
    }
}

const httpDeleteUserHandler = httpsCallable(functions, 'httpDeleteUserHandler');
export const sendDeleteUser = async () => {
    try {
        return await httpDeleteUserHandler()
    } catch (error) {
        throw error;
    }
}

const httpCreateOrGetProfile = httpsCallable(functions, 'httpCreateOrGetProfile');
export const createOrGetProfile = async () => {
    try {
        const result = await httpCreateOrGetProfile();
        return result.data;
    } catch (error) {
        throw error;
    }
}

const httpUpdateProfileWithData = httpsCallable(functions, 'httpUpdateProfileWithData');
export const updateProfileWithData = async (profile: any) => {
    try {
        return await httpUpdateProfileWithData(profile)
    } catch (error: any) {
        throw error;
    }
}

const httpUserActionsAdmin = httpsCallable(functions, 'httpUserActionsAdmin');
export const getUserActionsAdmin = async (userId: string, hours: number) => {
    try {
        const result = await httpUserActionsAdmin({userId, hours});
        return result.data;
    } catch (error: any) {
        throw error;
    }
}

const httpDeletedItemsAdmin = httpsCallable(functions, 'httpDeletedItemsAdmin');
export const gethttpDeletedItemsAdmin = async () => {
    try {
        const result = await httpDeletedItemsAdmin();
        return result.data;
    } catch (error: any) {
        throw error;
    }
}

const httpOperationUserAdmin = httpsCallable(functions, 'httpOperationUserAdmin');
export const sendOperationUserAdmin = async (userId: string, operationId: number, reason?: string) => {
    try {
        const result = await httpOperationUserAdmin({userId, operationId, reason});
        return result as HttpsCallableResult<{ message: string }>;
    } catch (error: any) {
        throw error;
    }
}

const httpDeleteFuelAdmin = httpsCallable(functions, 'httpDeleteFuelAdmin');
export const deleteFuelAdmin = async (stationId: string, fuelType: FuelType) => {
    try {
        const result = await httpDeleteFuelAdmin({stationId, fuelType});
        return result as HttpsCallableResult<{ message: string }>;
    } catch (error: any) {
        throw error;
    }
}

const httpDeleteStationAdmin = httpsCallable(functions, 'httpDeleteStationAdmin');
export const deleteStationAdmin = async (stationId: string) => {
    try {
        const result = await httpDeleteStationAdmin({stationId});
        return result as HttpsCallableResult<{ message: string }>;
    } catch (error: any) {
        throw error;
    }
}

const httpRestoreStationAdmin = httpsCallable(functions, 'httpRestoreStationAdmin');
export const restoreStationAdmin = async (stationId: string) => {
    try {
        const result = await httpRestoreStationAdmin({stationId});
        return result as HttpsCallableResult<{ message: string }>;
    } catch (error: any) {
        throw error;
    }
}

const httpRestoreFuelAdmin = httpsCallable(functions, 'httpRestoreFuelAdmin');
export const restoreFuelAdmin = async (stationId: string, fuelType: FuelType) => {
    try {
        const result = await httpRestoreFuelAdmin({stationId, fuelType});
        return result as HttpsCallableResult<{ message: string }>;
    } catch (error: any) {
        throw error;
    }
}

const httpContestAdmin = httpsCallable(functions, 'httpContestAdmin');
export const setContestAdmin = async (contestAdminDTO: ContestAdminDTO) => {
    try {
        const result = await httpContestAdmin(contestAdminDTO);
        return result as HttpsCallableResult<{ message: string }>;
    } catch (error: any) {
        throw error;
    }
}

const httpUpdateStationAdmin = httpsCallable(functions, 'httpUpdateStationAdmin');
export const updateStationAdmin = async (stationAdminDTO: StationAdminDTO) => {
    try {
        const result = await httpUpdateStationAdmin(stationAdminDTO);
        return result as HttpsCallableResult<{ message: string }>;
    } catch (error: any) {
        throw error;
    }
}


const httpCreateStationAdmin = httpsCallable(functions, 'httpCreateStationAdmin');
export const createStationAdmin = async (stationAdminDTO: StationAdminDTO) => {
    try {
        const result = await httpCreateStationAdmin(stationAdminDTO);
        return result as HttpsCallableResult<{ message: string }>;
    } catch (error: any) {
        throw error;
    }
}