import { ArrowForward, Login } from '@mui/icons-material';
import {
    Box,
    Button,
    Stack,
    Typography
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { useProfile } from "../../context/ProfileContext";
import { useRouter } from "../../context/RouterContext";
import { ReactComponent as LotteryTicket } from '../../icons/tcs-icon_lotteryticket.svg'
import { tcsBlueGray1, tcsBlueGray3, Theme } from '../../mui/Theme';

export const ContestCurrency = () => {
    const { t } = useTranslation();
    const { profile } = useProfile();
    const { navigate } = useRouter()

    const goToLogin = () => {
        navigate('/login')
    }

    const showTokens = Boolean(profile)
    const numberOfTokens = profile ? profile.lotteryTickets : 0

    let borderColor = ""
    let shineClass = ""
    let mainText = ""

    if (showTokens) {
        if (numberOfTokens > 0) {
            borderColor = Theme.palette.success.main
            shineClass = 'shine green'
            mainText = numberOfTokens > 1
                ? t("ContestCurrency_CreateAccount_YouCanUse", { count: numberOfTokens })
                : t("ContestCurrency_CreateAccount_YouCanUse_Singular", { count: numberOfTokens })
        } else {
            borderColor = Theme.palette.error.main
            shineClass = 'shine red'
            mainText = t("ContestCurrency_CreateAccount_YouCanUse_Zero")
        }
    } else {
        borderColor = tcsBlueGray3
        shineClass = 'shine blue'
        mainText = t("Create an account and participate!")
    }


    if (showTokens && numberOfTokens <= 0) {
        borderColor = Theme.palette.error.main
        shineClass = 'shine red'
    }

    return (
        <Box
            sx={{
                borderRadius: '8px',
                p: 2,
                background: tcsBlueGray1,
                border: 2,
                borderColor: borderColor
            }}>

            {showTokens && (
                <Stack direction='row' gap={2} alignItems='center'>
                    <Box sx={{
                        flexShrink: 0,
                        width: 44, height: 44,
                        pl: '4px', pt: '4px',
                        background: Theme.palette.primary.main,
                        border: 2,
                        borderColor: borderColor
                    }}
                        borderRadius='50%'>
                        <LotteryTicket className={"lotterySVG customSVGIcon size32"} />
                    </Box>
                    <Stack gap={0.5}>
                        <Typography variant={"body1Bold"} lineHeight={1.25} className={shineClass}>
                            {mainText}
                        </Typography>

                        <Typography fontSize={11}>
                            {t("You receive one token for each 100 participation points")}
                        </Typography>
                    </Stack>
                </Stack>
            )}



            {!showTokens && (
                <Stack direction='row' gap={2}
                    alignItems='center'>
                    <Box sx={{
                        flexShrink: 0,
                        width: 44, height: 44,
                        pl: '4px', pt: '4px',
                        background: Theme.palette.primary.main,
                        border: 2,
                        borderColor: borderColor
                    }}
                        borderRadius='50%'>
                        <Login fontSize='large' />
                    </Box>
                    <Stack gap={1}
                        direction={'row'}
                        flexWrap='wrap'
                        alignItems='center'
                        flexGrow={1}
                        justifyContent='space-between'
                    >
                        <Stack gap={0.5}>
                            <Typography variant={"body1Bold"} lineHeight={1.25} className={shineClass}>
                                {mainText}
                            </Typography>
                            <Typography fontSize={12} sx={{ maxWidth: '500px' }}>
                                {t("ContestCurrency_CreateAccount_Description")}
                            </Typography>
                        </Stack>

                        <Button
                            variant={'contained'}
                            color={'secondary'}
                            sx={{ fontWeight: 'bold' }}
                            endIcon={<ArrowForward />}
                            onClick={goToLogin}>
                            {t("Register")}
                        </Button>
                    </Stack>
                </Stack>
            )}
        </Box>
    )
}
