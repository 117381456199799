import { Typography } from '@mui/material'
import { useTranslation } from 'react-i18next';
const LegalMentionsText = () => {
  const { t } = useTranslation();
  return (
    <Typography variant="body1">
      {t('Mentions Body')}
    </Typography>
  )
}

export default LegalMentionsText
