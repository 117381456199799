import { doc, runTransaction } from 'firebase/firestore'
import { createContext, useContext, useEffect, useState } from 'react'
import { useDocument } from 'react-firebase-hooks/firestore'
import { useTranslation } from 'react-i18next'
import { firestore } from '../config/firebase'
import { updateProfileWithData } from '../utils/Api'
import ProfileConverter from '../utils/converters/ProfileConverter'
import PublicProfileConverter from '../utils/converters/PublicProfileConverter'
import { Profile } from '../utils/model/Profile'
import { PublicProfile } from '../utils/model/PublicProfile'
import { UserAvatar } from '../utils/model/UserAvatar'
import { useAuth } from './AuthContext'

interface ProfileContextInterface {
    profile: Profile | null;
    clearUnseenRewards(): void;
    updateAvatar(avatar: string): void;
    updateDisplayName(displayName: string): void;
    printDisplayName(publicProfile: PublicProfile): string;
    updateProfile(profile: Profile): void;
    updateAcceptFuelMailing(value: boolean): void;
    updateAcceptWinnerMailing(value: boolean): void;
}

const ProfileContext = createContext<ProfileContextInterface>({
    profile: null,
    clearUnseenRewards: () => { return },
    updateAvatar: (avatar: string) => { return },
    updateDisplayName: (displayName: string) => { return },
    printDisplayName: (publicProfile: PublicProfile): string => { return '' },
    updateProfile: (newProfile: Profile) => { return },
    updateAcceptFuelMailing: (value: boolean) => { return },
    updateAcceptWinnerMailing: (value: boolean) => { return },
})

export const useProfile = () => useContext(ProfileContext)

export const ProfileContextProvider = ({
    children,
}: {
    children: React.ReactNode
}) => {
    const { user } = useAuth();
    const [profile, setProfile] = useState<Profile | null>(null);
    const uid = user ? user : 'undefined';
    const { t } = useTranslation();

    const [snapshot, loading] = useDocument(
        doc(firestore, 'profiles', uid).withConverter(ProfileConverter),
        {
            snapshotListenOptions: { includeMetadataChanges: true },
        }
    );

    useEffect(() => {
        if (snapshot) {
            const userProfile = snapshot.data() as Profile;
            setProfile(userProfile)
        } else {
            setProfile(null)
        }
        // eslint-disable-next-line
    }, [snapshot])

    const clearUnseenRewards = async () => {
        const ref = snapshot?.ref!
        try {
            await runTransaction(firestore, async (transaction) => {
                transaction.update(ref, {
                    unseenRewards: [],
                    unseenPoints: 0
                });
            });
        } catch (e) {
            throw e;
        }
    }

    const updateAvatar = async (avatar: UserAvatar) => {
        const ref = snapshot?.ref!
        try {
            await runTransaction(firestore, async (transaction) => {
                transaction.update(ref, { publicProfile: PublicProfileConverter.toData({ ...profile?.publicProfile, avatar }) });
            });
        } catch (e) {
            throw e;
        }
    }

    const updateDisplayName = async (displayName: string) => {
        try {
            await updateProfileWithData({ publicProfile: PublicProfileConverter.toData({ ...profile?.publicProfile, displayName }) })
        } catch (e) {
            throw e;
        }
    }
    const updateAcceptFuelMailing = async (value: boolean) => {
        const ref = snapshot?.ref!
        try {
            await runTransaction(firestore, async (transaction) => {
                transaction.update(ref, { acceptFuelMailing: value });
            });
        } catch (e) {
            throw e;
        }
    }
    const updateAcceptWinnerMailing = async (value: boolean) => {
        const ref = snapshot?.ref!
        try {
            await runTransaction(firestore, async (transaction) => {
                transaction.update(ref, { acceptWinnerMailing: value });
            });
        } catch (e) {
            throw e;
        }
    }

    const updateProfile = async (newProfile: Profile) => {
        try {
            await updateProfileWithData({
                publicProfile: PublicProfileConverter.toData(newProfile.publicProfile),
                acceptFuelMailing: newProfile.acceptFuelMailing,
                acceptWinnerMailing: newProfile.acceptWinnerMailing,
                isCompleted: !!newProfile.isCompleted
            })
        } catch (e) {
            throw e;
        }
    }

    const printDisplayName = (publicProfile: PublicProfile): string => {
        if (publicProfile?.isDeleted) {
            return t('Deleted user name')
        }
        return publicProfile.displayName;
    }

    return (
        <ProfileContext.Provider value={{
            profile,
            clearUnseenRewards,
            updateAvatar,
            updateDisplayName,
            printDisplayName,
            updateProfile,
            updateAcceptFuelMailing,
            updateAcceptWinnerMailing,
        }}>
            {loading ? null : children}
        </ProfileContext.Provider>
    )
}
