export enum UserAvatar {
  AVATAR_01 = "AVATAR_01",
  AVATAR_02 = "AVATAR_02",
  AVATAR_03 = "AVATAR_03",
  AVATAR_04 = "AVATAR_04",
  AVATAR_05 = "AVATAR_05",
  AVATAR_06 = "AVATAR_06",
  AVATAR_07 = "AVATAR_07",
  AVATAR_08 = "AVATAR_08",
  AVATAR_09 = "AVATAR_09",
  AVATAR_10 = "AVATAR_10",
  AVATAR_11 = "AVATAR_11",
  AVATAR_12 = "AVATAR_12",
  AVATAR_13 = "AVATAR_13",
  AVATAR_14 = "AVATAR_14",
  AVATAR_15 = "AVATAR_15",
  AVATAR_16 = "AVATAR_16",
  AVATAR_17 = "AVATAR_17",
  AVATAR_18 = "AVATAR_18",
  AVATAR_19 = "AVATAR_19",
  AVATAR_20 = "AVATAR_20",
  AVATAR_21 = "AVATAR_21",
  AVATAR_22 = "AVATAR_22",
  AVATAR_23 = "AVATAR_23",
  AVATAR_24 = "AVATAR_24",
  AVATAR_25 = "AVATAR_25",
  AVATAR_26 = "AVATAR_26",
  AVATAR_27 = "AVATAR_27",
  AVATAR_28 = "AVATAR_28",
  AVATAR_29 = "AVATAR_29",
  AVATAR_30 = "AVATAR_30",
  AVATAR_31 = "AVATAR_31",
  AVATAR_32 = "AVATAR_32",
  AVATAR_33 = "AVATAR_33",
  AVATAR_34 = "AVATAR_34",
  AVATAR_35 = "AVATAR_35",
  AVATAR_36 = "AVATAR_36",
  AVATAR_37 = "AVATAR_37",
  AVATAR_38 = "AVATAR_38",
  AVATAR_39 = "AVATAR_39",
  AVATAR_40 = "AVATAR_40",
  AVATAR_41 = "AVATAR_41",
  AVATAR_42 = "AVATAR_42",
  AVATAR_43 = "AVATAR_43",
  AVATAR_44 = "AVATAR_44",
  AVATAR_45 = "AVATAR_45",
  AVATAR_46 = "AVATAR_46",
  AVATAR_47 = "AVATAR_47",
  AVATAR_48 = "AVATAR_48",
  AVATAR_49 = "AVATAR_49",
  AVATAR_50 = "AVATAR_50",
}
export const UserAvatarValues = Object.values(UserAvatar)

