import AddIcon from '@mui/icons-material/Add';
import {
    Avatar,
    Button, Link,
    List,
    ListItem, Stack,
    Typography
} from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useAuth} from '../../context/AuthContext';
import {FuelType, FuelTypeValues} from '../../utils/model/FuelType';
import {getFuelFromStation, Station} from '../../utils/model/Station';
import AddFuelDialog from './components/AddFuelDialog';
import LoginDialog from './components/LoginDialog';
import GasPrice from './GasPrice';
import {useParams} from "react-router-dom";
import {BrandIcon} from '../../utils/assets/BrandIcon';
import {Location} from "../../utils/model/Location";
import {UserActionRequestDTO} from "../../utils/model/UserAction";
import {UserActionType} from "../../utils/model/UserActionType";
import {deleteStationAdmin, sendReport, sendUserAction} from "../../utils/Api";
import ErrorResponseDialog from "./components/ErrorResponseDialog";
import {Reward} from "../../utils/model/Reward";
import Feedback from "../navigation/Feedback";
import {Fuel} from '../../utils/model/Fuel';
import NoGasPrice from './NoGasPrice';
import DialogLoader from "../common/DialogLoader";
import {ReportDTO} from "../../utils/model/Report";
import WarningIcon from '@mui/icons-material/Warning';
import MakeReportDialog from "./components/MakeReportDialog";
import ReportDialog from "./components/ReportDialog";
import {UserType} from "../../utils/model/UserType";
import {useProfile} from "../../context/ProfileContext";
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import ValidateDeleteStationAdminDialog from "../admin/ValidateDeleteStationAdminDialog";
import {useRouter} from "../../context/RouterContext";
import DirectionsIcon from '@mui/icons-material/Directions';

export const StationComponent = ({station, drawerBleeding}: { station: Station, drawerBleeding: number }) => {

    const {fuelId} = useParams();
    const {t} = useTranslation();
    const {user} = useAuth();
    const [loading, setLoading] = useState(false);
    const [reward, setReward] = useState<Reward | null>(null);
    const [resultResponse, setResultResponse] = useState(false);
    const [error, setError] = useState<TemplateStringsArray | undefined>();
    const [openMakeReport, setOpenMakeReport] = useState(false);
    const [reportSuccess, setReportSuccess] = useState(false);
    const {profile} = useProfile();
    const {navigate} = useRouter();

    const [login, setLogin] = useState(false);
    const [addFuel, setAddFuel] = useState(false);

    const [openValidateAdmin, setOpenValidateAdmin] = useState(false);

    const onAddFuel = (_e: React.MouseEvent<HTMLElement>) => {
        setAddFuel(!addFuel)
    }

    const onLogin = (_e: React.MouseEvent<HTMLElement>) => {
        setLogin(!login)
    }

    const googleMapsLink = `https://www.google.com/maps/dir/?api=1&travelmode=driving&destination=${station.location.lat},${station.location.lng}`

    const fuelType = fuelId! as FuelType
    const fuelTypeListOrdered: FuelType[] = [fuelType, ...FuelTypeValues.filter(item => item !== fuelId)];
    const fuelsToDisplay = fuelTypeListOrdered
        .filter(type => type !== fuelType)
        .map(type => getFuelFromStation(station, type))
        .filter(Boolean) as Fuel[]
    const selectedFuel = getFuelFromStation(station, fuelType)
    const imageSize = 64

    const onAddFuelDialog = (e: React.MouseEvent<HTMLElement>, coords: { latitude: number, longitude: number }, price: any, fuelType: any) => {
        setAddFuel(!addFuel);
        setLoading(true);
        if (!fuelType || !price) {
            return;
        }

        const location: Location = {
            lat: coords?.latitude!,
            lng: coords?.longitude!
        }

        const userActionRequestDTO: UserActionRequestDTO = {
            stationId: station.id,
            actionType: UserActionType.NEW_FUEL_TYPE,
            fuelType: fuelType,
            price: price,
            location: location
        }

        createUserAction(userActionRequestDTO);
    }

    const createUserAction = async (userActionRequestDTO: UserActionRequestDTO) => {
        setLoading(true);
        try {
            const result = await sendUserAction(userActionRequestDTO);
            const reward = result.data.reward as Reward;
            setReward(reward);
            setResultResponse(true);
        } catch (error: any) {
            setError(error.message);
        }
        setLoading(false);
    }

    const attributes = {
        station
    }

    const onDeleteStation = (_e: React.MouseEvent<HTMLElement>) => {
        setOpenValidateAdmin(!openValidateAdmin);
    }

    const onMakeReport = (_e: React.MouseEvent<HTMLElement>) => {
        setOpenMakeReport(!openMakeReport);
    }

    const onMakeReportDialog = async (_e: React.MouseEvent<HTMLElement>, fuelType: any, reportType: any, idStation: string) => {
        setOpenMakeReport(!openMakeReport);
        setLoading(true);

        if (!reportType || !idStation) {
            return;
        }

        const reportDTO: ReportDTO = {
            reportType: reportType,
            stationId: idStation
        }

        if (fuelType) {
            reportDTO.fuelType = fuelType;
        }

        try {
            await sendReport(reportDTO);
            setReportSuccess(true);
        } catch (error: any) {
            setError(error.message);
        }
        setLoading(false);
    }

    const onCloseStationConfirmation = async (e: React.MouseEvent<HTMLElement>, stationId: string) => {
        setOpenValidateAdmin(false);
        setLoading(true);
        try {
            await deleteStationAdmin(stationId);
        } catch (error: any) {
            setError(error.message);
        }
        setLoading(false);
    }

    return (
        <>
            {openValidateAdmin &&
                <ValidateDeleteStationAdminDialog
                    onValidate={onCloseStationConfirmation}
                    onClose={() => setOpenValidateAdmin(!openValidateAdmin)}
                    {...attributes}/>
            }
            {
                reportSuccess &&
                <ReportDialog onClose={() => setReportSuccess(false)}></ReportDialog>
            }
            {
                loading &&
                <DialogLoader loading={loading} onClose={() => setLoading(!loading)}/>
            }
            {
                error &&
                <ErrorResponseDialog errorCode={error} onClose={() => setError(undefined)}/>
            }
            {
                resultResponse && reward &&
                <Feedback reward={reward} onClose={() => setResultResponse(false)}/>
            }
            {
                login && <LoginDialog onClose={() => setLogin(!login)}/>
            }
            {
                addFuel && <AddFuelDialog
                    onNew={onAddFuelDialog}
                    onClose={() =>
                        setAddFuel(!addFuel)
                    }
                    station={station}/>
            }
            {
                openMakeReport &&
                <MakeReportDialog
                    onNewReport={onMakeReportDialog}
                    onClose={() => setOpenMakeReport(!openMakeReport)} {...attributes} />
            }

            <Grid
                pt={2}
                pb={1}
                px={3}
                container
                flexDirection={'column'} flexWrap={'nowrap'} justifyContent={'stretch'}
                maxHeight={`calc(70vh - ${drawerBleeding}px)`} id={"bottomDrawer"}>
                <Grid container flexShrink={1} flexWrap={'nowrap'} gap={1}>
                    <Grid flexGrow={1} pt={1}>
                        <Grid container justifyContent={'space-between'} gap={1}>
                            <Grid>
                                <Typography variant={"h3"}>
                                    {station.displayName}
                                </Typography>

                                <Stack alignItems={{sm: 'center'}} sx={{flexDirection: {xs: 'column', sm: 'row'}, gap: 1}}>
                                    <Typography variant={"body1"} fontSize={16}>
                                        {station.formattedAddress}
                                    </Typography>
                                    <Link href={googleMapsLink}>
                                        <Button
                                            variant="outlined"
                                            color="secondary"
                                            sx={{minWidth: 0, fontSize: 14}}>
                                            <DirectionsIcon />
                                            <Typography fontSize={12} sx={{pl: 1}}>
                                                {t("Button_Go_To")}
                                            </Typography>
                                        </Button>
                                    </Link>
                                </Stack>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid flexShrink={0} justifySelf={'flex-end'}>
                        <Avatar src={BrandIcon.forStation(station)} sx={{
                            imgProps: {width: imageSize, height: imageSize},
                            width: imageSize, height: imageSize,
                        }}/>
                    </Grid>
                </Grid>
                <Grid container flexGrow={1} minHeight={0} pt={1} flexDirection={'column'} flexWrap={'nowrap'}
                      justifyContent={'stretch'}>
                    <List sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        flexShrink: 1,
                        gap: '0.5rem',
                        overflowY: 'auto'
                    }}>
                        <>
                            {selectedFuel ?
                                (<GasPrice key={`fuel-${fuelType}`} fuel={selectedFuel!} station={station}/>)
                                :
                                (<NoGasPrice fuelType={fuelType}/>)
                            }
                            {fuelsToDisplay.length > 0 &&
                                <Typography variant={"body1Bold"} pt={1.5} pl={1} fontWeight='bold' fontSize={14}>
                                    {t('GasPrice_OtherFuelsInStation')}
                                </Typography>
                            }

                            {station && fuelsToDisplay.map((fuel, index) => {
                                return <GasPrice key={`fuel-${index}`} fuel={fuel} station={station}/>
                            })
                            }
                            <ListItem key={'add-fuel-type'} sx={{p: 0}}>
                                <Stack sx={{flexDirection: {xs: 'column', sm: 'row'}, gap: 1, width: "100%"}}>
                                    <Button variant='outlined' color="secondary"
                                            onClick={user ? onAddFuel : onLogin}>
                                        <AddIcon></AddIcon>
                                        {t('Add a new fuel type')}
                                    </Button>
                                    <Button
                                        variant="outlined"
                                        color="error"
                                        onClick={user ? onMakeReport : onLogin}>
                                        <WarningIcon/>
                                        <Typography fontSize={12} sx={{pl: 1}}>
                                            {t("Button_Report_Title")}
                                        </Typography>
                                    </Button>
                                    {profile?.type === UserType.ADMIN &&
                                        <>
                                            <Button
                                                color="error"
                                                sx={{
                                                    borderStyle: "dashed",
                                                    borderWidth: "1px"
                                                }}
                                                onClick={user ? onDeleteStation : onLogin}>
                                                <DeleteIcon />
                                                <Typography fontSize={12} sx={{pl: 1}}>
                                                    {t("Button_Admin_Delete_Title")}
                                                </Typography>
                                            </Button>
                                            <Button
                                                color="warning"
                                                sx={{
                                                    borderStyle: "dashed",
                                                    borderWidth: "1px"
                                                }}
                                                onClick={() => navigate("/admin/station/" + station.id)}>
                                                <EditIcon />
                                                <Typography fontSize={12} sx={{pl: 1}}>
                                                    {t("Button_Admin_Edit_Title")}
                                                </Typography>
                                            </Button>
                                        </>
                                    }
                                </Stack>
                            </ListItem>
                        </>
                    </List>
                </Grid>
            </Grid>
        </>
    )
}
