import {useNavigate, useParams} from "react-router-dom";
import React, {useEffect, useMemo, useState} from "react";
import {AppBar, CircularProgress, Toolbar, Typography} from "@mui/material";
import {getUserActionsAdmin, sendOperationUserAdmin} from "../../utils/Api";
import ErrorResponseDialog from "../station/components/ErrorResponseDialog";
import {Stack} from '@mui/system';
import Button from "@mui/material/Button";
import {doc} from "firebase/firestore";
import {firestore} from "../../config/firebase";
import {useDocument} from "react-firebase-hooks/firestore";
import ProfileConverter from "../../utils/converters/ProfileConverter";
import {Profile} from "../../utils/model/Profile";
import {fontFamilyMuseo300, fontFamilyMuseo700, Theme} from "../../mui/Theme";
import {UserActionAdminDTO} from "../../../functions/src/utils/model/UserActionAdminDTO";
import moment from "moment";
import OperationAdminDialog from "./OperationAdminDialog";
import GoogleMapReact from 'google-map-react';
import {AdminUserMarker} from "./AdminUserMarker";
import {MAP_OPTIONS_DEFAULT} from "./utils/map";
import {UserType} from "../../utils/model/UserType";
import AdminBanReasonDialog from "./AdminBanReasonDialog";
import DialogLoader from "../common/DialogLoader";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import {useProfile} from "../../context/ProfileContext";

export default function AdminUser() {
    const {profile} = useProfile();
    const navigate = useNavigate();
    const {userId} = useParams();
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState<TemplateStringsArray | undefined>();
    const [stations, setStations] = useState<UserActionAdminDTO[]>([]);
    const [hours, setHours] = useState(24);
    moment.locale("en");

    const [profileSnapshot, loadingProfile] = useDocument(doc(firestore, 'profiles', userId ?? "undefined").withConverter(ProfileConverter));
    const user = profileSnapshot ? profileSnapshot.data() as Profile : undefined;
    const [resultResponse, setResultResponse] = useState<string | undefined>(undefined);

    const [openBanDialog, setOpenBanDialog] = useState(false);

    const memoizedGetUserActionsAdmin = useMemo(() => {
        return async (userId: string) => {
            setIsLoading(true);
            try {
                const result = await getUserActionsAdmin(userId, 24);
                const stations = result as UserActionAdminDTO[];
                setStations(stations);
            } catch (error: any) {
                setError(error.message);
            }
            setIsLoading(false);
        }
    }, []);

    const onGetStationsByUserId = async (e: React.MouseEvent<HTMLElement>, hours: number) => {
        setIsLoading(true);
        setHours(hours);
        if (userId) {
            try {
                const result = await getUserActionsAdmin(userId, hours);
                const stations = result as UserActionAdminDTO[];
                setStations(stations);
            } catch (error: any) {
                setError(error.message);
            }
        }
        setIsLoading(false);
    }

    const onSendOperationAdmin = async (e: React.MouseEvent<HTMLElement>, operationId: number, reason?: string) => {
        setOpenBanDialog(false);
        setIsLoading(true);
        if (userId) {
            try {
                const result = await sendOperationUserAdmin(userId, operationId, reason);
                setResultResponse(result.data.message);
            } catch (error: any) {
                setError(error.message);
            }
        }
        setIsLoading(false);
    }

    const googleMapReactOptions = {
        bootstrapURLKeys: {
            key: process.env.REACT_APP_GOOGLE_API!,
        },
        defaultCenter: stations.length > 0 ? {
            lat: stations[0].stationLocation.lat,
            lng: stations[0].stationLocation.lng
        } : {lat: 46.798543289186775, lng: 8.231108154514448},
        defaultZoom: stations.length <= 0 ? 8 : 12,
        options: MAP_OPTIONS_DEFAULT,
        yesIWantToUseGoogleMapApiInternals: true,
    }

    useEffect(() => {
        if (userId) {
            memoizedGetUserActionsAdmin(userId);
        }
    }, [userId, memoizedGetUserActionsAdmin]);

    return (
        <>
            {isLoading &&
                <DialogLoader loading={isLoading} onClose={() => setIsLoading(!isLoading)}/>
            }
            {error &&
                <ErrorResponseDialog errorCode={error} onClose={() => {
                    setError(undefined);
                }}/>
            }
            {resultResponse &&
                <OperationAdminDialog message={resultResponse} onClose={() => setResultResponse(undefined)}/>
            }
            {openBanDialog &&
                <AdminBanReasonDialog
                    onValidate={onSendOperationAdmin}
                    onClose={() => setOpenBanDialog(!openBanDialog)} />
            }
            <Stack direction='column' height={'100%'}>
                <AppBar position="static">
                    <Toolbar className="topAdminBar">
                        <Stack direction='column' width={'100%'} justifyContent={'space-around'} gap={1} padding={1}>
                            <Stack width={'100%'} margin={'auto'}
                                   direction={'row'} justifyContent={'space-between'} flexWrap={'nowrap'}
                                   alignContent={'center'}
                                   alignItems={'center'}>
                                {loadingProfile &&
                                    <CircularProgress
                                        color="secondary"
                                        size={64}
                                        disableShrink
                                        thickness={4}
                                        sx={{
                                            margin: "auto",
                                        }}
                                    />
                                }
                                {!loadingProfile && user &&
                                    <Stack width={'100%'} margin={'auto'}
                                           direction={'row'} justifyContent={'space-between'} flexWrap={'nowrap'}
                                           alignContent={'center'}
                                           alignItems={'center'} sx={{
                                        borderRadius: '5px',
                                        p: 1,
                                        border: 1,
                                        borderColor: "#005aa0"
                                    }}>
                                        <Stack direction='column'>
                                            <Typography fontSize={14} fontFamily={fontFamilyMuseo700}>
                                                Display Name: {user.publicProfile.displayName}
                                            </Typography>
                                            <Typography fontSize={14} fontFamily={fontFamilyMuseo300}>
                                                TCS number: {user.tcsRef}
                                            </Typography>
                                        </Stack>
                                        <Stack direction='column'>
                                            <Typography fontSize={14} fontFamily={fontFamilyMuseo300}>
                                                Points: {user.points}
                                            </Typography>
                                            <Typography fontSize={14} fontFamily={fontFamilyMuseo300}>
                                                Tokens: {user.lotteryTickets}
                                            </Typography>
                                        </Stack>
                                    </Stack>
                                }
                            </Stack>

                            <Stack width={'100%'} margin={'auto'}
                                   direction={'row'} justifyContent={'space-between'} flexWrap={'nowrap'}
                                   alignContent={'center'}
                                   alignItems={'center'}>
                                <Stack direction={'row'} alignItems={'center'} gap={1}>
                                    <Button
                                        sx={{textTransform: "none"}}
                                        variant={hours === 12 ? 'contained' : 'outlined'}
                                        color={'secondary'}
                                        onClick={(e) => onGetStationsByUserId(e, 12)}>
                                        12h
                                    </Button>
                                    <Button
                                        sx={{textTransform: "none"}}
                                        variant={hours === 24 ? 'contained' : 'outlined'}
                                        color={'secondary'}
                                        onClick={(e) => onGetStationsByUserId(e, 24)}>
                                        24h
                                    </Button>
                                    <Button
                                        sx={{textTransform: "none"}}
                                        variant={hours === 48 ? 'contained' : 'outlined'}
                                        color={'secondary'}
                                        onClick={(e) => onGetStationsByUserId(e, 48)}>
                                        48h
                                    </Button>
                                    <Button
                                        sx={{textTransform: "none"}}
                                        variant={hours === 210 ? 'contained' : 'outlined'}
                                        color={'secondary'}
                                        onClick={(e) => onGetStationsByUserId(e, 210)}>
                                        7d
                                    </Button>
                                    <Button
                                        sx={{textTransform: "none"}}
                                        variant={hours === 720 ? 'contained' : 'outlined'}
                                        color={'secondary'}
                                        onClick={(e) => onGetStationsByUserId(e, 720)}>
                                        30d
                                    </Button>
                                    <Button
                                        sx={{textTransform: "none"}}
                                        variant={hours === 1440 ? 'contained' : 'outlined'}
                                        color={'secondary'}
                                        onClick={(e) => onGetStationsByUserId(e, 1440)}>
                                        60d
                                    </Button>
                                </Stack>
                                <Stack direction={'row'} justifyItems={'center'} alignItems={'center'} gap={1}>
                                    {!loadingProfile && user &&
                                        <>
                                            {profile?.type === UserType.ADMIN &&
                                                <>
                                                    <Button
                                                        variant={'outlined'}
                                                        color={'warning'}
                                                        onClick={(e) => onSendOperationAdmin(e, 4)}>
                                                        Remove points
                                                    </Button>
                                                    {user?.type === UserType.SUSPECT ?
                                                        <Button
                                                            variant={'outlined'}
                                                            color={'error'}
                                                            onClick={(e) => onSendOperationAdmin(e, 3)}>
                                                            Unban
                                                        </Button> :
                                                        <Button
                                                            variant={'outlined'}
                                                            color={'error'}
                                                            onClick={() => setOpenBanDialog(true)}>
                                                            Ban
                                                        </Button>
                                                    }
                                                </>
                                            }
                                        </>
                                    }
                                </Stack>
                            </Stack>
                            {user?.type === UserType.SUSPECT &&
                                <Stack direction={'column'} gap={1}>
                                    <Typography fontWeight={'bold'} fontSize={14} color={Theme.palette.error.main}>
                                        BAN: {user.banReason ? user.banReason : 'UNDEFINED'}
                                    </Typography>
                                    <Typography fontWeight={'bold'} fontSize={14} color={Theme.palette.error.main}>
                                        Date: {user.banDate?.toLocaleString()}
                                    </Typography>
                                </Stack>
                            }
                        </Stack>
                    </Toolbar>
                </AppBar>
                <div className='google-map active'>
                    <div className='google-map-inner'>
                        <GoogleMapReact {...googleMapReactOptions}>
                            {stations.map((station: UserActionAdminDTO) => {
                                return <AdminUserMarker key={station.stationId}
                                                        lat={station.stationLocation.lat}
                                                        lng={station.stationLocation.lng}
                                                        station={station}
                                ></AdminUserMarker>
                            })}
                        </GoogleMapReact>
                    </div>
                </div>
                <Stack sx={{
                    flexDirection: "row",
                    margin: 1
                }}>
                    <Button
                        variant="contained"
                        type="button"
                        onClick={() => navigate(-1)}
                    >
                        <ArrowBackIcon />
                    </Button>
                </Stack>
            </Stack>
        </>
    )
}