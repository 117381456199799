import { Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import SimplePage from "../components/SimplePage";

const DataPrivacy = () => {
  const { t } = useTranslation();
  return (
    <SimplePage>
      <Typography variant="h1">
        {t('Data Privacy Title')}
      </Typography>
      <Typography variant="body1">
        {t('Data Privacy Body')}
      </Typography>
    </SimplePage>
  )
}

export default DataPrivacy
