import { CircularProgress, Dialog, DialogContent, DialogContentText } from "@mui/material";
import { useTranslation } from "react-i18next";

interface Props {
    coords: any,
    onClose(...args: any): void
}

export default function GeolocationAcquireDialog({coords, onClose}: Props) {
    const {t} = useTranslation();

    return (
        <Dialog
            open={!coords}
            onClose={onClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            className='dialog'>
            <DialogContent>
                <CircularProgress style={{display: "block", margin: "auto"}} color={"secondary"}
                                  thickness={5}></CircularProgress>
                <DialogContentText id="alert-dialog-description">
                    {t('acquire_geolocation')}
                </DialogContentText>
            </DialogContent>
        </Dialog>
    )
}
