import {Paper, Stack} from "@mui/material";
import Grid from '@mui/material/Unstable_Grid2';
import {tcsBlueGray1, Theme} from "../../mui/Theme";
import SearchIcon from "@mui/icons-material/Search";
import React from "react";

export const AdminUserMarkerInfo = () => {
    const border = 2;

    return (
        <Paper className='marker-info' elevation={2}
               sx={{
                   background: tcsBlueGray1,
                   p: '1px',
                   border: border,
                   borderColor: Theme.palette.error.main
               }}>
            <Grid container gap={0.5} wrap='nowrap'>
                <Stack>
                    <Stack direction={'column'}>
                        <SearchIcon />
                    </Stack>
                </Stack>
            </Grid>
        </Paper>
    )
}
