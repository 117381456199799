import {
    Button
} from "@mui/material"
import { useTranslation } from "react-i18next"
import { PrizeWithUserEntries } from "../../../context/ContestContext"
import { ChevronRightOutlined } from "@mui/icons-material"
import { Profile } from "../../../utils/model/Profile"


interface Props {
    isContestActive: boolean;
    profile: Profile | null;
    prize: PrizeWithUserEntries;

    onAddTokensPress(): void;
    onLoginPress(): void;
    onShowWinnersPress(): void;
}

export const PrizeButtons = ({ isContestActive, profile, prize, onAddTokensPress, onLoginPress, onShowWinnersPress }: Props) => {
    const { t } = useTranslation();

    const isWinner = () => {
        return prize.winnerIds.includes(profile?.id ?? "");
    }

    return (
        <>
            {isContestActive && profile && profile.lotteryTickets > 0 && (
                <Button color="secondary" variant="contained"
                    endIcon={
                        <ChevronRightOutlined />
                    }
                    size={'small'}
                    sx={{ pr: 0.5, pl: 1.5, py: 1, lineHeight: 1.2, textAlign: "center", fontWeight: 'bold' }}
                    onClick={onAddTokensPress}>
                    {t("Add chance to win")}
                </Button>
            )}
            {isContestActive && profile && profile.lotteryTickets <= 0 && (
                <Button
                    color="secondary" variant="outlined"
                    sx={{ pr: 0.5, pl: 1.5, py: 1.5, lineHeight: 1.2 }}
                    size="large"
                    disabled>
                    {t("Not enough tokens")}
                </Button>
            )}
            {isContestActive && !profile && (
                <Button color="secondary" variant="contained"
                    sx={{ pr: 0.5, pl: 1.5, py: 1.5, lineHeight: 1.2, textAlign: "left" }}
                    onClick={onLoginPress}>
                    {t("Register to participate")}
                </Button>
            )}

            {
                (!isContestActive && prize.winnerIds && prize.winnerIds.length) &&
                    <>
                        {
                            isWinner() ?
                                <Button color="success" variant="contained"
                                        endIcon={
                                            <ChevronRightOutlined />
                                        }
                                        size={'small'}
                                        sx={{ pr: 0.5, pl: 1.5, py: 1, lineHeight: 1.2, textAlign: "center", fontWeight: 'bold' }}
                                        onClick={onShowWinnersPress}>
                                    {t("Button_Prize_Congratulation")}
                                </Button> :
                                <Button color="primary" variant="contained"
                                        endIcon={
                                            <ChevronRightOutlined />
                                        }
                                        size={'small'}
                                        sx={{ pr: 0.5, pl: 1.5, py: 1, lineHeight: 1.2, textAlign: "center", fontWeight: 'bold' }}
                                        onClick={onShowWinnersPress}>
                                    {t("Button_Prize_List_Winners")}
                                </Button>
                        }
                    </>

            }
        </>
    )
}