import Profile from '../components/profile/Profile'
import SimplePage from '../components/SimplePage'

const ProfilePage = () => {
  return (
    <SimplePage>
      <Profile />
    </SimplePage>
  )
}

export default ProfilePage
