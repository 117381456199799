import {Box, Stack, Typography} from "@mui/material";
import {useTranslation} from "react-i18next";

export default function HowTodo() {
    const {t} = useTranslation();

    return (
        <Box className='how-it-works'>
            <Typography variant='h3' sx={{mt: 4, mb: 1}}>Instructions</Typography>

            <Stack direction='column' flexWrap={'wrap'} className='steps'>
                <Stack direction='column' sx={{flex: '1 1 50%', minWidth: '300px'}}>
                    <Typography>
                        {t("How_WonPrizes_Text1")}
                    </Typography>
                    <Typography>
                        {t("How_WonPrizes_Text2")}
                    </Typography>
                </Stack>
            </Stack>
        </Box>
    )
}