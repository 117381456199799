import {Avatar, List, ListItem, Stack} from "@mui/material";
import {WonPrize} from "../../../utils/model/WonPrize";
import {AvatarIcon} from "../../../utils/assets/AvatarIcon";
import {PublicProfile} from "../../../utils/model/PublicProfile";
import {useProfile} from "../../../context/ProfileContext";
import {PrizeWithUserEntries} from "../../../context/ContestContext";
import {useTranslation} from "react-i18next";
import {UserType} from "../../../utils/model/UserType";
import {useRouter} from "../../../context/RouterContext";

interface Props {
    wonPrizes: WonPrize[],
    prize: PrizeWithUserEntries
}

export default function WinnersList({wonPrizes, prize}: Props) {
    const {profile, printDisplayName} = useProfile();
    const {t} = useTranslation();
    const {navigate} = useRouter();

    const winnerProfiles: string[] = [];

    const getNumberOfWin = (userId: string) => {
        return prize.winnerIds.filter(item => item === userId).length;
    }

    return (
        <List className="history-list">
            {
                wonPrizes.map((wonPrize: WonPrize, index: number) => {
                    const publicProfile = wonPrize.winnerProfile as PublicProfile;
                    if (publicProfile && !winnerProfiles.includes(publicProfile.userId)) {
                        winnerProfiles.push(publicProfile.userId);
                        return <ListItem
                            key={`winnerProfile-${index}`}
                            className={"history-list-item winner-list-item " + (publicProfile.userId === profile?.publicProfile.userId ? "winner-item" : "")}
                            {...profile?.type === UserType.ADMIN && {
                                onClick: () => {navigate("/admin/user/" + publicProfile.userId)}
                            }}>
                            <Stack direction={'column'}>
                                <Stack direction={'row'} gap={1}>
                                    <div className="winner-avatar">
                                        <Avatar sizes="small" alt={printDisplayName(publicProfile)}
                                                src={AvatarIcon.forAvatar(publicProfile?.avatar)}/>
                                    </div>
                                    <div className="winner-display-name">
                                        {printDisplayName(publicProfile)}
                                    </div>
                                </Stack>
                                <div className="winner-text">
                                    {t("Dialog_Winner_Item_Text", {
                                        number_won_prize: getNumberOfWin(publicProfile.userId),
                                        number_tokens: wonPrize.winnerUsedTokens
                                    })}
                                </div>
                            </Stack>
                        </ListItem>
                    }
                    return null;
                })
            }
        </List>
    )
}