import {Navigate, Outlet, Route, Routes, useSearchParams} from 'react-router-dom'
import {IsWebviewStorage} from '../components/maps/IsWebviewStorage'
import ProtectedRoute from '../components/ProtectedRoute'
import {ContestContextProvider} from '../context/ContestContext'
import {useRouter} from '../context/RouterContext'
import ContestPage from '../pages/Contest'
import DataPrivacy from '../pages/DataPrivacy'
import LegalMentions from '../pages/LegalMentions'
import Login from '../pages/Login'
import Map from '../pages/Map'
import Profile from '../pages/Profile'
import ProfileFinalize from '../pages/ProfileFinalize'
import ProfileThanks from '../pages/ProfileThanks'
import Restricted from '../pages/Restricted'
import {FuelType} from '../utils/model/FuelType'
import WonPrizePage from "../pages/WonPrizePage";
import AdminRoute from "../components/AdminRoute";
import AdminUser from "../components/admin/AdminUser";
import AdminStationsRemoved from "../components/admin/AdminStationsRemoved";
import AdminBannedUsers from "../components/admin/AdminBannedUsers";
import AdminUpdateStation from "../components/admin/AdminUpdateStation";
import AdminDashboard from "../components/admin/AdminDashboard";
import AdminFindUser from "../components/admin/AdminFindUser";
import AdminCreateStation from "../components/admin/AdminCreateStation";
import AdminCreateContest from "../components/admin/AdminCreateContest";
import AdminActiveContests from "../components/admin/AdminActiveContests";
import AdminFindStation from "../components/admin/AdminFindStation";

const Router = () => {
    const {basePath} = useRouter();
    const myFuel: FuelType = localStorage.getItem(`my-fuel`) as FuelType || FuelType.SP95;

    const [searchParams] = useSearchParams();
    const isWebviewString = searchParams.get("webview")
    if (isWebviewString === "false") {
        IsWebviewStorage.saveIsWebview(false)
    } else if (isWebviewString === "true") {
        IsWebviewStorage.saveIsWebview(true)
    }

    return (
        <Routes>
            <Route path={'/admin'} element={<><Outlet/></>}>
                <Route path={''} element={<AdminRoute><AdminDashboard/></AdminRoute>}/>
                <Route path={'station/:stationId'} element={<AdminRoute><AdminUpdateStation/></AdminRoute>}/>
                <Route path={'station/create'} element={<AdminRoute><AdminCreateStation/></AdminRoute>}/>
                <Route path={'station/removed'} element={<AdminRoute><AdminStationsRemoved/></AdminRoute>}/>
                <Route path={'station/find'} element={<AdminRoute><AdminFindStation/></AdminRoute>}/>

                <Route path={'user/:userId'} element={<AdminRoute><AdminUser/></AdminRoute>}/>
                <Route path={'user/banned'} element={<AdminRoute><AdminBannedUsers/></AdminRoute>}/>
                <Route path={'user/find'} element={<AdminRoute><AdminFindUser/></AdminRoute>}/>

                <Route path={'contest/create'} element={<AdminRoute><AdminCreateContest/></AdminRoute>}/>
                <Route path={'contest/active'} element={<AdminRoute><AdminActiveContests/></AdminRoute>}/>
                <Route path={'contest/update/:contestId'} element={<AdminRoute><AdminCreateContest/></AdminRoute>}/>
            </Route>
            <Route path={'/:lang'} element={<><Outlet/><Map/></>}>
                <Route path={'station/:stationId/:fuelId'} element={<></>}/>
                <Route path={'map'} element={<Navigate to={`${basePath}/map/${myFuel}`}/>}/>
                <Route path={'map/:fuelId'} element={<></>}/>
                <Route path={'legal-mentions'} element={<LegalMentions/>}/>
                <Route path={'data-privacy'} element={<DataPrivacy/>}/>
                <Route path={'login'} element={<Login/>}/>
                <Route path={'profile'} element={
                    <ProtectedRoute><ContestContextProvider><Profile/></ContestContextProvider></ProtectedRoute>}/>
                <Route path={'profile-finalize'} element={<ProtectedRoute><ProfileFinalize/></ProtectedRoute>}/>
                <Route path={'profile-thanks'} element={<ProtectedRoute><ProfileThanks/></ProtectedRoute>}/>
                <Route path={'restricted'} element={<Restricted/>}/>
                <Route path={''} element={<Navigate to={`${basePath}/map/${myFuel}`}/>}/>
                <Route path={'contest'} element={<ContestContextProvider><ContestPage/></ContestContextProvider>}/>
                <Route path={'won-prizes'} element={
                    <ProtectedRoute><ContestContextProvider><WonPrizePage/></ContestContextProvider></ProtectedRoute>}/>
            </Route>
            <Route path="*" element={<Navigate to={`${basePath}/`} replace/>}/>
        </Routes>
    )
}

export default Router
