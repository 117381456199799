import { Button, Dialog, DialogActions, DialogContent, DialogContentText } from "@mui/material";
import { useTranslation } from "react-i18next";

interface Props {
    isGeolocationAvailable: boolean,
    onClose(...args: any): void
}

export default function GeolocationAvailableDialog({isGeolocationAvailable, onClose}: Props) {
    const {t} = useTranslation();

    return (
        <Dialog
            open={!isGeolocationAvailable}
            onClose={onClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            className='dialog'>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    {t('not_support_geolocation')}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button variant="contained" color="secondary" onClick={onClose}>{t('Cancel')}</Button>
            </DialogActions>
        </Dialog>
    )
}
