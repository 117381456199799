import { FuelType } from '../../utils/model/FuelType';
import { Station } from '../../utils/model/Station';
import {GMapInfo} from './GMapInfo';

interface Props {
    lat: number;
    lng: number;
    station:Station;
    fuelType:FuelType;
    onStationClick: CallableFunction;
    isCheapest:boolean;
    active:boolean;
}

//Keep the same value between these variables and the ones in main.scss
const pinLegWidth = 18;
const pinLegHeight = 14;
const points = `0,0 ${pinLegWidth/2},${pinLegHeight} ${pinLegWidth},0`

const GMapMarker = ({station, fuelType, onStationClick, isCheapest, active}: Props) => {
    const onClick = (e: React.MouseEvent<HTMLElement>) => {
        e.stopPropagation();
        onStationClick(station);
    }

    return (
        <div onClick={onClick} className={"marker"}>
            <svg height={pinLegHeight} width={pinLegWidth}>
                <polygon points={points} />
            </svg>
            <div className="marker-inner">
                <GMapInfo 
                station={station}
                fuelType={fuelType}
                isCheapest={isCheapest}
                active={active}/>
            </div>
        </div>
    )
}

export default GMapMarker
