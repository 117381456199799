import {
    Dialog, DialogActions,
    DialogContent,
    DialogTitle,
    Stack, TextField, Typography
} from "@mui/material";
import Button from "@mui/material/Button";
import React, {useState} from "react";

interface Props {
    onValidate(e: React.MouseEvent<HTMLElement>, operationId: number, reason: string): void,
    onClose(): void
}

export default function AdminBanReasonDialog({onValidate, onClose}: Props) {
    const [banType, setBanType] = useState<string>("");
    const [banTypeError, setBanTypeError] = useState(false);

    const onValidateBan = (e: React.MouseEvent<HTMLElement>) => {
        if (banType === '') {
            setBanTypeError(true);
        } else {
            onValidate(e, 2, banType);
        }
    }

    return (
        <>
            <Dialog
                open={true}
                onClose={onClose}
                className='dialog'>
                <DialogTitle>
                    <Stack alignItems={'flex-start'}>
                        <Typography variant="h4" sx={{mb: 0.5}}>Reason of ban</Typography>
                    </Stack>
                </DialogTitle>
                <DialogContent>
                    <TextField
                        label="Text"
                        variant="standard"
                        multiline
                        rows={4}
                        error={banTypeError}
                        onChange={(e: any) =>
                            setBanType(e.target.value)}
                    />
                </DialogContent>
                <DialogActions sx={{mt: 1}}>
                    <Button variant="outlined" color="secondary" sx={{fontSize: 14}} onClick={onClose}>
                        Cancel
                    </Button>
                    {banType &&
                        <Button variant="contained" color="secondary" sx={{fontSize: 14}}
                                onClick={(e) => onValidateBan(e)}>
                            Confirm
                        </Button>
                    }
                </DialogActions>
            </Dialog>
        </>
    )
}