import {CircularProgress} from '@mui/material';
import {collection, query, where} from 'firebase/firestore';
import {useCollection} from 'react-firebase-hooks/firestore';
import {firestore} from '../../config/firebase';
import StationConverter from '../../utils/converters/StationConverter';
import {Point} from '../../utils/MapTypes';
import SimplePage from '../SimplePage';
import FuelSelector from './components/FuelSelector';
import {Station} from '../../utils/model/Station';
import {MessageBanner} from '../messagebanner/MessageBanner';
import {LOCALSTORAGE_CONTESTOVER_PREFIX_KEY, LOCALSTORAGE_CONTESTSTART_PREFIX_KEY} from './BannerStateStorage';
import {useTranslation} from 'react-i18next';
import MMap from "./MMap";

const GMapLoader = () => {
    const {t} = useTranslation();

    const [stationSnapshot, loading, error] = useCollection(
        query(
            collection(firestore, 'stations').withConverter(StationConverter),
            where('isDeleted', '==', false)
        ),
        {
            snapshotListenOptions: {includeMetadataChanges: false},
        }
    );

    const stations: Station[] = stationSnapshot ? stationSnapshot.docs.map(stationSnap => stationSnap.data()) : [];

    const points: Point[] = stations.map(station => {
        const point: Point = {
            properties: {
                station: station
            },
            geometry: {
                coordinates: [
                    station.location.lng,
                    station.location.lat
                ]
            }
        }
        return point;
    })

    return (
        <>
            {error && <SimplePage>{error.message}</SimplePage>}

            {loading &&
                <CircularProgress
                    color="secondary"
                    size={64}
                    disableShrink
                    thickness={4}
                    sx={{
                        margin: "auto",
                    }}
                />
            }
            {!loading && stations &&
                <>
                    <MessageBanner advertBanners={[
                        {
                            localStorageKey: LOCALSTORAGE_CONTESTOVER_PREFIX_KEY,
                            minDate: new Date("2024-10-01T00:00:01+01:00"),
                            maxDate: new Date("2024-10-07T00:00:01+01:00"),
                            title: t('ContestAdvert_ContestOver_Title'),
                            description: t('ContestAdvert_ContestOver_Description'),
                            severity: "success"
                        },
                        {
                            localStorageKey: LOCALSTORAGE_CONTESTSTART_PREFIX_KEY,
                            minDate: new Date("2024-10-01T00:00:01+01:00"),
                            maxDate: new Date("2024-10-26T00:00:01+01:00"),
                            title: t('ContestAdvert_ContestStart_Title'),
                            description: t('ContestAdvert_ContestStart_Description'),
                            severity: "info"
                        }
                    ]}
                    />

                    {/*<GMap stationPoints={points}/>*/}

                    <MMap stationPoints={points}/>

                    <FuelSelector/>
                </>
            }
        </>
    )
}

export default GMapLoader
