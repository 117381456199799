import {WithFieldValue, DocumentData, QueryDocumentSnapshot, SnapshotOptions} from "firebase/firestore";
import {Station} from "../model/Station";
import FuelCollectionConverter from "./FuelCollectionConverter";

const StationConverter = {
    toFirestore(Station: WithFieldValue<Station>): DocumentData {
        return {...Station};
    },
    fromFirestore(
        snapshot: QueryDocumentSnapshot,
        options: SnapshotOptions
    ): Station {
        const data: DocumentData = snapshot.data(options)!;
        return {
            ...data,
            id: snapshot.id,
            fuelCollection: FuelCollectionConverter.fromData(data.fuelCollection)
        } as Station
    }
}
export default StationConverter