import {Button, Dialog, DialogActions, DialogContent, DialogTitle} from "@mui/material";
import {useTranslation} from "react-i18next";
import {isDesktop, isMobile, isAndroid, isIOS, isChrome, isFirefox, isSafari, isEdge} from 'react-device-detect';
import { IsWebviewStorage } from "../../maps/IsWebviewStorage";

interface Props {
    isGeolocationEnabled: boolean,
    onClose(...args: any): void
}

export default function GeolocationEnabledDialog({isGeolocationEnabled, onClose}: Props) {
    const {t} = useTranslation();
    const isWebview = IsWebviewStorage.getIsWebview();

    const renderTitle = () => {
        if (isWebview) {
            return t('not_enabled_geolocation_app');
        } else {
            return t('not_enabled_geolocation');
        }
    }

    const renderContent = () => {
        const imgOptions = {className: 'geo-error-image'};
        if (isMobile) {
            if (isAndroid) {
                if (isWebview) {
                    return <ol>
                        <li>{t('geo_error_webview_android_1')}</li>
                        <li>
                            {t('geo_error_webview_android_2')}
                            <img {...imgOptions} src="/images/geo-error/webview/android1.jpg" alt="Android"/>
                        </li>

                        <li>
                            {t('geo_error_webview_android_3')}
                        </li>
                        <li>
                            {t('geo_error_webview_android_4')}
                            <img {...imgOptions} src="/images/geo-error/webview/android3.jpg" alt="Android"/>
                        </li>
                        <li>
                            {t('geo_error_webview_android_5')}
                        </li>
                        <li>
                            {t('geo_error_webview_android_6')}
                            <img {...imgOptions} src="/images/geo-error/webview/android5.jpg" alt="Android"/>
                        </li>
                    </ol>
                } else {
                    return <ol>
                        <li>{t('geo_error_android_1')}
                            <img {...imgOptions} src="/images/geo-error/android1.jpg" alt="Android"/></li>
                        <li>{t('geo_error_android_2')}
                            <img {...imgOptions} src="/images/geo-error/android2.jpg" alt="Android"/></li>
                        <li>{t('geo_error_android_3')}
                            <img {...imgOptions} src="/images/geo-error/android3.jpg" alt="Android"/></li>
                    </ol>
                }

            }
            if (isIOS) {
                if (isWebview) {
                    return <ol>
                        <li>
                            {t('geo_error_webview_ios_1')}
                        </li>
                        <li>
                            {t('geo_error_webview_ios_2')}
                        </li>
                        <li>
                            {t('geo_error_webview_ios_3')}
                            <img {...imgOptions} src="/images/geo-error/webview/ios1.jpg" alt="IOs"/>
                        </li>
                        <li>
                            {t('geo_error_webview_ios_4')}
                            <img {...imgOptions} src="/images/geo-error/webview/ios2.jpg" alt="IOs"/>
                        </li>
                    </ol>
                } else {
                    return <ol>
                        <li>{t('geo_error_ios_1')}
                            <img {...imgOptions} src="/images/geo-error/ios1.jpg" alt="IOs"/></li>
                        <li>{t('geo_error_ios_2')}
                            <img {...imgOptions} src="/images/geo-error/ios2.jpg" alt="IOs"/></li>
                        <li>{t('geo_error_ios_3')}
                            <img {...imgOptions} src="/images/geo-error/ios3.jpg" alt="IOs"/></li>
                    </ol>
                }
            }
        }
        if (isDesktop) {
            if (isChrome) {
                return <ol>
                    <li>{t('geo_error_chrome_1')}
                        <img {...imgOptions} src="/images/geo-error/chrome1.jpg" alt="Chrome"/></li>
                    <li>{t('geo_error_chrome_2')}
                        <img {...imgOptions} src="/images/geo-error/chrome2.jpg" alt="Chrome"/></li>
                </ol>
            }
            if (isFirefox) {
                return <ol>
                    <li>{t('geo_error_firefox_1')}
                        <img {...imgOptions} src="/images/geo-error/firefox1.jpg" alt="Firefox"/></li>
                    <li>{t('geo_error_firefox_2')}
                        <img {...imgOptions} src="/images/geo-error/firefox2.jpg" alt="Firefox"/></li>
                    <li>{t('geo_error_firefox_3')}
                        <img {...imgOptions} src="/images/geo-error/firefox3.jpg" alt="Firefox"/></li>
                </ol>
            }
            if (isSafari) {
                return <ol>
                    <li>{t('geo_error_safari_1')}
                        <img {...imgOptions} src="/images/geo-error/safari1.jpg" alt="Chrome"/></li>
                    <li>{t('geo_error_safari_2')}
                        <img {...imgOptions} src="/images/geo-error/safari2.jpg" alt="Chrome"/></li>
                </ol>
            }
            if (isEdge) {
                return <ol>
                    <li>{t('geo_error_edge_1')}
                        <img {...imgOptions} src="/images/geo-error/edge1.jpg" alt="Edge"/></li>
                    <li>{t('geo_error_edge_2')}
                        <img {...imgOptions} src="/images/geo-error/edge2.jpg" alt="Edge"/></li>
                    <li>{t('geo_error_edge_3')}
                        <img {...imgOptions} src="/images/geo-error/edge3.jpg" alt="Edge"/></li>
                </ol>
            }
        }
    }

    return (
        <Dialog
            open={!isGeolocationEnabled}
            onClose={onClose}
            className='dialog geo-error'>
            <DialogTitle>
                {renderTitle()}
            </DialogTitle>
            <DialogContent>
                {renderContent()}
            </DialogContent>
            <DialogActions>
                <Button variant="contained" color="secondary" onClick={onClose}>{t('Cancel')}</Button>
            </DialogActions>
        </Dialog>
    )
}
