import {
    Box,
    Button,
    CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Typography
} from "@mui/material";
import {Stack} from '@mui/system';
import {useTranslation} from "react-i18next";
import {useContests} from "../../../context/ContestContext";
import {ContestCurrency} from '../ContestCurrency';
import {ContestComingSoon} from "../ContestComingSoon";
import ContestListItem from "./ContestListItem";

export const ContestList = () => {
    const {t} = useTranslation();
    const contestContext = useContests();

    const onCloseAddTicketError = async () => {
        contestContext.clearAddLotteryTicketError()
        window.location.reload()
    }

    return (
        <>
            <Box sx={{mt: 4, mb: 2}}></Box>

            <ContestCurrency/>

            {/* Contest enabled */}
            <Stack my={2} alignItems='center'>
                {contestContext.errors.map((error) => {
                    return <Typography color='error'>{error.message}</Typography>
                })}
                {contestContext.isLoading && <CircularProgress color='secondary' sx={{my: 4}}/>}
            </Stack>

            {
                contestContext.isAddLotteryTicketError &&
                <Dialog open onClose={contestContext.clearAddLotteryTicketError}>
                    <DialogTitle variant="h4" color='error'>{t('ContestAddToken_ErrorTitle')}</DialogTitle>
                    <DialogContent>
                        <Typography color="error">{t('ContestAddToken_ErrorDescription')}</Typography>
                    </DialogContent>
                    <DialogActions>
                        <Button variant="contained" color="secondary"
                                onClick={onCloseAddTicketError}>{t('Close')}</Button>
                    </DialogActions>
                </Dialog>
            }

            {
                contestContext.isAddLotteryTicketsLoading &&
                <Dialog open>
                    <DialogContent>
                        <Stack alignItems={"center"} gap={2}>
                            <Typography variant="body1">{t('ContestAddToken_LoadingText')}</Typography>
                            <CircularProgress color="secondary"/>
                        </Stack>
                    </DialogContent>
                </Dialog>
            }

            {
                !contestContext.isLoading && !contestContext.errors.length && (
                    <>
                        {contestContext.contests
                            .filter(contest => contest.startDate < new Date())
                            .map((contest, index) => {
                                return <ContestListItem
                                    index={index}
                                    key={contest.id}
                                    contest={contest}
                                />
                            })}
                        {!contestContext.contests.length && <ContestComingSoon/>}
                    </>
                )
            }
        </>
    )
}
