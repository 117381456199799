import {Container, Typography} from "@mui/material";
import {Theme} from "../../mui/Theme";
import {useTranslation} from "react-i18next";

export default function WonPrizeBanner() {
    const {t} = useTranslation();

    return (
        <Container sx={{background: Theme.palette.primary.main, pt: 6, pb: 4}}>
            <Typography variant='h1' fontSize={{xs: 32, sm: 46}} lineHeight={{xs: '24px', sm: '46px'}}>
                {t("Banner_Congratulation")}
            </Typography>
            <Typography fontSize={{xs: 18, sm: 24}} sx={{
                mb: 2,
                maxWidth: {xs: '100%', sm: '70%', md: '500px'}
            }}>
                {t("Banner_Winner")}
            </Typography>
        </Container>
    )
}