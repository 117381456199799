import React, { useEffect } from 'react'
import { useAuth } from '../context/AuthContext'
import { useRouter } from '../context/RouterContext'

const ProtectedRoute = ({ children }: { children: React.ReactNode }) => {
  const { user, tcsSignIn } = useAuth()
  const { navigate } = useRouter();

  useEffect(() => {
    if (!user) {
      tcsSignIn()
    }
  }, [navigate, user, tcsSignIn])

  return <>{user ? children : null}</>
}

export default ProtectedRoute
