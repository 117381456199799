import { Button } from "@mui/material"
import { useTranslation } from "react-i18next"
import SimplePage from "../components/SimplePage"
import { useAuth } from "../context/AuthContext"
import { useRouter } from "../context/RouterContext"

const RestrictedPage = () => {
  const { t } = useTranslation()
  const { navigate } = useRouter()
  const {  tcsSignIn } = useAuth()


  const tcsLogin = async (_e: React.MouseEvent<HTMLElement>) => {
    _e.preventDefault();
    try {
      await tcsSignIn();
      navigate();
    } catch (error) {
      console.log(error);
    }
  }

  return (
    <SimplePage>
      <h1>{t('Restricted Area')}</h1>
      <p>{t('You try to reach a restricted page Please login')}</p>


      <Button
        variant="contained"
        type="button"
        onClick={tcsLogin}
      >
        {t('login')}
      </Button>
      &nbsp;
      <Button
        variant="contained"
        type="button"
        onClick={() => navigate('/')}
      >
        {t('Back to the map')}
      </Button>
    </SimplePage>
  )
}

export default RestrictedPage
