import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Stack, Typography
} from "@mui/material";
import React from "react";
import {useTranslation} from "react-i18next";
import {PrizeWithUserEntries} from "../../../context/ContestContext";
import WinnersLoader from "./WinnersLoader";
import {langFromi18nLang} from "../../../utils/helpers/Lang";
import {useProfile} from "../../../context/ProfileContext";

interface Props {
    prize: PrizeWithUserEntries,
    onClose(): void
}

export default function WinnersDialog({ prize, onClose }: Props) {
    const {t, i18n} = useTranslation();
    const lang = langFromi18nLang(i18n.language);
    const {profile} = useProfile();

    const isWinner = () => {
        return prize.winnerIds.includes(profile?.id ?? "");
    }

    return (
        <Dialog
            open={true}
            onClose={onClose}
            className='dialog history'>
            <DialogTitle>
                <Stack alignItems={'flex-start'}>
                    <Typography variant="h3" sx={{ mb: 1 }}>
                        {t("Winners_List")}
                    </Typography>
                    <Typography variant={"body1Bold"} fontSize={15} sx={{ mb: 0.5 }}>
                        {prize[`title_${lang}`]}
                    </Typography>
                    <Box className="winners-dialog-header-summary">
                        <Typography fontSize={11}>{t("Dialog_Total_Number_WonPrize")} {prize.numberOfPrizes}</Typography>
                        <Typography fontSize={11}>{t("Dialog_Total_Token_Added")} {prize.totalUsedTokens}</Typography>
                    </Box>
                    {
                        isWinner() &&
                        <Box className="winners-dialog-header-congratulation">
                            <Stack direction={'row'} gap={1}
                                   sx={{
                                       p: 1, px: 1, my: 1
                                   }}>
                                <img width={64} height={64} alt={t('Thanks for contributing')} src="/images/party.svg" />
                                <Stack>
                                    <Typography variant="body1Bold" fontSize={"1rem"}>{t("Dialog_Congratulation_Title")}</Typography>
                                    <Typography fontSize={11}>{t("Dialog_Congratulation_Text")}</Typography>
                                </Stack>
                            </Stack>
                        </Box>
                    }
                </Stack>
            </DialogTitle>
            <DialogContent>
                <div>
                    {
                        prize &&
                        <WinnersLoader prize={prize}/>
                    }
                </div>
            </DialogContent>
            <DialogActions>
                <Button variant="contained" color="secondary" onClick={onClose}>{t('Close')}</Button>
            </DialogActions>
        </Dialog>
    )
}