import { Theme } from "../../mui/Theme";
import { FiabilityLevel } from "../model/FiabilityLevel";

export class Color {
    static forFiabilityLevel(level?: FiabilityLevel): string {
        switch (level) {
            case FiabilityLevel.CONFIDENT:
            case FiabilityLevel.FEW_RECENT_PRICES:
                return Theme.palette.success.main
            case FiabilityLevel.OLD_LAST_UPDATE:
                return Theme.palette.warning.main;
            case FiabilityLevel.OUTDATED_LAST_PRICE_UPDATE:
                return Theme.palette.error.main;
            default:
                return Theme.palette.grey[700];
        }
    }

    static iconColorForFiability(level?: FiabilityLevel): string {
        switch (level) {
            case FiabilityLevel.CONFIDENT:
                return Theme.palette.success.main
            case FiabilityLevel.FEW_RECENT_PRICES:
            case FiabilityLevel.OLD_LAST_UPDATE:
                return Theme.palette.warning.main;
            case FiabilityLevel.OUTDATED_LAST_PRICE_UPDATE:
                return Theme.palette.error.main;
            default:
                return Theme.palette.grey[700];
        }
    }
}


