import React from "react";

interface Props {
  children:React.ReactNode
  color?: 'dark';
}

function SimplePage({ children, color }:Props) {
  return (
      <div className={"simplePage"}>
          <div className={"container"}>
              <div className={"content"}>
                  {children}
              </div>
          </div>
      </div>
  )
}

export default SimplePage
