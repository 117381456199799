import React, {useEffect, useState} from 'react';
import {firestore, storage} from '../../config/firebase';
import {ref, uploadBytes, getDownloadURL} from "firebase/storage";
import {Contest} from "../../utils/model/Contest";
import {ContestWithPrizes, PrizeWithUserEntries} from "../../context/ContestContext";
import {Button, Grid, InputLabel, Stack, TextField, Typography} from "@mui/material";
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';
import {AdapterMoment} from '@mui/x-date-pickers/AdapterMoment';
import {tcsBlueGray1, tcsBlueGray2} from "../../mui/Theme";
import {useTranslation} from "react-i18next";
import {langFromi18nLang} from "../../utils/helpers/Lang";
import moment from "moment";
import AdminContestPrizeDialog from "./AdminContestPrizeDialog";
import {ContestPrize} from "../../utils/model/ContestPrize";
import {v4 as uuidv4} from 'uuid';
import ContestListItem from "../contestpage/contestlist/ContestListItem";
import {setContestAdmin} from "../../utils/Api";
import {ContestAdminDTO} from "../../utils/model/ContestAdminDTO";
import ErrorResponseDialog from "../station/components/ErrorResponseDialog";
import OperationAdminDialog from "./OperationAdminDialog";
import {ContestPrizeAdminDTO} from "../../utils/model/ContestPrizeAdminDTO";
import DialogLoader from "../common/DialogLoader";
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import {useRouter} from "../../context/RouterContext";
import {useParams} from "react-router-dom";
import {collection, doc, query, where, getDoc, getDocs} from "firebase/firestore";
import ContestConverter from "../../utils/converters/ContestConverter";
import ContestPrizeConverter from "../../utils/converters/ContestPrizeConverter";

interface Props {
    initialContestWithPrizes?: ContestWithPrizes;
}

interface ImageByContestPrize {
    contestPrizeId: string,
    image: File,
    sponsor: File
}

export default function AdminCreateContest({initialContestWithPrizes}: Props) {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState<TemplateStringsArray | undefined>();
    const [resultResponse, setResultResponse] = useState<string | undefined>(undefined);
    const {navigate} = useRouter();
    const {contestId} = useParams();

    const [openContestPrizeDialog, setOpenContestPrizeDialog] = useState(false);
    const [contest, setContest] = useState<Contest>({
        id: initialContestWithPrizes?.id || uuidv4(),
        displayName_DE: initialContestWithPrizes?.displayName_DE || "",
        displayName_FR: initialContestWithPrizes?.displayName_FR || "",
        displayName_IT: initialContestWithPrizes?.displayName_IT || "",
        startDate: initialContestWithPrizes?.startDate || new Date(),
        endDate: initialContestWithPrizes?.endDate || new Date()
    });
    const [contestPrizes, setContestPrizes] = useState<ContestPrize[]>([]);
    const [contestWithPrizes, setContestWithPrizes] = useState<ContestWithPrizes>({
        ...contest,
        prizes: initialContestWithPrizes?.prizes || []
    });
    const [contestPrizesImages, setContestPrizesImages] = useState<ImageByContestPrize[]>([]);
    const [selectedContestPrize, setSelectedContestPrize] = useState<ContestPrize | undefined>(undefined);

    const [displayNameFrError, setDisplayNameFrError] = useState(false);
    const [displayNameDeError, setDisplayNameDeError] = useState(false);
    const [displayNameItError, setDisplayNameItError] = useState(false);
    const [startDateError, setStartDateError] = useState(false);
    const [endDateError, setEndDateError] = useState(false);
    const [wonPrizesError, setWonPrizesError] = useState(false);

    const {i18n} = useTranslation();
    moment.locale(i18n.language);
    const lang = langFromi18nLang(i18n.language);

    const handleChangeDisplayNameFr = (title: string) => {
        setContest((prevContest) => ({
            ...prevContest,
            displayName_FR: title,
        }));
        setContestWithPrizes((prevContestWithPrizes) => ({
            ...prevContestWithPrizes,
            displayName_FR: title,
        }));
        if (title !== "") {
            setDisplayNameFrError(false);
        }
    }

    const handleChangeDisplayNameDe = (title: string) => {
        setContest((prevContest) => ({
            ...prevContest,
            displayName_DE: title,
        }));
        setContestWithPrizes((prevContestWithPrizes) => ({
            ...prevContestWithPrizes,
            displayName_DE: title,
        }));
        if (title !== "") {
            setDisplayNameDeError(false);
        }
    }

    const handleChangeDisplayNameIt = (title: string) => {
        setContest((prevContest) => ({
            ...prevContest,
            displayName_IT: title,
        }));
        setContestWithPrizes((prevContestWithPrizes) => ({
            ...prevContestWithPrizes,
            displayName_IT: title,
        }));
        if (title !== "") {
            setDisplayNameItError(false);
        }
    }

    const handleChangeStartDate = (time: any) => {
        setContest((prevContest) => ({
            ...prevContest,
            startDate: time.toDate(),
        }));
        setContestWithPrizes((prevContestWithPrizes) => ({
            ...prevContestWithPrizes,
            startDate: time.toDate(),
        }));
        if (contest.startDate < contest.endDate) {
            setStartDateError(false);
            setEndDateError(false);
        }
    }

    const handleChangeEndDate = (time: any) => {
        setContest((prevContest) => ({
            ...prevContest,
            endDate: time.toDate(),
        }));
        setContestWithPrizes((prevContestWithPrizes) => ({
            ...prevContestWithPrizes,
            endDate: time.toDate(),
        }));
        if (contest.startDate < contest.endDate) {
            setStartDateError(false);
            setEndDateError(false);
        }
    }

    const handleOpenContestPrizeDialog = () => {
        setSelectedContestPrize(undefined);
        setOpenContestPrizeDialog(true);
    };

    const contestCanBeDisplayed = () => {
        return contest.displayName_FR !== ""
            && contest.displayName_DE !== ""
            && contest.displayName_IT !== ""
            && contest.startDate !== new Date()
            && contest.endDate !== new Date();
    }

    const handleSaveContest = async () => {
        setLoading(true);
        if (contest.startDate >= contest.endDate) {
            setStartDateError(true);
            setEndDateError(true);
        }

        if (contest.displayName_FR === '') {
            setDisplayNameFrError(true);
        }

        if (contest.displayName_DE === '') {
            setDisplayNameDeError(true);
        }

        if (contest.displayName_IT === '') {
            setDisplayNameItError(true);
        }

        if (contestPrizes.length <= 0) {
            setWonPrizesError(true);
        }

        if (contest.displayName_FR !== ''
            && contest.displayName_DE !== ''
            && contest.displayName_IT !== ''
            && contest.endDate > contest.startDate
            && contestPrizes.length > 0) {
            try {
                const contestAdminDTO: ContestAdminDTO = {
                    id: contest.id,
                    displayName_DE: contest.displayName_DE,
                    displayName_FR: contest.displayName_FR,
                    displayName_IT: contest.displayName_IT,
                    startDate: contest.startDate,
                    endDate: contest.endDate,
                    prizes: []
                }

                for (const contestPrize of contestPrizes) {
                    const contestPrizeImages = contestPrizesImages.find((imageByContestPrize: ImageByContestPrize) => imageByContestPrize.contestPrizeId === contestPrize.id);

                    if (contestPrizeImages) {
                        const imageRef = ref(storage, 'prizes/images/' + Date.now() + '_' + contestPrizeImages.image.name);
                        await uploadBytes(imageRef, contestPrizeImages.image);
                        const newImageSrc = await getDownloadURL(imageRef);

                        const sponsorRef = ref(storage, 'prizes/sponsors/' + Date.now() + '_' + contestPrizeImages.sponsor.name);
                        await uploadBytes(sponsorRef, contestPrizeImages.sponsor);
                        const newSponsorSrc = await getDownloadURL(sponsorRef);

                        const contestPrizeAdminDTO: ContestPrizeAdminDTO = {
                            contestId: contest.id,
                            order: contestPrize.order || 1,
                            title_DE: contestPrize.title_DE,
                            title_FR: contestPrize.title_FR,
                            title_IT: contestPrize.title_IT,
                            description_DE: contestPrize.description_DE,
                            description_FR: contestPrize.description_FR,
                            description_IT: contestPrize.description_IT,
                            numberOfPrizes: contestPrize.numberOfPrizes,
                            value: contestPrize.value,
                            imageSrc: newImageSrc,
                            sponsorName: contestPrize.sponsorName || "",
                            sponsorImageSrc: newSponsorSrc,
                            sponsorURL_DE: contestPrize.sponsorURL_DE || "",
                            sponsorURL_FR: contestPrize.sponsorURL_FR || "",
                            sponsorURL_IT: contestPrize.sponsorURL_IT || ""
                        }

                        contestAdminDTO.prizes.push(contestPrizeAdminDTO);
                    }
                }

                const result = await setContestAdmin(contestAdminDTO);
                setResultResponse(result.data.message);
            } catch (error: any) {
                setError(error.message);
            }
        }

        setLoading(false);
    };

    const onEditContestPrize = (contestPrize: ContestPrize) => {
        setSelectedContestPrize(contestPrize);
        setOpenContestPrizeDialog(true);
    }

    const onDeleteContestPrize = async (contestPrize: ContestPrize) => {
        setContestPrizes(contestPrizes.filter((contestPrizeItem: ContestPrize) => contestPrizeItem.id !== contestPrize.id));
        setContestWithPrizes((prevContestWithPrizes) => ({
            ...prevContestWithPrizes,
            prizes: prevContestWithPrizes.prizes.filter((prizeWithUserEntries: PrizeWithUserEntries) => prizeWithUserEntries.id !== contestPrize.id)
        }));
    }

    const onUpdateContestPrize = (e: React.MouseEvent<HTMLElement>, contestPrize: ContestPrize, image: File, sponsorImage: File) => {
        setOpenContestPrizeDialog(false);
        contestPrize.contestId = contest.id;

        if (image && sponsorImage) {
            contestPrize.imageSrc = URL.createObjectURL(image);
            const imageByContestPrizes = contestPrizesImages.filter((imageByContestPrize: ImageByContestPrize) => imageByContestPrize.contestPrizeId !== contestPrize.id);
            const imageByContestPrize = {
                contestPrizeId: contestPrize.id,
                image: image,
                sponsor: sponsorImage
            }
            setContestPrizesImages([...imageByContestPrizes, imageByContestPrize]);
        }

        const newContestPrizes = contestPrizes.filter((contestPrizeItem: ContestPrize) => contestPrizeItem.id !== contestPrize.id);
        setContestPrizes([...newContestPrizes, contestPrize]);

        let newPrizeWithUserEntries: PrizeWithUserEntries = {
            id: contestPrize.id,
            contestId: contest.id,
            title_DE: contestPrize.title_DE,
            title_FR: contestPrize.title_FR,
            title_IT: contestPrize.title_IT,
            description_DE: contestPrize.description_DE,
            description_FR: contestPrize.description_FR,
            description_IT: contestPrize.description_IT,
            numberOfPrizes: contestPrize.numberOfPrizes,
            value: contestPrize.value,
            imageSrc: contestPrize.imageSrc,
            totalUsedTokens: 0,
            wonPrizeIds: [],
            winnerIds: [],
            isNotWinnableMultipleTimes: true,
            userEntries: 0
        };

        const newPrizes = contestWithPrizes.prizes.filter((prizeWithUserEntries: PrizeWithUserEntries) => prizeWithUserEntries.id !== contestPrize.id);
        setContestWithPrizes((prevContestWithPrizes) => ({
            ...prevContestWithPrizes,
            prizes: [...newPrizes, newPrizeWithUserEntries],
        }));
    }

    const onAddContestPrize = async (e: React.MouseEvent<HTMLElement>, contestPrize: ContestPrize, image: File, sponsorImage: File) => {
        setOpenContestPrizeDialog(false);
        contestPrize.contestId = contest.id;

        if (image && sponsorImage) {
            contestPrize.imageSrc = URL.createObjectURL(image);
            contestPrize.sponsorImageSrc = URL.createObjectURL(sponsorImage);

            const imageByContestPrize: ImageByContestPrize = {
                contestPrizeId: contestPrize.id,
                image: image,
                sponsor: sponsorImage
            }
            setContestPrizesImages([...contestPrizesImages, imageByContestPrize]);
        }

        setContestPrizes([...contestPrizes, contestPrize]);
        let newPrizeWithUserEntries: PrizeWithUserEntries = {
            id: contestPrize.id,
            contestId: contest.id,
            title_DE: contestPrize.title_DE,
            title_FR: contestPrize.title_FR,
            title_IT: contestPrize.title_IT,
            description_DE: contestPrize.description_DE,
            description_FR: contestPrize.description_FR,
            description_IT: contestPrize.description_IT,
            numberOfPrizes: contestPrize.numberOfPrizes,
            value: contestPrize.value,
            imageSrc: contestPrize.imageSrc,
            totalUsedTokens: 0,
            wonPrizeIds: [],
            winnerIds: [],
            isNotWinnableMultipleTimes: true,
            userEntries: 0
        };

        setContestWithPrizes((prevContestWithPrizes) => ({
            ...prevContestWithPrizes,
            prizes: [...prevContestWithPrizes.prizes, newPrizeWithUserEntries],
        }));

        setWonPrizesError(false);
    };

    useEffect(() => {
        if (contestId) {
            const fetchContest = async () => {
                setLoading(true);
                const contestRef = doc(firestore, "contests", contestId).withConverter(ContestConverter);
                const contestSnapshot = await getDoc(contestRef);
                setContest(contestSnapshot.data() as Contest);

                const queryContestPrizes = query(
                    collection(firestore, "contestPrizes").withConverter(ContestPrizeConverter),
                    where("contestId", "==", contestId)
                );
                const contestPrizesSnapshot = await getDocs(queryContestPrizes);
                const contestPrizes = contestPrizesSnapshot.docs.map(contestPrize => contestPrize.data()) as PrizeWithUserEntries[];
                setContestPrizes(contestPrizes);
                setContestWithPrizes({...contestSnapshot.data() as Contest, prizes: contestPrizes});
                setLoading(false);
            }
            fetchContest().catch(console.error);
        }
    }, [contestId]);

    return (
        <>
            {loading &&
                <DialogLoader loading={loading} onClose={() => setLoading(!loading)}/>
            }
            {error &&
                <ErrorResponseDialog errorCode={error} onClose={() => {
                    setError(undefined);
                }}/>
            }
            {resultResponse &&
                <OperationAdminDialog message={resultResponse} onClose={() => setResultResponse(undefined)}/>
            }
            {openContestPrizeDialog &&
                <AdminContestPrizeDialog
                    onAdd={onAddContestPrize}
                    onUpdate={onUpdateContestPrize}
                    onClose={() => setOpenContestPrizeDialog(!openContestPrizeDialog)}
                    initialContestPrize={selectedContestPrize}
                />
            }
            <Stack direction={'column'}>
                <form autoComplete="off" onSubmit={handleSaveContest}>
                    <Stack sx={{
                        display: "flex",
                        flexDirection: "column",
                        borderRadius: '5px',
                        background: tcsBlueGray1,
                        gap: 2,
                        padding: 2,
                        margin: 2
                    }}>
                        <h3>1 - Contest</h3>
                        <TextField
                            value={contest.displayName_DE}
                            label="DisplayName DE"
                            variant="outlined"
                            required
                            error={displayNameDeError}
                            onChange={(e: any) =>
                                handleChangeDisplayNameDe(e.target.value)}
                        />
                        <TextField
                            value={contest.displayName_FR}
                            label="DisplayName FR"
                            variant="outlined"
                            required
                            error={displayNameFrError}
                            onChange={(e: any) =>
                                handleChangeDisplayNameFr(e.target.value)}
                        />
                        <TextField
                            value={contest.displayName_IT}
                            label="DisplayName IT"
                            variant="outlined"
                            required
                            error={displayNameItError}
                            onChange={(e: any) =>
                                handleChangeDisplayNameIt(e.target.value)}
                        />
                        <LocalizationProvider dateAdapter={AdapterMoment}>
                            <DatePicker
                                slotProps={{
                                    textField: {
                                        required: true,
                                        error: startDateError
                                    }
                                }}
                                value={moment(contest.startDate || new Date())}
                                label="Start Date"
                                disablePast
                                onChange={handleChangeStartDate}/>
                            <DatePicker
                                slotProps={{
                                    textField: {
                                        required: true,
                                        error: endDateError,
                                        helperText: endDateError ? "Invalid date range" : ""
                                    }
                                }}
                                value={moment(contest.endDate || new Date())}
                                label="End Date"
                                disablePast
                                onChange={handleChangeEndDate}/>
                        </LocalizationProvider>
                    </Stack>
                </form>
                <Stack sx={{
                    display: "flex",
                    flexDirection: "column",
                    borderRadius: '5px',
                    background: tcsBlueGray2,
                    gap: 2,
                    padding: 2,
                    margin: 2,
                    border: wonPrizesError ? 1 : 0,
                    borderColor: "#cd0046"
                }}>
                    <h3>2 - Prizes</h3>
                    {contestPrizes.length > 0 &&
                        <Grid container direction="column" rowSpacing={2}>
                            {contestPrizes.map((contestPrize: ContestPrize, index: number) => {
                                return <Grid item key={"contestPrize_" + index}>
                                    <Stack width={'100%'}
                                           sx={{
                                               display: "flex",
                                               flexDirection: "row",
                                               justifyContent: "space-between"
                                           }}>
                                        <Stack gap={1} alignItems={'center'} sx={{
                                            display: "flex",
                                            flexDirection: "row"
                                        }}>
                                            <Typography>{contestPrize.order} -</Typography>
                                            <Typography variant="h4">{contestPrize[`title_${lang}`]}</Typography>
                                            <Typography>x{contestPrize.numberOfPrizes}</Typography>
                                            <Typography>{contestPrize.value} CHF</Typography>
                                        </Stack>
                                        <Stack gap={1} sx={{
                                            display: "flex",
                                            flexDirection: "row"
                                        }}>
                                            <Button
                                                variant={'contained'}
                                                color={'warning'}
                                                onClick={(e) => onEditContestPrize(contestPrize)}>
                                                <EditIcon/>
                                            </Button>
                                            <Button
                                                variant={'contained'}
                                                color={'error'}
                                                onClick={(e) => onDeleteContestPrize(contestPrize)}>
                                                <DeleteIcon/>
                                            </Button>
                                        </Stack>
                                    </Stack>
                                </Grid>
                            })}
                        </Grid>
                    }
                    {wonPrizesError &&
                        <InputLabel sx={{color: "#cd0046", marginTop: 0}}>
                            There must be at least one element
                        </InputLabel>
                    }
                    <Stack sx={{
                        flexDirection: "row"
                    }}>
                        <Button color="secondary" variant="contained" onClick={handleOpenContestPrizeDialog}>
                            Add Prize
                        </Button>
                    </Stack>
                </Stack>

                <Stack sx={{
                    margin: 2,
                    padding: 2,
                    borderRadius: '5px',
                    flexDirection: "column",
                    background: tcsBlueGray1
                }}>
                    <h3>3 - Preview</h3>
                    {contestCanBeDisplayed() &&
                        <div className="contest">
                            <ContestListItem
                                index={1}
                                key={contestWithPrizes.id}
                                contest={contestWithPrizes}
                                preview={true}
                            />
                        </div>
                    }
                    <InputLabel>
                        Note: It's a preview so the buttons are not available.
                    </InputLabel>
                </Stack>
                <Stack sx={{
                    margin: 2,
                    padding: 2,
                    borderRadius: '5px',
                    flexDirection: "column",
                    background: tcsBlueGray2
                }}>
                    <h3>4 - Save</h3>
                    <Stack sx={{
                        flexDirection: "row",
                        gap: 1
                    }}>
                        <Button
                            variant="contained"
                            type="button"
                            onClick={() => navigate("/admin")}
                        >
                            {"Back"}
                        </Button>
                        <Button
                            color="secondary"
                            variant="contained"
                            onClick={handleSaveContest}
                        >
                            Save
                        </Button>
                    </Stack>
                </Stack>
            </Stack>
        </>
    );
};