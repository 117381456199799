import { WithFieldValue, DocumentData, QueryDocumentSnapshot, SnapshotOptions } from "firebase/firestore";
import { ContestPrize } from "../model/ContestPrize";
import PublicProfileConverter from "./PublicProfileConverter";

export const ContestPrizeConverter = {
  toFirestore(prize: WithFieldValue<ContestPrize | undefined>): DocumentData {
    return { ...prize };
  },
  fromFirestore(
    snapshot: QueryDocumentSnapshot,
    options: SnapshotOptions
  ): ContestPrize | undefined {
    try {
      const data = snapshot.data(options);
      if (data.winner) {
        data.winner = PublicProfileConverter.fromData(data.winner)
      }
      return {
        ...data,
        id: snapshot.id
      } as ContestPrize;
    } catch (e) {
      console.error("ContestConverter error " + e)
      return undefined
    }
  }
}

export default ContestPrizeConverter