
import { useRef } from "react";
import { ContestBanner } from "../components/contestpage/ContestBanner";
import { ContestList } from "../components/contestpage/contestlist/ContestList";
import HowItWorks from "../components/contestpage/HowItWorks";
import SimplePage from "../components/SimplePage";

const ContestPage = () => {
    const contestsRef = useRef<HTMLElement>(null)
    const onClick = () => {
        if (contestsRef.current) {
            contestsRef.current.scrollIntoView()
        }
    }
    return (
        <div className="contest">
            <SimplePage>
                <section className="no-margin">
                    <ContestBanner onClick={onClick}></ContestBanner>
                </section>
                <section>
                    <HowItWorks />
                </section>
                <section ref={contestsRef}>
                    <ContestList />
                </section>

            </SimplePage>
        </div>
    )
}

export default ContestPage

