import {UserAvatar} from "../model/UserAvatar";

export class AvatarIcon {
    static forAvatar(avatar: UserAvatar | undefined): string {
        switch (avatar) {
            case UserAvatar.AVATAR_01: return "/images/avatars/avatar_01.svg";
            case UserAvatar.AVATAR_02: return "/images/avatars/avatar_02.svg";
            case UserAvatar.AVATAR_03: return "/images/avatars/avatar_03.svg";
            case UserAvatar.AVATAR_04: return "/images/avatars/avatar_04.svg";
            case UserAvatar.AVATAR_05: return "/images/avatars/avatar_05.svg";
            case UserAvatar.AVATAR_06: return "/images/avatars/avatar_06.svg";
            case UserAvatar.AVATAR_07: return "/images/avatars/avatar_07.svg";
            case UserAvatar.AVATAR_08: return "/images/avatars/avatar_08.svg";
            case UserAvatar.AVATAR_09: return "/images/avatars/avatar_09.svg";
            case UserAvatar.AVATAR_10: return "/images/avatars/avatar_10.svg";
            case UserAvatar.AVATAR_11: return "/images/avatars/avatar_11.svg";
            case UserAvatar.AVATAR_12: return "/images/avatars/avatar_12.svg";
            case UserAvatar.AVATAR_13: return "/images/avatars/avatar_13.svg";
            case UserAvatar.AVATAR_14: return "/images/avatars/avatar_14.svg";
            case UserAvatar.AVATAR_15: return "/images/avatars/avatar_15.svg";
            case UserAvatar.AVATAR_16: return "/images/avatars/avatar_16.svg";
            case UserAvatar.AVATAR_17: return "/images/avatars/avatar_17.svg";
            case UserAvatar.AVATAR_18: return "/images/avatars/avatar_18.svg";
            case UserAvatar.AVATAR_19: return "/images/avatars/avatar_19.svg";
            case UserAvatar.AVATAR_20: return "/images/avatars/avatar_20.svg";
            case UserAvatar.AVATAR_21: return "/images/avatars/avatar_21.svg";
            case UserAvatar.AVATAR_22: return "/images/avatars/avatar_22.svg";
            case UserAvatar.AVATAR_23: return "/images/avatars/avatar_23.svg";
            case UserAvatar.AVATAR_24: return "/images/avatars/avatar_24.svg";
            case UserAvatar.AVATAR_25: return "/images/avatars/avatar_25.svg";
            case UserAvatar.AVATAR_26: return "/images/avatars/avatar_26.svg";
            case UserAvatar.AVATAR_27: return "/images/avatars/avatar_27.svg";
            case UserAvatar.AVATAR_28: return "/images/avatars/avatar_28.svg";
            case UserAvatar.AVATAR_29: return "/images/avatars/avatar_29.svg";
            case UserAvatar.AVATAR_30: return "/images/avatars/avatar_30.svg";
            case UserAvatar.AVATAR_31: return "/images/avatars/avatar_31.svg";
            case UserAvatar.AVATAR_32: return "/images/avatars/avatar_32.svg";
            case UserAvatar.AVATAR_33: return "/images/avatars/avatar_33.svg";
            case UserAvatar.AVATAR_34: return "/images/avatars/avatar_34.svg";
            case UserAvatar.AVATAR_35: return "/images/avatars/avatar_35.svg";
            case UserAvatar.AVATAR_36: return "/images/avatars/avatar_36.svg";
            case UserAvatar.AVATAR_37: return "/images/avatars/avatar_37.svg";
            case UserAvatar.AVATAR_38: return "/images/avatars/avatar_38.svg";
            case UserAvatar.AVATAR_39: return "/images/avatars/avatar_39.svg";
            case UserAvatar.AVATAR_40: return "/images/avatars/avatar_40.svg";
            case UserAvatar.AVATAR_41: return "/images/avatars/avatar_41.svg";
            case UserAvatar.AVATAR_42: return "/images/avatars/avatar_42.svg";
            case UserAvatar.AVATAR_43: return "/images/avatars/avatar_43.svg";
            case UserAvatar.AVATAR_44: return "/images/avatars/avatar_44.svg";
            case UserAvatar.AVATAR_45: return "/images/avatars/avatar_45.svg";
            case UserAvatar.AVATAR_46: return "/images/avatars/avatar_46.svg";
            case UserAvatar.AVATAR_47: return "/images/avatars/avatar_47.svg";
            case UserAvatar.AVATAR_48: return "/images/avatars/avatar_48.svg";
            case UserAvatar.AVATAR_49: return "/images/avatars/avatar_49.svg";
            case UserAvatar.AVATAR_50: return "/images/avatars/avatar_50.svg";
            default: return `/images/avatars/avatar_01.svg`
        }
    }
}