import {
    Dialog,
    Box,
    Stack,
    Typography,
    Link,
    DialogContent
} from "@mui/material";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { useProfile } from '../../../context/ProfileContext';
import { Contest, isContestActive } from "../../../utils/model/Contest";
import { langFromi18nLang } from "../../../utils/helpers/Lang";
import { tcsBeige1, tcsBlueGray1, Theme } from "../../../mui/Theme";
import { PrizeWithUserEntries } from "../../../context/ContestContext";
import { PrizeStats } from "./PrizeStats";
import { PrizeButtons } from "./PrizeButtons";

interface Props {
    contest: Contest;
    prize: PrizeWithUserEntries;
    
    onClose(): void;

    onAddTokensPress(): void;
    onLoginPress(): void;
    onShowWinnersPress(): void;
}

const ContestPrizeDetails = ({
    contest,
    prize,
    onClose,
    onAddTokensPress,
    onLoginPress,
    onShowWinnersPress
}: Props) => {
    const { t, i18n } = useTranslation()
    const lang = langFromi18nLang(i18n.language)
    const { profile } = useProfile()

    const internalClose = () => { onClose(); }
    moment.locale(i18n.language);

    const isActive = isContestActive(contest)

    const title = prize[`title_${lang}`]
    const description = prize[`description_${lang}`]
    const sponsorUrl = prize[`sponsorURL_${lang}`]

    return (
        <Dialog open={true} onClose={internalClose}>
            {/* Image */}
            <Box
                component="img"
                height="150px"
                src={prize.imageSrc}
                alt={prize[`title_${lang}`]}
                sx={{ background: tcsBlueGray1, objectFit: 'cover' }}
            />

            <DialogContent sx={{ p: 1, pb: 1 }}>


                {/* Title and value */}
                <div>
                    <Typography variant="h4">{title}</Typography>
                    <Typography color="text.secondary" fontSize={14}> {t("Value")} {t("CHF")}&nbsp;{prize.value}</Typography>
                    <Typography variant="body1Bold" color={Theme.palette.success.main} fontSize={{ xs: 12, sm: 14 }} lineHeight={1} sx={{ p: 0 }}>{t('Contests_NumberOfPrizes', {numberOfPrizes: prize.numberOfPrizes})}</Typography>
                </div>

                {/* Sponsor */}
                {prize.sponsorImageSrc && prize.sponsorName &&
                    <Stack direction='column'
                        sx={{
                            background: tcsBeige1,
                            mt: 1
                        }}>
                        <Stack direction='row' alignItems='center' gap={1}>
                            <img width={40} height={40} src={prize.sponsorImageSrc} alt={prize.sponsorName + "_image"} />
                            <Stack gap={0}>
                                <Typography variant="body1Bold" fontSize={11}>Sponsor</Typography>
                                {sponsorUrl
                                    ? <Link color={"secondary"} variant={'body1'} fontSize={12} underline={"hover"} sx={{ cursor: 'pointer' }} href={sponsorUrl}>{prize.sponsorName}</Link>
                                    : <Typography fontSize={12}>{prize.sponsorName}</Typography>
                                }

                            </Stack>
                        </Stack>
                    </Stack>
                }

                {/* Description */}
                {description &&
                    <Typography variant="body2" mt={2} mb={2} sx={{ whiteSpace: 'pre-line' }}>{description}</Typography>
                }


                <Stack direction={'column'} justifyContent='space-between' gap={1.5} mt={1}>
                    {/* Used tokens */}
                    <PrizeStats prize={prize} />

                    {/* Button */}
                    <PrizeButtons
                        isContestActive={isActive}
                        prize={prize}
                        profile={profile}
                        onAddTokensPress={onAddTokensPress}
                        onLoginPress={onLoginPress}
                        onShowWinnersPress={onShowWinnersPress}
                    />
                </Stack>
            </DialogContent>
        </Dialog >
    )
}

export default ContestPrizeDetails
