import { Avatar, Badge, Link } from '@mui/material';
import AppBar from '@mui/material/AppBar';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import IconButton from '@mui/material/IconButton';
import Toolbar from '@mui/material/Toolbar';
import Grid from '@mui/material/Unstable_Grid2';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useProfile } from '../../context/ProfileContext';
import { useRouter } from '../../context/RouterContext';
import RewardDialog from './components/RewardDialog';
import TokenDialog from './components/TokenDialog';
import { AvatarIcon } from "../../utils/assets/AvatarIcon";
import { ReactComponent as LotteryTicket } from '../../icons/tcs-icon_lotteryticket.svg'
import { ReactComponent as TrophyIcon } from '../../icons/Icon_Pokal3.svg'
import { Stack } from '@mui/system';

export const Header = () => {
    const { t } = useTranslation();
    const { navigate } = useRouter();
    const { profile, printDisplayName } = useProfile();

    const [rewards, setRewards] = useState(false);
    const [token, setToken] = useState(false);

    const goTo = (url: string, setReturnUrl: boolean = false) => {
        navigate(url, setReturnUrl);
    }

    const onLogo = (e: React.MouseEvent<HTMLElement>) =>  {
        goTo('/');
        e.preventDefault();
    }
    const onProfile = () => goTo('/profile')

    const onRewards = () => setRewards(!rewards)
    const onToken = () => setToken(!token)

    const tcsLogin = async (_e: React.MouseEvent<HTMLElement>) => {
        navigate('/login');
    }

    return (
        <AppBar position="relative" className="topBar">
            <Container maxWidth={false} sx={{ px: 0.5 }}>
                <Toolbar disableGutters>

                    {/* Main container */}
                    <Stack width={'100%'} margin={'auto'} gap={3}
                        direction={'row'} justifyContent={'space-between'} flexWrap={'nowrap'} alignContent={'center'} alignItems={'center'}>

                        {/* Left part */}
                        <Link href="/" onClick={onLogo} color={'inherit'} underline={'none'} sx={{ cursor: 'pointer' }}>
                            <Stack direction={'row'} alignItems={'center'} gap={1}>
                                <img alt="TCS Logo" width="48" height="48" className="img-contain" src="/images/tcslogo.webp" />
                                <h1 className="app-title">{t('app_name')}</h1>
                            </Stack>
                        </Link>

                        {/* Right part */}
                        <Stack direction={'row'} justifyItems={'center'} alignItems={'center'} gap={1} pt={1.5} pb={1}>
                            {
                                profile &&
                                <Badge badgeContent={profile.lotteryTickets} color="error" className={"menuButton"} max={999}>
                                    <IconButton onClick={onToken}>
                                        <LotteryTicket className={"customSVGIcon lotterySVG stroke1 size25"} />
                                    </IconButton>
                                </Badge>
                            }
                            {
                                token && <TokenDialog onClose={onToken}></TokenDialog>
                            }
                            {
                                profile &&
                                <Badge
                                    badgeContent={profile.unseenPoints > 0 ? `+${profile.unseenPoints}` : 0}
                                    color="success"
                                    max={999}
                                    className={"menuButton"}>
                                    <IconButton onClick={onRewards}>
                                        <TrophyIcon className={"customSVGIcon size25"} />
                                    </IconButton>
                                </Badge>
                            }
                            {
                                rewards && <RewardDialog onClose={onRewards}></RewardDialog>
                            }
                            <Grid container gap={1}>
                                {!profile && (
                                    <Button
                                        onClick={tcsLogin}
                                        variant={'contained'}
                                        color={'secondary'}>
                                        {t('Register or login')}
                                    </Button>
                                )}
                                {profile && (
                                    <IconButton sx={{ p: 0 }} onClick={onProfile}>
                                        <Avatar
                                            alt={profile ? printDisplayName(profile?.publicProfile) : ''}
                                            src={profile ? AvatarIcon.forAvatar(profile?.publicProfile.avatar) : ''}></Avatar>
                                    </IconButton>
                                )}
                            </Grid>
                        </Stack>
                        {/* </Grid> */}
                    </Stack>
                </Toolbar>
            </Container>
        </AppBar>
    );
};

