import {AdminUserMarkerInfo} from "./AdminUserMarkerInfo";
import {UserActionAdminDTO} from "../../../functions/src/utils/model/UserActionAdminDTO";
import {fontFamilyMuseo700, tcsBlueGray1, Theme} from "../../mui/Theme";
import {Box, Dialog, DialogActions, DialogContent, DialogTitle, Stack, Typography} from "@mui/material";
import Button from "@mui/material/Button";
import React, {useState} from "react";
import {UserActionTypeAdminDTO} from "../../utils/model/UserActionTypeAdminDTO";
import moment from "moment/moment";
import {UserActionType} from "../../utils/model/UserActionType";

interface Props {
    lat: number;
    lng: number;
    station: UserActionAdminDTO;
}

const pinLegWidth = 18;
const pinLegHeight = 14;
const points = `0,0 ${pinLegWidth/2},${pinLegHeight} ${pinLegWidth},0`;

export const AdminUserMarker = ({station}: Props) => {
    const [showDetailsDialog, setShowDetailsDialog] = useState(false);
    moment.locale("en");

    const onStationClick = () => {
        setShowDetailsDialog(true);
    }

    const getBackgroundColor = (userActionType: UserActionType) => {
        switch (userActionType) {
            case UserActionType.UPDATE_PRICE:
                return Theme.palette.success.light;
            case UserActionType.NEW_FUEL_TYPE:
                return Theme.palette.secondary.light;
            case UserActionType.CONFIRM_PRICE:
                return Theme.palette.error.light;
            default:
                return tcsBlueGray1;
        }
    }

    return (
        <>
            {station &&
                <Dialog
                    open={showDetailsDialog}
                    onClose={() => setShowDetailsDialog(false)}
                    className='dialog'>
                    <DialogTitle>
                        <Stack alignItems={'flex-start'}>
                            <Typography variant="h4" sx={{mb: 1}}>{station.stationId}</Typography>
                            <Typography variant="h6">{station.stationFormattedAddress}</Typography>
                        </Stack>
                    </DialogTitle>
                    <DialogContent>
                        <Stack direction='column' width={'100%'} justifyContent={'space-around'} gap={1} paddingBottom={1}>
                            {station.userActionTypeAdmin.map((item: UserActionTypeAdminDTO, index: number) => {
                                return <Box key={'userActionTypeAdmin-' + index}
                                            sx={{
                                                borderRadius: '5px',
                                                background: getBackgroundColor(item.userActionType),
                                                border: 1,
                                            }}>
                                    <Stack direction={'column'} alignItems={'center'}>
                                        <Typography fontSize={11} fontFamily={fontFamilyMuseo700} margin={'2px'}>
                                            {item.userActionType} on {item.userActionFuelType} ({moment(item.userActionDate).format('DD/MM/YYYY, HH:mm:ss')})
                                        </Typography>
                                    </Stack>
                                </Box>
                            })}
                        </Stack>
                    </DialogContent>
                    <DialogActions>
                        <Button variant="contained" color="secondary" onClick={() => setShowDetailsDialog(false)}>Close</Button>
                    </DialogActions>
                </Dialog>
            }
            <div onClick={onStationClick} className={"marker"}>
                <svg height={pinLegHeight} width={pinLegWidth}>
                    <g color={Theme.palette.error.main}>
                        <polygon points={points} fill="currentcolor"/>
                    </g>
                </svg>
                <div className="marker-inner">
                    <AdminUserMarkerInfo />
                </div>
            </div>
        </>
    )
}