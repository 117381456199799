import { Button, Dialog, DialogActions, DialogContent, DialogContentText } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";

interface Props {
    hasPermission: boolean,
    onClose(...args: any): void
}

export default function ForbiddenParticipationDialog({ hasPermission, onClose }: Props) {
    const { t } = useTranslation();

    return (
        <Dialog
            open={!hasPermission}
            onClose={onClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            className='dialog'>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    {t('too_far_from_station')}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button variant="contained" color="secondary" onClick={onClose}>{t('Cancel')}</Button>
            </DialogActions>
        </Dialog>
    )
}
