import { Button, Typography } from '@mui/material';
import { useTranslation } from "react-i18next";
import { useRouter } from '../../context/RouterContext';
import HowItWorks from '../contestpage/HowItWorks';
import { ArrowForward } from '@mui/icons-material';
import { Stack } from '@mui/system';

function ProfileThanks() {
    const { t } = useTranslation();

    const { navigate } = useRouter();
    return (
        <div className="profile-thanks">
            <Typography variant="h2" sx={{mt: 4}}>{t('Registration finished!')}</Typography>
            <p>{t('All you have to do is enter the contest and try to win lots of prizes')}</p>

            <HowItWorks />

            <Stack direction={'row'} justifyContent={'flex-start'}>
                <Button variant='contained' color='secondary'
                    sx={{ fontSize: 16, fontWeight: 'bold', mt: 4, px: 4}}
                    endIcon={<ArrowForward />}
                    onClick={() => navigate()}>{t('Understood')}</Button>
            </Stack>
        </div>
    )
}

export default ProfileThanks
