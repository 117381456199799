import { ArrowForward } from '@mui/icons-material';
import { Button, Container, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next';
import { Theme } from '../../mui/Theme';
interface Props {
  onClick(): void
}
export const ContestBanner = ({ onClick }: Props) => {
  const { t } = useTranslation();
  return (
    <Container sx={{ background: Theme.palette.primary.main, pt: 4, pb: 4 }}>
      <Typography variant='h1' fontSize={{ xs: 32, sm: 42 }} lineHeight={{ xs: '24px', sm: '42px' }}>{t('Contests_PageTitle')}</Typography>
      <Typography fontSize={{ xs: 18, sm: 24 }} sx={{ mb: 2, maxWidth: { xs: '100%', sm: '70%', md: '500px' } }}>{t('Contests_PageDescription')}</Typography>

      <Button size='large' color='secondary' variant='contained' sx={{ fontSize: 14, fontWeight: 'bold' }} onClick={onClick} endIcon={<ArrowForward />}>{t('Contests_button_go_to_prizes')}</Button>

    </Container>
  )
}
