import {
    BottomNavigation,
    BottomNavigationAction,
    Button,
    Link,
    Menu,
    MenuItem,
    Typography
} from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import {Box} from '@mui/system';
import {useTranslation} from 'react-i18next';
import {useRouter} from '../../context/RouterContext';
import MenuIcon from '@mui/icons-material/Menu';
import React, {useEffect, useState} from 'react';
import {useLocation} from 'react-router-dom';
import {ReactComponent as MapIcon} from '../../icons/Icon_Map.svg'
import {ReactComponent as TrophyIcon} from '../../icons/tcs-icon_win.svg'
import {ArrowDropDown} from '@mui/icons-material';
import {fontFamilyMuseo700} from '../../mui/Theme';

interface MenuPage {
    path?: string;
    label: string;
    sublabel?: string;
}

const Footer = () => {
    const {t, i18n} = useTranslation();
    const {navigate, changeLanguage, isNoBottomMenuPage} = useRouter();

    const [anchorElNav, setAnchorElNav] = useState<null | HTMLElement>(null);
    const [anchorElLanguage, setAnchorElLanguage] = useState<null | HTMLElement>(null);

    const [selectedLanguage, setSelectedLanguage] = useState<string>(i18n.language);

    const [currentMenuItem, setCurrentMenuItem] = useState<string>('');

    const location = useLocation();

    useEffect(() => {
        const splits = location.pathname?.split('/')
        if (splits.includes('map')) {
            setCurrentMenuItem('map')
        }
        if (splits.includes('contest')) {
            setCurrentMenuItem('contest')
        }
    }, [location])


    const onClickMap = () => {
        setCurrentMenuItem('map');
        navigate('/map');
    };
    const onClickContest = () => {
        setCurrentMenuItem('contest');
        navigate('/contest');
    }

    const onClickMenuClose = () => {
        setAnchorElNav(null);
    }

    const handleClickListItem = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorElLanguage(event.currentTarget);
    };

    const handleCloseLanguageMenu = () => {
        setAnchorElLanguage(null);
    };

    const handleLanguageMenuClick = (lng: string) => {
        setSelectedLanguage(lng);
        setAnchorElLanguage(null);
        onClickMenuClose();
        changeLanguage(lng)
    };

    interface LanguageLink {
        key: string
        label: string
    }

    const supportedLngs = i18n.options.supportedLngs ? i18n.options.supportedLngs : []

    const languageOptions: LanguageLink[] = supportedLngs.reduce((init: LanguageLink[], key) => {
        let label: string
        switch (key) {
            case 'fr':
                label = "Français"
                init.push({
                    key,
                    label
                })
                break;
            case 'de':
                label = "Deutsch"
                init.push({
                    key,
                    label
                })
                break;
            case 'it':
                label = "Italiano"
                init.push({
                    key,
                    label
                })
                break;
            default:
                label = key
                break;
        }
        return init

    }, [])

    const getLanguageOption = (): LanguageLink | null => {
        return languageOptions.reduce((languageLink: LanguageLink | null, init) => {
            if (languageLink?.key === selectedLanguage) {
                return languageLink
            }
            return init;
        }, null)
    }

    const pages: MenuPage[] = [
        {
            path: t('Footer_WrongData_formURL'),
            label: t('Report wrong data'),
            sublabel: t('Missing station, incorrect fuel types, etc'),
        },
        {
            path: t('Footer_ContactSupport_formURL'),
            label: t('Contact Support'),
            sublabel: t('Technical problems, general questions, etc'),
        },
        {
            path: t('Footer_FAQ_URL'),
            label: t('Footer_FAQ_Label'),
        },
        {
            path: t('DataPrivacy_URL'),
            label: t('Data Privacy Title'),
        },
        {
            label: t('Footer_Cookie_Settings_Label'),
        },
        {
            path: t('general_conditions_link'),
            label: t('Footer_GeneralConditions_Title'),
        }
    ]

    const bottomBarClick = (event: React.SyntheticEvent, newValue: string) => {
        switch (newValue) {
            case 'map':
                onClickMap();
                break;
            case 'contest':
                onClickContest();
                break;
            case 'menu':
                break;
        }
    }

    const handleMenuClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorElNav(event.currentTarget);
    };

    const onClickCookies = () => {
        // @ts-ignore
        if (UC_UI) {
            // @ts-ignore
            UC_UI.showSecondLayer();
            onClickMenuClose();
        }
    }

    return (
        <>
            {!isNoBottomMenuPage(location.pathname) &&
                <footer className="bottomNav">
                    <BottomNavigation
                        className="bottomNav--nav"
                        showLabels
                        value={currentMenuItem}
                        onChange={bottomBarClick}
                    >
                        <BottomNavigationAction value="map" label={t('Map')} icon={<MapIcon/>}/>
                        <BottomNavigationAction value="contest" label={t('Contest')} className={"bottomNavContestIcon"}
                                                icon={<TrophyIcon/>}/>
                        <BottomNavigationAction value="menu" onClick={handleMenuClick} label={t('Menu')}
                                                icon={<MenuIcon/>}/>
                    </BottomNavigation>
                    <Menu
                        id="menu-appbar"
                        anchorEl={anchorElNav}
                        anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}
                        keepMounted
                        transformOrigin={{
                            vertical: 'bottom',
                            horizontal: 'right',
                        }}
                        open={Boolean(anchorElNav)}
                        onClose={onClickMenuClose}

                    >
                        <Box sx={{flexGrow: 1}}>
                            <MenuItem>
                                <Grid container width={'100%'} alignItems={'center'} justifyContent={'space-between'}>
                                    <Typography fontFamily={fontFamilyMuseo700}>{t('Language')}</Typography>

                                    <Button variant='outlined' color='secondary' sx={{fontWeight: 'bold'}}
                                            endIcon={<ArrowDropDown/>} onClick={handleClickListItem}>
                                        {getLanguageOption()?.label.substring(0, 2)}
                                    </Button>
                                    <Menu
                                        id="lock-menu"
                                        anchorEl={anchorElLanguage}
                                        anchorOrigin={{
                                            vertical: 'bottom',
                                            horizontal: 'right',
                                        }}
                                        transformOrigin={{
                                            vertical: 'top',
                                            horizontal: 'right',
                                        }}
                                        open={Boolean(anchorElLanguage)}
                                        onClose={handleCloseLanguageMenu}
                                        MenuListProps={{
                                            'aria-labelledby': 'lock-button',
                                            role: 'listbox',
                                        }}
                                    >
                                        {(languageOptions)?.map((option: LanguageLink) => (
                                            <MenuItem
                                                key={option.key}
                                                selected={option.key === selectedLanguage}
                                                onClick={() => handleLanguageMenuClick(option.key)}
                                            >
                                                {option.label}
                                            </MenuItem>
                                        ))}
                                    </Menu>
                                </Grid>
                            </MenuItem>
                            {pages.map((page, index) => (
                                <MenuItem
                                    key={index}
                                    component={Link}
                                    {...(page.path ? {href: page.path} : {})}
                                    onClick={
                                        () => {
                                            if (page.label === t('Footer_Cookie_Settings_Label')) {
                                                onClickCookies()
                                            } else {
                                                onClickMenuClose()
                                            }
                                        }
                                    }
                                    color='secondary'
                                    sx={{borderTop: 1, borderColor: "grey.300"}}
                                >
                                    <Box>
                                        <Typography fontFamily={fontFamilyMuseo700}>{page.label}</Typography>
                                        {page?.sublabel && (<Typography variant='body2'>{page.sublabel}</Typography>)}
                                    </Box>
                                </MenuItem>
                            ))}
                        </Box>
                    </Menu>
                </footer>
            }
        </>
    )
}

export default Footer
