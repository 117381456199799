import { DocumentData, QueryDocumentSnapshot, SnapshotOptions, WithFieldValue } from "firebase/firestore";
import { PublicProfile } from "../model/PublicProfile";
import UserAvatarConverter from "./UserAvatarConverter";

export const PublicProfileConverter = {
  toFirestore(publicProfile: WithFieldValue<PublicProfile>): DocumentData {
    return PublicProfileConverter.toData(publicProfile);
  },
  toData(data:any){
    const backData = { ...data }
    if (data?.isDeleted === undefined) {
      delete backData.isDeleted;
    }
    return backData as PublicProfile
  },
  fromFirestore(
    snapshot: QueryDocumentSnapshot,
    options: SnapshotOptions
  ): PublicProfile {
    const data = snapshot.data(options)!;
    return PublicProfileConverter.fromData(data);
  },
  fromData(data: any): PublicProfile{
    return { ...data,avatar:UserAvatarConverter.fromData(data.avatar) } as PublicProfile
  }
}
export default PublicProfileConverter
