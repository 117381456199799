import {ContestPrize} from "../../utils/model/ContestPrize";
import {Box, Card, CardMedia, Typography} from "@mui/material";
import {tcsBlueGray1} from "../../mui/Theme";
import {Stack} from "@mui/system";
import React from "react";
import {useTranslation} from "react-i18next";
import {langFromi18nLang} from "../../utils/helpers/Lang";

interface Props {
    contestPrize: ContestPrize
}

export default function WonPrizeItem({ contestPrize }: Props) {
    const { t, i18n } = useTranslation();
    const lang = langFromi18nLang(i18n.language);

    return(
        <>
            <Card sx={{
                height: '100%',
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                borderRadius: 2
            }}>
                <Box>
                    <Box sx={{overflow: 'hidden'}}>
                        <CardMedia
                            component="img"
                            height="125px"
                            image={contestPrize.imageSrc}
                            alt={contestPrize[`title_${lang}`]}
                            className={"imageZoom"}
                            loading="lazy"
                            sx={{background: tcsBlueGray1, objectFit: 'cover'}}
                        />
                    </Box>

                    <Box bgcolor={tcsBlueGray1} px={{xs: 1, sm: 1.5}} py={1} flexGrow={0}>
                        <Typography variant="h4" fontSize={14}>{contestPrize[`title_${lang}`]}</Typography>
                    </Box>

                    <Stack px={{xs: 1, sm: 1.5}} py={1} direction={'row'} justifyContent='space-between' gap={1}>
                        <div>
                            <Typography variant="body1" fontSize={{
                                xs: 12,
                                sm: 14
                            }}> {t("Value")} {t("CHF")}&nbsp;{contestPrize.value}</Typography>
                        </div>

                        {contestPrize.sponsorImageSrc &&
                            <img width={32} height={32} src={contestPrize.sponsorImageSrc} alt={contestPrize.sponsorName + "_image"}/>
                        }
                    </Stack>
                </Box>
            </Card>
        </>
    )
}