import { Fuel } from "../model/Fuel";
import { FuelType } from "../model/FuelType";
import { Profile } from "../model/Profile";
import { Station } from "../model/Station";
import { getDistance } from "./Distance";
import {UserType} from "../model/UserType";

export const displayPriceToFixed = (price:number|string) => (+price).toFixed(2)
export const displayPriceToNumber = (price:number|string) => (+price)

const MIN_DISTANCE = 200;
const TIME_IN_MINUTES_BEFORE_NEXT_SUBMIT = 360; // 6h
const TIME_IN_MINUTES_DISPLAY_OWN_PRICE = 60;
const TIME_IN_MINUTES_BEFORE_NEXT_CORRECTION = 5;

export const canParticipate = (coords: any, profile: Profile | null, station: Station) => {
    if (coords && profile?.type) {
        if (String(profile.type) === UserType.ADMIN || String(profile.type) === UserType.EMPLOYEE) {
            return true;
        }
        const location = {lat: coords.latitude, lng: coords.longitude};
        if (getDistance(station.location, location) <= MIN_DISTANCE) {
            return true;
        }
    }
    return false;
}

export const canContribute = ({ station, fuelType, profile }: { station: Station, fuelType: FuelType, profile: Profile | null }): boolean => {
  // if admin return true
  if (profile?.type === UserType.ADMIN) return true;
  const date = localStorage.getItem(`${station.id}-${fuelType}-participate`)
  // if no date found retrun true
  if (date === null) return true;
  const then = new Date(date);
  const now = new Date();
  const msBetweenDates = Math.abs(then.getTime() - now.getTime());
  // date found but under 24h return false
  return (msBetweenDates < TIME_IN_MINUTES_BEFORE_NEXT_SUBMIT * 60 * 1000) ? false : true
}

export const canCorrect = ({ station, fuelType }: { station: Station, fuelType: FuelType }): boolean => {
    const date = localStorage.getItem(`${station.id}-${fuelType}-correction`);
    if (date === "" || date === null) {
        return false;
    }
    const then = new Date(date);
    const now = new Date();
    const msBetweenDates = Math.abs(then.getTime() - now.getTime());
    return (msBetweenDates < TIME_IN_MINUTES_BEFORE_NEXT_CORRECTION * 60 * 1000) ? true : false;
}

export const getDisplayPrice = ({ station, fuelType, fuel }: { station: Station, fuelType: FuelType, fuel: Fuel }): string => {

  const livePrice = fuel.displayPrice
  const storageDate = localStorage.getItem(`${station.id}-${fuelType}-price-participate`)
  const storagePrice = localStorage.getItem(`${station.id}-${fuelType}-price`)

  if (storageDate === null || storagePrice === null) return displayPriceToFixed(livePrice);
  const then = new Date(storageDate);
  const now = new Date();
  const msBetweenDates = Math.abs(then.getTime() - now.getTime());
  // date found but over 1h return true
  return (msBetweenDates > TIME_IN_MINUTES_DISPLAY_OWN_PRICE * 60 * 1000) ? displayPriceToFixed(livePrice) : displayPriceToFixed(storagePrice)
}
export const nextParticipation = ({ station, fuelType }: { station: Station, fuelType: FuelType }): string => {

  const date = localStorage.getItem(`${station.id}-${fuelType}-participate`)

  // No local date for fuel
  if (date === null) return '';


  const nextUpdate = new Date(date).getTime() + TIME_IN_MINUTES_BEFORE_NEXT_SUBMIT * 60 * 1000;
  const now = new Date().getTime();

  // Date found but over 24h return empty string
  if (nextUpdate < now) return "";

  // Calulate remaining time.
  const hours = Math.floor((nextUpdate - now) / 1000 / 60 / 60) % 24
  const minutes = Math.floor((nextUpdate - now) / 1000 / 60) % 60

  return hours.toString() + "h " + minutes.toString() + "min"
}

