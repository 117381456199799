
/* 
Returns RelativeTimeSpec format from moment.js.
Allows to override the relative time translation, for shorter meaning
*/
export function momentRelativeTimeTranslationsForLocale(locale: string) {
    switch (locale.toLowerCase()) {
        case "fr":
            return {
                relativeTime: {
                    future: 'dans %s',
                    past: 'il y a %s',
                    s: '< 1 min',
                    ss: '< 1 min',
                    m: '1 min',
                    mm: '%d min',
                    h: '1 heure',
                    hh: '%d heures',
                    d: '1 jour',
                    dd: '%d jours',
                    M: 'un mois',
                    MM: '%dM',
                    y: 'un an',
                    yy: '%dY'
                }
            }
        case "de":
            return {}
        case "it":
            return {}
    }

    return undefined;
}