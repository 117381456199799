import { FiabilityLevel } from "../../utils/model/FiabilityLevel";
import { GppBad, NewReleases, Verified } from '@mui/icons-material';

interface Props {
  fiabilityLevel: FiabilityLevel | undefined
  iconSize: number | undefined
}
const FiabilityIcon = ({ fiabilityLevel, iconSize }: Props) => {
  const sharedSx = {
    '&.MuiSvgIcon-root': {
      width: iconSize + "px",
      height: iconSize + "px",
    }
  }

  switch (fiabilityLevel) {
    case FiabilityLevel.CONFIDENT:
      return <Verified fontSize='small' color="success" sx={sharedSx} />
    case FiabilityLevel.FEW_RECENT_PRICES:
      return <NewReleases fontSize='small' color='warning' sx={sharedSx} />
    case FiabilityLevel.OLD_LAST_UPDATE:
      return <NewReleases fontSize='small' color='warning' sx={sharedSx} />
    case FiabilityLevel.OUTDATED_LAST_PRICE_UPDATE:
      return <GppBad fontSize='small' color='error' sx={sharedSx} />
  }

  return null;
}

export default FiabilityIcon
