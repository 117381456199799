export enum FuelType {
    SP95 = "SP95",
    SP98 = "SP98",
    DIESEL = "DIESEL",
    DIESEL_PREMIUM = "DIESEL_PREMIUM",
    GPL = "GPL",
    ADBLUE = "ADBLUE",
    CNG = "CNG",
    E85 = "E85"
}
export const FuelTypeValues = Object.values(FuelType)
