import React, {useState} from "react";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    InputAdornment,
    InputLabel,
    Stack,
    TextField
} from "@mui/material";
import {ContestPrize} from "../../utils/model/ContestPrize";
import {v4 as uuidv4} from 'uuid';

interface Props {
    initialContestPrize?: ContestPrize;
    onClose(...args: any): void,
    onAdd(
        e: React.MouseEvent<HTMLElement>,
        contestPrize: ContestPrize,
        image: File | null,
        sponsor: File | null
    ): void,
    onUpdate(
        e: React.MouseEvent<HTMLElement>,
        contestPrize: ContestPrize,
        image: File | null,
        sponsor: File | null
    ): void
}

export default function AdminContestPrizeDialog({onClose, onAdd, onUpdate, initialContestPrize}: Props) {
    const [image, setImage] = useState<File | null>(null);
    const [sponsorImage, setSponsorImage] = useState<File | null>(null);

    const [contestPrize, setContestPrize] = useState<ContestPrize>({
        id: initialContestPrize?.id || uuidv4(),
        order: initialContestPrize?.order || 1,
        contestId: "contestId-placeholder",
        title_DE: initialContestPrize?.title_DE || "",
        title_FR: initialContestPrize?.title_FR || "",
        title_IT: initialContestPrize?.title_IT || "",
        description_DE: initialContestPrize?.description_DE || "",
        description_FR: initialContestPrize?.description_FR || "",
        description_IT: initialContestPrize?.description_IT || "",
        numberOfPrizes: initialContestPrize?.numberOfPrizes || 0,
        value: initialContestPrize?.value || "",
        imageSrc: initialContestPrize?.imageSrc || "",
        sponsorImageSrc: initialContestPrize?.sponsorImageSrc || "",
        sponsorURL_DE: initialContestPrize?.sponsorURL_DE || "",
        sponsorURL_FR: initialContestPrize?.sponsorURL_FR || "",
        sponsorURL_IT: initialContestPrize?.sponsorURL_IT || "",
        totalUsedTokens: initialContestPrize?.totalUsedTokens || 0,
        wonPrizeIds: initialContestPrize?.wonPrizeIds || [],
        winnerIds: initialContestPrize?.winnerIds || [],
        isNotWinnableMultipleTimes: initialContestPrize?.isNotWinnableMultipleTimes || true
    });

    const [titleFrError, setTitleFrError] = useState(false);
    const [titleDeError, setTitleDeError] = useState(false);
    const [titleItError, setTitleItError] = useState(false);

    const [descriptionFrError, setDescriptionFrError] = useState(false);
    const [descriptionDeError, setDescriptionDeError] = useState(false);
    const [descriptionItError, setDescriptionItError] = useState(false);

    const [valueError, setValueError] = useState(false);
    const [numberOfPrizesError, setNumberOfPrizesError] = useState(false);

    const [imageSrcError, setImageSrcError] = useState(false);

    const [sponsorImageSrcError, setSponsorImageSrcError] = useState(false);
    const [sponsorURLDeError, setSponsorURLDeError] = useState(false);
    const [sponsorURLFrError, setSponsorURLFrError] = useState(false);
    const [sponsorURLItError, setSponsorURLItError] = useState(false);

    const handleImageUpload = (event: React.ChangeEvent) => {
        const target = event.target as HTMLInputElement;
        if (target.files && target.files.length > 0) {
            const file = target.files[0];
            setImage(file);
        }
    };

    const handleSponsorUpload = (event: React.ChangeEvent) => {
        const target = event.target as HTMLInputElement;
        if (target.files && target.files.length > 0) {
            const file = target.files[0];
            setSponsorImage(file);
        }
    };

    const handleSave = (e: React.MouseEvent<HTMLElement>) => {
        if (contestPrize.title_FR === '') {
            setTitleFrError(true);
        }

        if (contestPrize.title_DE === '') {
            setTitleDeError(true);
        }

        if (contestPrize.title_IT === '') {
            setTitleItError(true);
        }

        if (contestPrize.description_FR === '') {
            setDescriptionFrError(true);
        }

        if (contestPrize.description_DE === '') {
            setDescriptionDeError(true);
        }

        if (contestPrize.description_IT === '') {
            setDescriptionItError(true);
        }

        if (contestPrize.sponsorURL_DE === '') {
            setSponsorURLDeError(true);
        }

        if (contestPrize.sponsorURL_FR === '') {
            setSponsorURLFrError(true);
        }

        if (contestPrize.sponsorURL_IT === '') {
            setSponsorURLItError(true);
        }

        if (contestPrize.numberOfPrizes <= 0) {
            setNumberOfPrizesError(true);
        }

        if (contestPrize.value === '') {
            setValueError(true);
        }

        if (image === null || image.type !== "image/webp") {
            setImageSrcError(true);
        }

        if (sponsorImage === null || sponsorImage.type !== "image/webp") {
            setSponsorImageSrcError(true);
        }

        if (contestPrize.title_FR !== ''
            && contestPrize.title_DE !== ''
            && contestPrize.title_IT !== ''
            && contestPrize.description_FR !== ''
            && contestPrize.description_DE !== ''
            && contestPrize.description_IT !== ''
            && contestPrize.sponsorURL_DE !== ''
            && contestPrize.sponsorURL_FR !== ''
            && contestPrize.sponsorURL_IT !== ''
            && contestPrize.value !== '') {
            if (initialContestPrize) {
                onUpdate(e, contestPrize, image, sponsorImage);
            } else {
                onAdd(e, contestPrize, image, sponsorImage);
            }
        }
    };

    const handleChange = (
        e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
        field: keyof ContestPrize
    ) => {
        setContestPrize((prevContestPrize) => ({
            ...prevContestPrize,
            [field]: e.target.value
        }));
    };

    return (
        <Dialog open={true}>
            <DialogContent>
                <Stack sx={{
                    display: "flex",
                    flexDirection: "column"
                }}>
                    <TextField
                        sx={{mb: 2}}
                        value={contestPrize.order}
                        label="Order"
                        variant="outlined"
                        type="number"
                        required
                        InputProps={{
                            inputProps: {
                                max: 100, min: 0
                            }
                        }}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        helperText="Between 1 and X, order is used to sorted the prize in the list."
                        onChange={(e: any) =>
                            handleChange(e, 'order')
                        }
                    />
                    <TextField
                        sx={{mb: 2}}
                        value={contestPrize.title_DE}
                        label="Title DE"
                        variant="outlined"
                        required
                        error={titleDeError}
                        onChange={(e: any) =>
                            handleChange(e, 'title_DE')}
                    />
                    <TextField
                        sx={{mb: 2}}
                        value={contestPrize.title_FR}
                        label="Title FR"
                        variant="outlined"
                        required
                        error={titleFrError}
                        onChange={(e: any) =>
                            handleChange(e, 'title_FR')}
                    />
                    <TextField
                        sx={{mb: 2}}
                        value={contestPrize.title_IT}
                        label="Title IT"
                        variant="outlined"
                        required
                        error={titleItError}
                        onChange={(e: any) =>
                            handleChange(e, 'title_IT')}
                    />
                    <TextField
                        sx={{mb: 2}}
                        value={contestPrize.description_DE}
                        label="Description DE"
                        variant="outlined"
                        multiline
                        rows={2}
                        required
                        error={descriptionDeError}
                        onChange={(e: any) =>
                            handleChange(e, 'description_DE')}
                    />
                    <TextField
                        sx={{mb: 2}}
                        value={contestPrize.description_FR}
                        label="Description FR"
                        variant="outlined"
                        multiline
                        rows={2}
                        required
                        error={descriptionFrError}
                        onChange={(e: any) =>
                            handleChange(e, 'description_FR')}
                    />
                    <TextField
                        sx={{mb: 2}}
                        value={contestPrize.description_IT}
                        label="Description IT"
                        variant="outlined"
                        multiline
                        rows={2}
                        required
                        error={descriptionItError}
                        onChange={(e: any) =>
                            handleChange(e, 'description_IT')}
                    />
                    <TextField
                        sx={{mb: 2}}
                        value={contestPrize.value}
                        label="Value"
                        required
                        error={valueError}
                        InputProps={{
                            startAdornment: <InputAdornment position="start">CHF</InputAdornment>,
                        }}
                        onChange={(e: any) =>
                            handleChange(e, 'value')}
                    />
                    <TextField
                        sx={{mb: 2}}
                        value={contestPrize.numberOfPrizes}
                        label="Number of prizes"
                        type="number"
                        required
                        error={numberOfPrizesError}
                        helperText={numberOfPrizesError ? "Invalid input: A positive number is required." : ""}
                        InputProps={{
                            inputProps: {
                                max: 999, min: 1
                            }
                        }}
                        onChange={(e: any) =>
                            handleChange(e, 'numberOfPrizes')}
                    />
                    <InputLabel shrink htmlFor="image-input">
                        Image*
                    </InputLabel>
                    <TextField
                        sx={{mb: 2}}
                        inputProps={{accept: "image/webp"}}
                        type="file"
                        required
                        error={imageSrcError}
                        onChange={handleImageUpload}
                        id="image-input"
                        helperText="Upload .webp format only. Use an online converter if necessary."
                    />
                    {contestPrize.imageSrc &&
                        <img alt="Main" style={{marginBottom: 20}} src={contestPrize.imageSrc}/>
                    }

                    <InputLabel shrink htmlFor="sponsor-input">
                        Sponsor*
                    </InputLabel>
                    <TextField
                        sx={{mb: 2}}
                        inputProps={{accept: "image/webp"}}
                        type="file"
                        required
                        error={sponsorImageSrcError}
                        onChange={handleSponsorUpload}
                        id="sponsor-input"
                        helperText="Upload .webp format only. Use an online converter if necessary."
                    />
                    {contestPrize.sponsorImageSrc &&
                        <img alt="Sponsor" style={{marginBottom: 20}} src={contestPrize.sponsorImageSrc}/>
                    }

                    <TextField
                        sx={{mb: 2}}
                        value={contestPrize.sponsorURL_DE}
                        label="Sponsor URL DE"
                        variant="outlined"
                        required
                        error={sponsorURLDeError}
                        onChange={(e: any) =>
                            handleChange(e, 'sponsorURL_DE')}
                    />
                    <TextField
                        sx={{mb: 2}}
                        value={contestPrize.sponsorURL_FR}
                        label="Sponsor URL FR"
                        variant="outlined"
                        required
                        error={sponsorURLFrError}
                        onChange={(e: any) =>
                            handleChange(e, 'sponsorURL_FR')}
                    />
                    <TextField
                        sx={{mb: 2}}
                        value={contestPrize.sponsorURL_IT}
                        label="Sponsor URL IT"
                        variant="outlined"
                        required
                        error={sponsorURLItError}
                        onChange={(e: any) =>
                            handleChange(e, 'sponsorURL_IT')}
                    />
                </Stack>
            </DialogContent>
            <DialogActions>
                <Button variant="outlined" color="secondary" sx={{fontSize: 12}} onClick={onClose}>Close</Button>
                <Button variant="contained" color="secondary" sx={{fontSize: 12}} onClick={handleSave}>Save</Button>
            </DialogActions>
        </Dialog>
    )
}