import {Button, Stack, TextField} from "@mui/material";
import {tcsBlueGray1} from "../../mui/Theme";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import {Brand} from "../../utils/model/Station";
import MenuItem from "@mui/material/MenuItem";
import React, {useState} from "react";
import {StationAdminDTO} from "../../utils/model/StationAdminDTO";
import {createStationAdmin} from "../../utils/Api";
import DialogLoader from "../common/DialogLoader";
import ErrorResponseDialog from "../station/components/ErrorResponseDialog";
import OperationAdminDialog from "./OperationAdminDialog";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import {useNavigate} from "react-router-dom";

export default function AdminCreateStation() {
    const navigate = useNavigate();

    const [displayName, setDisplayName] = useState<string>();
    const [formattedAddress, setFormattedAddress] = useState<string>();
    const [brand, setBrand] = useState<string>();
    const [latitude, setLatitude] = useState<number>();
    const [longitude, setLongitude] = useState<number>();

    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState<TemplateStringsArray | undefined>();
    const [resultResponse, setResultResponse] = useState<string | undefined>(undefined);

    const [displayNameError, setDisplayNameError] = useState(false);
    const [formattedAddressError, setFormattedAddressError] = useState(false);
    const [latitudeError, setLatitudeError] = useState(false);
    const [longitudeError, setLongitudeError] = useState(false);

    const handleSaveStation = async () => {
        setIsLoading(true);

        if (!displayName) {
            setDisplayNameError(true);
        }

        if (!formattedAddress) {
            setFormattedAddressError(true);
        }

        if (!latitude) {
            setLatitudeError(true);
        }

        if (!longitude) {
            setLongitudeError(true);
        }

        if (displayName !== ''
            && brand !== ''
            && formattedAddress !== ''
            && latitude
            && longitude)
            try {
                const stationDto: StationAdminDTO = {
                    displayName: displayName,
                    brand: brand,
                    formattedAddress: formattedAddress,
                    location: {
                        lat: latitude,
                        lng: longitude
                    }
                }
                const result = await createStationAdmin(stationDto);
                setResultResponse(result.data.message);
            } catch (error: any) {
                setError(error.message);
            }

        setIsLoading(false);
    }

    const handleChangeDisplayName = (title: string) => {
        setDisplayName(title);
        if (title !== "") {
            setDisplayNameError(false);
        }
    }

    const handleChangeFormattedAddress = (address: string) => {
        setFormattedAddress(address);
        if (address !== "") {
            setFormattedAddressError(false);
        }
    }

    const handleChangeLatitude = (latitude: string) => {
        setLatitude(parseFloat(latitude) || 0);
        if (latitude !== "") {
            setLatitudeError(false);
        }
    }

    const handleChangeLongitude = (longitude: string) => {
        setLongitude(parseFloat(longitude) || 0);
        if (longitude !== "") {
            setLongitudeError(false);
        }
    }

    const handleChangeBrand = (brand: string) => {
        setBrand(brand);
    }

    return (
        <>
            {isLoading &&
                <DialogLoader loading={isLoading} onClose={() => setIsLoading(!isLoading)}/>
            }
            {error &&
                <ErrorResponseDialog errorCode={error} onClose={() => {
                    setError(undefined);
                }}/>
            }
            {resultResponse &&
                <OperationAdminDialog message={resultResponse} onClose={() => setResultResponse(undefined)}/>
            }
            <Stack direction='column' height={'100%'}>
                <Stack sx={{
                    display: "flex",
                    flexDirection: "column",
                    borderRadius: '5px',
                    background: tcsBlueGray1,
                    gap: 2,
                    padding: 2,
                    margin: 2
                }}>
                    <TextField
                        label="Display name"
                        variant="outlined"
                        required
                        error={displayNameError}
                        onChange={(e: any) =>
                            handleChangeDisplayName(e.target.value)}
                    />
                    <TextField
                        label="Formatted address"
                        variant="outlined"
                        required
                        error={formattedAddressError}
                        onChange={(e: any) =>
                            handleChangeFormattedAddress(e.target.value)}
                    />
                    <InputLabel id="brand-label">Brand</InputLabel>
                    <Select
                        labelId="brand-label"
                        label="Brand"
                        value={Brand.UNDEFINED}
                        onChange={(e: any) =>
                            handleChangeBrand(e.target.value)}
                    >
                        {Object.values(Brand).map((brand) => (
                            <MenuItem key={brand} value={brand}>{brand}</MenuItem>
                        ))}
                    </Select>
                    <h4>Location</h4>
                    <Stack sx={{
                        display: "flex",
                        flexDirection: "row",
                        gap: 2
                    }}>
                        <TextField
                            type="number"
                            label="Latitude"
                            variant="outlined"
                            required
                            error={latitudeError}
                            onChange={(e: any) =>
                                handleChangeLatitude(e.target.value)}
                        />
                        <TextField
                            type="number"
                            label="Longitude"
                            variant="outlined"
                            required
                            error={longitudeError}
                            onChange={(e: any) =>
                                handleChangeLongitude(e.target.value)}
                        />
                    </Stack>

                    <Stack sx={{
                        flexDirection: "row",
                        gap: 1
                    }}>
                        <Button
                            variant="contained"
                            type="button"
                            onClick={() => navigate(-1)}
                        >
                            <ArrowBackIcon />
                        </Button>
                        <Button
                            color="secondary"
                            variant="contained"
                            onClick={handleSaveStation}
                        >
                            Save
                        </Button>
                    </Stack>
                </Stack>
            </Stack>
        </>
    )
}