import React, {useEffect, useMemo, useState} from "react";
import {sendOperationUserAdmin} from "../../utils/Api";
import {Profile} from "../../utils/model/Profile";
import {
    CircularProgress,
    Stack,
    Avatar,
    TablePagination,
    Table,
    TableHead,
    TableRow,
    TableCell, TableBody, TableContainer
} from "@mui/material";
import ErrorResponseDialog from "../station/components/ErrorResponseDialog";
import Button from "@mui/material/Button";
import OperationAdminDialog from "./OperationAdminDialog";
import {AvatarIcon} from "../../utils/assets/AvatarIcon";
import {useProfile} from "../../context/ProfileContext";
import {
    collection, endBefore,
    getCountFromServer,
    getDocs,
    limit, limitToLast,
    orderBy,
    query,
    QueryDocumentSnapshot, startAfter,
    where
} from "firebase/firestore";
import {firestore} from "../../config/firebase";
import ProfileConverter from "../../utils/converters/ProfileConverter";
import {UserType} from "../../utils/model/UserType";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import {useNavigate} from "react-router-dom";
import {Query} from "@firebase/firestore";
import SearchIcon from '@mui/icons-material/Search';

export default function AdminBannedUsers() {
    const {printDisplayName} = useProfile();
    const [users, setUsers] = useState<Profile[]>([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState<TemplateStringsArray | undefined>();
    const [resultResponse, setResultResponse] = useState<string | undefined>(undefined);
    const navigate = useNavigate();
    const {profile} = useProfile();

    const [page, setPage] = useState(0);
    const [count, setCount] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);

    const [lastVisibleSnapshot, setLastVisibleSnapshot] = useState<QueryDocumentSnapshot | undefined>();
    const [firstVisibleSnapshot, setFirstVisibleSnapshot] = useState<QueryDocumentSnapshot | undefined>();

    const memoizedGetBannedUsersAdmin = useMemo(() => {
        return async () => {
            setUsers([]);
            setLoading(true);
            setPage(0);
            try {
                const queryTotalUsers = query(
                    collection(firestore, "profiles").withConverter(ProfileConverter),
                    where("type", "==", UserType.SUSPECT)
                );

                const totalUsers = await getCountFromServer(queryTotalUsers);
                setCount(totalUsers.data().count);

                const queryUsers = query(
                    collection(firestore, "profiles").withConverter(ProfileConverter),
                    where("type", "==", UserType.SUSPECT),
                    orderBy("publicProfile.displayName", "asc"),
                    limit(rowsPerPage)
                );

                const usersSnapshot = await getDocs(queryUsers);
                const users = usersSnapshot.docs.map(profile => profile.data()) as Profile[];
                setUsers(users);
                setLastVisibleSnapshot(usersSnapshot.docs[usersSnapshot.docs.length - 1]);
                setFirstVisibleSnapshot(usersSnapshot.docs[0]);
            } catch (error: any) {
                setError(error.message);
            }
            setLoading(false);
        }
    }, [rowsPerPage]);

    const onSendOperationAdmin = async (e: React.MouseEvent<HTMLElement>, operationId: number, userId: string) => {
        setLoading(true);
        try {
            const result = await sendOperationUserAdmin(userId, operationId);
            setResultResponse(result.data.message);
        } catch (error: any) {
            setError(error.message);
        }
        setLoading(false);
    }

    useEffect(() => {
        memoizedGetBannedUsersAdmin();
    }, [memoizedGetBannedUsersAdmin]);

    const handleChangeRowsPerPage = async (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    ) => {
        setLoading(true);
        setRowsPerPage(parseInt(event.target.value));
        setPage(0);
        try {
            const queryStations = query(
                collection(firestore, "profiles").withConverter(ProfileConverter),
                where("type", "==", UserType.SUSPECT),
                orderBy("publicProfile.displayName", "asc"),
                limit(parseInt(event.target.value))
            );
            const profilesSnapshot = await getDocs(queryStations);
            const profiles = profilesSnapshot.docs.map(profile => profile.data()) as Profile[];

            setUsers(profiles);
            setLastVisibleSnapshot(profilesSnapshot.docs[profilesSnapshot.docs.length - 1]);
        } catch (error: any) {
            setError(error.message);
        }
        setLoading(false);
    };

    const onPageChanged = async (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
        setLoading(true);
        setPage(newPage);
        try {
            let queryUsers: Query;
            if (newPage > page) {
                queryUsers = query(
                    collection(firestore, "profiles").withConverter(ProfileConverter),
                    where("type", "==", UserType.SUSPECT),
                    orderBy("publicProfile.displayName", "asc"),
                    startAfter(lastVisibleSnapshot),
                    limit(rowsPerPage)
                );
            } else {
                queryUsers = query(
                    collection(firestore, "profiles").withConverter(ProfileConverter),
                    where("type", "==", UserType.SUSPECT),
                    orderBy("publicProfile.displayName", "asc"),
                    endBefore(firstVisibleSnapshot),
                    limitToLast(rowsPerPage)
                );
            }

            const usersSnapshot = await getDocs(queryUsers);
            const profiles = usersSnapshot.docs.map(profile => profile.data()) as Profile[];
            setUsers(profiles);
            setLastVisibleSnapshot(usersSnapshot.docs[usersSnapshot.docs.length - 1]);
            setFirstVisibleSnapshot(usersSnapshot.docs[0]);
        } catch (error: any) {
            setError(error.message);
        }
        setLoading(false);
    }

    return (
        <>
            {resultResponse &&
                <OperationAdminDialog message={resultResponse} onClose={() => setResultResponse(undefined)}/>
            }
            {error &&
                <ErrorResponseDialog errorCode={error} onClose={() => {
                    setError(undefined);
                }}/>
            }
            <Stack direction='column' height={'100%'} sx={{
                    padding: "1rem"
                }}>
                {loading &&
                    <CircularProgress
                        color="secondary"
                        size={64}
                        disableShrink
                        thickness={4}
                        sx={{
                            margin: "auto",
                        }}
                    />
                }
                {!loading && users &&
                    <Stack direction='column' gap={1} marginBottom={1}>
                        <TableContainer>
                            <Table size="small">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Avatar</TableCell>
                                        <TableCell align="right">Id</TableCell>
                                        <TableCell align="right">TCS Ref</TableCell>
                                        <TableCell align="right">Display Name</TableCell>
                                        <TableCell align="right">Ban Reason</TableCell>
                                        <TableCell align="right">Actions</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {users.map((user: Profile) => {
                                        return <TableRow key={user.id} sx={{
                                            '&:last-child td, &:last-child th': {border: 0},
                                        }}>
                                            <TableCell component="th" scope="row">
                                                <Avatar sizes="small" alt={printDisplayName(user.publicProfile)}
                                                        src={AvatarIcon.forAvatar(user.publicProfile?.avatar)}/>
                                            </TableCell>
                                            <TableCell align="right">{user.id}</TableCell>
                                            <TableCell align="right">{user.tcsRef}</TableCell>
                                            <TableCell align="right">{user.publicProfile.displayName}</TableCell>
                                            <TableCell align="right">{user.banReason ?? 'UNDEFINED'}</TableCell>
                                            <TableCell align="right">
                                                <Stack direction={'row'} gap={1} justifyContent={"flex-end"}>
                                                    {profile?.type === UserType.ADMIN &&
                                                        <Button
                                                            variant={'outlined'}
                                                            color={'error'}
                                                            onClick={(e) => onSendOperationAdmin(e, 3, user.id)}>
                                                            Unban
                                                        </Button>
                                                    }
                                                    <Button
                                                        sx={{
                                                            borderStyle: "solid",
                                                            borderWidth: "1px",
                                                            minWidth: "auto"
                                                        }}
                                                        color={'warning'}
                                                        onClick={() => navigate("/admin/user/" + user.id)}>
                                                        <SearchIcon/>
                                                    </Button>
                                                </Stack>
                                            </TableCell>
                                        </TableRow>
                                    })}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <TablePagination
                            component="div"
                            count={count}
                            page={page}
                            onPageChange={onPageChanged}
                            rowsPerPage={rowsPerPage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                    </Stack>
                }
                <Stack sx={{
                    flexDirection: "row",
                    gap: 1
                }}>
                    <Button
                        variant="contained"
                        type="button"
                        onClick={() => navigate(-1)}
                    >
                        <ArrowBackIcon />
                    </Button>
                </Stack>
            </Stack>
        </>
    )
}