import {Box, Card, CardMedia, Typography} from "@mui/material";
import {useTranslation} from "react-i18next";
import {useProfile} from "../../../context/ProfileContext";
import React, {useState} from "react";
import ContestPrizeDetails from "./ContestPrizeDetails";
import UseTokensDialog from "./UseTokensDialog";
import {useRouter} from "../../../context/RouterContext";
import {Contest, isContestActive} from "../../../utils/model/Contest";
import {tcsBlueGray1, Theme} from "../../../mui/Theme";
import {Stack} from "@mui/system";
import {PrizeWithUserEntries, useContests} from "../../../context/ContestContext";
import {langFromi18nLang} from "../../../utils/helpers/Lang";
import {PrizeStats} from "./PrizeStats";
import {PrizeButtons} from "./PrizeButtons";
import WinnersDialog from "./WinnersDialog";

interface Props {
    contest: Contest,
    prize: PrizeWithUserEntries,
    preview?: boolean
}

const ContestPrizeItem = ({contest, prize, preview = false}: Props) => {
    const {t, i18n} = useTranslation();
    const lang = langFromi18nLang(i18n.language)
    const [showContestPrizeDetail, setShowContestDetail] = useState<boolean>(false)
    const [showAddTokensDialog, setShowAddTokensDialog] = useState<boolean>(false)
    const [showWinnersDialog, setShowWinnersDialog] = useState<boolean>(false);

    const {profile} = useProfile();
    const {navigate} = useRouter()
    const {addLotteryTickets} = useContests()

    const isActive = isContestActive(contest)

    const goToLogin = () => {
        navigate('/login')
    }

    return (
        <>
            <Card sx={{
                height: '100%',
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                borderRadius: 2
            }}>
                <Box className={'pointer'} onClick={() => setShowContestDetail(true)}>
                    {/* Main image (NB the overflow hidden in parent container) */}
                    <Box sx={{overflow: 'hidden'}}>
                        <CardMedia
                            component="img"
                            height="125px"
                            image={prize.imageSrc}
                            alt={prize[`title_${lang}`]}
                            className={"imageZoom"}
                            loading="lazy"
                            sx={{background: tcsBlueGray1, objectFit: 'cover'}}
                        />
                    </Box>

                    <Box bgcolor={tcsBlueGray1} px={{xs: 1, sm: 1.5}} py={1} flexGrow={0}>
                        <Typography variant="h4" fontSize={14}>{prize[`title_${lang}`]}</Typography>
                    </Box>

                    {/* Title and sponsor */}
                    <Stack px={{xs: 1, sm: 1.5}} py={1} direction={'row'} justifyContent='space-between' gap={1}>
                        <div>
                            <Typography variant="body1" fontSize={{
                                xs: 12,
                                sm: 14
                            }}> {t("Value")} {t("CHF")}&nbsp;{prize.value}</Typography>
                            <Typography variant="body1Bold" color={Theme.palette.success.main}
                                        fontSize={{xs: 12, sm: 14}} lineHeight={1}
                                        sx={{p: 0}}>{t('Contests_NumberOfPrizes', {numberOfPrizes: prize.numberOfPrizes})}</Typography>
                        </div>

                        {prize.sponsorImageSrc &&
                            <img width={32} height={32} src={prize.sponsorImageSrc} alt={prize.sponsorName + "_image"}/>
                        }
                    </Stack>
                </Box>

                <Stack px={{xs: 1, sm: 1.5}} pb={1} direction={'column'} justifyContent='space-between' gap={1.5}>
                    {/* Used tokens */}
                    <PrizeStats prize={prize}/>

                    {/* Button */}
                    {!preview &&
                        <PrizeButtons
                            isContestActive={isActive}
                            prize={prize}
                            profile={profile}
                            onAddTokensPress={() => setShowAddTokensDialog(true)}
                            onLoginPress={goToLogin}
                            onShowWinnersPress={() => setShowWinnersDialog(true)}
                        />
                    }
                </Stack>
            </Card>

            {
                showWinnersDialog &&
                <WinnersDialog
                    prize={prize}
                    onClose={() => setShowWinnersDialog(!showWinnersDialog)}
                />
            }

            {
                showContestPrizeDetail &&
                <ContestPrizeDetails
                    contest={contest}
                    prize={prize}
                    onClose={() => setShowContestDetail(false)}
                    onAddTokensPress={() => {
                        setShowContestDetail(false)
                        setShowAddTokensDialog(true)
                    }}
                    onLoginPress={goToLogin}
                    onShowWinnersPress={() => {
                        setShowContestDetail(false)
                    }}
                />
            }

            {
                showAddTokensDialog &&
                <UseTokensDialog
                    prize={prize}
                    onClose={() => setShowAddTokensDialog(false)}
                    onUseTickets={(numberOfTickets: number) => {
                        setShowAddTokensDialog(false)
                        addLotteryTickets(prize, numberOfTickets)
                    }
                    }
                />
            }
        </>
    )
}

export default ContestPrizeItem
