import {Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography} from "@mui/material";
import {Stack} from "@mui/system";
import {useTranslation} from "react-i18next";
import {lightGreenBackgroundColor, tcsBlueGray1, Theme} from "../../mui/Theme";
import {Reward} from "../../utils/model/Reward";
import {ReactComponent as TrophyIcon} from '../../icons/Icon_Pokal3.svg'
import {TipsAndUpdatesOutlined} from "@mui/icons-material";

interface Props {
    reward: Reward | null,
    onClose(): void
}

const Feedback = ({reward, onClose}: Props) => {
    const {t} = useTranslation();

    return (
        <Dialog open={true} onClose={onClose} className="dialog feedback">
            <DialogTitle variant="h3" sx={{mb: 0.5}} align="center">
                {t('Thanks for contributing')}
            </DialogTitle>
            <DialogContent>
                {
                    reward &&
                    <Stack direction={'row'} gap={1}
                           alignItems={'center'} justifyContent={'center'}
                           sx={{
                               p: 1, px: 1, my: 1
                           }}>
                        <img width={64} height={64} alt={t('Thanks for contributing')} src="/images/party.svg"/>
                        <Typography variant="body1Bold" color={Theme.palette.success.main} fontSize={"2rem"}>
                            {t('+ {{count}} points', {count: reward.points})}
                        </Typography>
                    </Stack>
                }
                <Stack direction={'row'} gap={1}
                       sx={{
                           background: lightGreenBackgroundColor,
                           px: 1, py: 1.5, mb: 0,
                           borderRadius: '8px'
                       }}>
                    <TrophyIcon className="customSVGIcon size64"/>
                    <Typography fontSize={11}>
                        {t('Additionally, for the next 24hours, you will earn 10 points for each other person confirming your price')}
                    </Typography>
                </Stack>

                <Stack direction={'row'} gap={1.25}
                       alignItems={"center"}
                       sx={{
                           background: tcsBlueGray1,
                           p: 1, pl: 1.5,
                           borderRadius: '8px'
                       }}>
                    <TipsAndUpdatesOutlined fontSize="medium"/>
                    <Typography fontSize={11} color={"success"}>
                        {t('Feel free to update the prices of other fuels at this station to earn more points')}
                    </Typography>
                </Stack>
            </DialogContent>
            <DialogActions sx={{mt: 1}}>
                <Button variant="contained" color="secondary" sx={{fontSize: 14}}
                        onClick={onClose}>{t('Continue')}</Button>
            </DialogActions>
        </Dialog>
    )
}

export default Feedback
