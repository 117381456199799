import React, {useEffect} from "react";
import {useAuth} from "../context/AuthContext";
import {useRouter} from "../context/RouterContext";
import {useProfile} from "../context/ProfileContext";
import {UserType} from "../utils/model/UserType";
import SimplePage from "./SimplePage";

const AdminRoute = ({children}: { children: React.ReactNode }) => {
    const {user, tcsSignIn} = useAuth()
    const {navigate} = useRouter();
    const {profile} = useProfile();

    useEffect(() => {
        if (!user) {
            tcsSignIn()
        }
    }, [navigate, user, tcsSignIn])

    return (
        <>
            {
                (user && (profile?.type === UserType.ADMIN || profile?.type === UserType.SUBADMIN)) ?
                    children :
                    <SimplePage>
                        <p>Restricted access</p>
                    </SimplePage>
            }
        </>
    )
}

export default AdminRoute