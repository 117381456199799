import {collection, FirestoreError, orderBy, query, where} from 'firebase/firestore'
import {createContext, useContext, useState} from 'react'
import {useCollection, useCollectionOnce} from 'react-firebase-hooks/firestore'
import {firestore} from '../config/firebase'
import ContestConverter from '../utils/converters/ContestConverter'
import ContestPrizeConverter from '../utils/converters/ContestPrizeConverter'
import ContestPrizeEntryConverter from '../utils/converters/ContestPrizeEntryConverter'
import {Contest, isContestActive} from '../utils/model/Contest'
import {ContestPrize} from '../utils/model/ContestPrize'
import {useAuth} from './AuthContext'
import {ContestPrizeEntryRequestDTO} from '../utils/model/ContestPrizeEntryRequestDTO'
import {sendContestUseTokenRequest} from '../utils/Api'
import {WonPrize} from "../utils/model/WonPrize";

export interface ContestWithPrizes extends Contest {
    prizes: PrizeWithUserEntries[];
}

export interface PrizeWithUserEntries extends ContestPrize {
    userEntries: number;
}

interface ContestContextInterface {
    isLoading: boolean;
    errors: FirestoreError[];
    contests: ContestWithPrizes[];

    isAddLotteryTicketsLoading: boolean;
    isAddLotteryTicketError: boolean;

    addLotteryTickets(prize: ContestPrize, numberOfTickets: number): void;
    clearAddLotteryTicketError(): void;

    userWonContestPrizes: ContestPrize[];
    userWonPrizes: WonPrize[];
    isWonPrizesLoading: boolean;
    wonPrizesError: FirestoreError[];
}

const defaultContext = createContext<ContestContextInterface>({
    isLoading: true,
    errors: [],
    contests: [],

    isAddLotteryTicketsLoading: false,
    isAddLotteryTicketError: false,

    addLotteryTickets: (prize: ContestPrize, numberOfTickets: number) => {
        return
    },
    clearAddLotteryTicketError: () => {
        return
    },
    userWonContestPrizes: [],
    userWonPrizes: [],
    isWonPrizesLoading: true,
    wonPrizesError: []
})

export const useContests = () => useContext(defaultContext)

export const ContestContextProvider = ({
                                           children,
                                       }: {
    children: React.ReactNode
}) => {
    const {user} = useAuth();
    const uid = user ? user : 'xxxxxxxx';
    const [isAddLotteryTicketsLoading, setIsAddLotteryTicketsLoading] = useState<boolean>(false)
    const [addLotteryTicketError, setAddLotteryTicketError] = useState<boolean>(false)

    const contestsQuery = query(
        collection(firestore, "contests").withConverter(ContestConverter),
        orderBy("startDate", "desc")
    );

    //Retrieve all active and past contests (only on page load)
    const [contestsSnapshot, isContestLoading, contestsError] = useCollectionOnce(
        contestsQuery
    );
    const contests = contestsSnapshot ? contestsSnapshot.docs
            .map(x => x.data()).filter(Boolean)
            .filter(contest => contest!.startDate <= new Date())
            .sort((a, b) => {
                const isActiveA = isContestActive(a!)
                const isActiveB = isContestActive(b!)

                if (isActiveA && !isActiveB) {
                    return -1
                }
                if (!isActiveA && isActiveB) {
                    return 1
                }
                return b!.endDate.getTime() - a!.endDate.getTime();
            }) as Contest[]
        : []

    // Retrieve prizes for valid contests
    const prizeQuery = contests.length ? query(
        collection(firestore, "contestPrizes").withConverter(ContestPrizeConverter),
        where("contestId", "in", contests.map(x => x.id))) : null
    const [prizesSnapshot, isPrizesLoading, prizesError] = useCollection(
        contests.length ? prizeQuery : null,
        {snapshotListenOptions: {includeMetadataChanges: false}}
    );

    //Retrieve all the prizes entries for the authenticated user
    const [userContestPrizeEntriesSnapshot, isContestPrizeEntriesLoading, contestPrizeEntriesError] =
        useCollection(
            query(
                collection(firestore, "contestPrizeEntries").withConverter(ContestPrizeEntryConverter),
                where("profileId", "==", uid)
            )
        );
    const userContestPrizeEntries = userContestPrizeEntriesSnapshot
        ? userContestPrizeEntriesSnapshot.docs.map(x => x.data())
        : []

    const [userWonPrizesSnapshot, isWonPrizesLoading, wonPrizesError] =
        useCollectionOnce(
            query(
                collection(firestore, "wonPrizes"),
                where("winnerProfile.userId", "==", uid)
            )
        )

    const userWonPrizes = userWonPrizesSnapshot ? userWonPrizesSnapshot.docs
        .map(x => x.data()).filter(Boolean) as WonPrize[] : [];

    const userWonPrizesData: string[] = userWonPrizesSnapshot ? userWonPrizesSnapshot.docs.map(x => x.data().prizeId) : [];
    const userWonContestPrizes: ContestPrize[] = prizesSnapshot ? prizesSnapshot.docs.map(x => x.data() as ContestPrize).filter(prize => userWonPrizesData.includes(prize.id)) : [];

    //Map contest to contestWithPrizes
    const contestsWithPrizes: ContestWithPrizes[] = prizesSnapshot ? contests.map(contest => {
        //Sanity checks
        const associatedPrizes = prizesSnapshot.docs
            .map(x => x.data())
            .filter(prize => prize?.contestId === contest.id) as ContestPrize[]

        //Discard contest if no prizes
        if (!associatedPrizes.length) {
            return undefined
        }

        //Add to each prize the number of tokens used by the authenticated user
        const prizesWithUserEntries = associatedPrizes.map(prize => {
            const prizeWithUserEntries: PrizeWithUserEntries = {
                ...prize,
                totalUsedTokens: prize.totalUsedTokens,
                userEntries: userContestPrizeEntries.filter(
                    userPrizeEntry => userPrizeEntry.contestId === prize.contestId
                        && userPrizeEntry.contestPrizeId === prize.id
                )
                    .length
            }
            return prizeWithUserEntries
        })

        //Create an extended Contest that includes the prizes in its model
        const contestWithPrizes: ContestWithPrizes = {
            ...contest,
            prizes: prizesWithUserEntries
        }
        return contestWithPrizes
    }).filter(Boolean) as ContestWithPrizes[] : []


    const sendAPICall = async (prize: ContestPrize, numberOfTickets: number) => {
        if (isAddLotteryTicketsLoading) {
            return
        }

        setIsAddLotteryTicketsLoading(true)
        const dto: ContestPrizeEntryRequestDTO = {
            contestPrize: prize,
            numberOfTokens: numberOfTickets
        }

        try {
            await sendContestUseTokenRequest(dto)
        } catch (e) {
            console.error("AddLotteryError " + e)
            setAddLotteryTicketError(true)
        }

        setIsAddLotteryTicketsLoading(false)
    }

    /*
     ** API call when user add a lottery ticket
     */
    const addLotteryTickets = (prize: ContestPrize, numberOfTickets: number): void => {
        sendAPICall(prize, numberOfTickets)
    }

    const clearAddLotteryTicketError = (): void => {
        setAddLotteryTicketError(false)
    }

    const provider: ContestContextInterface = {
        isLoading: (isContestLoading || isContestPrizeEntriesLoading || isPrizesLoading),
        errors: [contestsError, contestPrizeEntriesError, prizesError].filter(Boolean) as FirestoreError[],
        contests: contestsWithPrizes,
        isAddLotteryTicketsLoading: isAddLotteryTicketsLoading,
        isAddLotteryTicketError: addLotteryTicketError,
        addLotteryTickets: addLotteryTickets,
        clearAddLotteryTicketError: clearAddLotteryTicketError,
        userWonContestPrizes: userWonContestPrizes,
        userWonPrizes: userWonPrizes,
        isWonPrizesLoading: isWonPrizesLoading,
        wonPrizesError: [wonPrizesError].filter(Boolean) as FirestoreError[]
    }

    return (
        <defaultContext.Provider value={provider}>
            {children}
        </defaultContext.Provider>
    )
}
