import {CircularProgress} from '@mui/material';
import {collection, query, where} from 'firebase/firestore';
import {firestore} from '../../../config/firebase';
import {PrizeWithUserEntries} from "../../../context/ContestContext";
import WinnersList from "./WinnersList";
import {useCollectionOnce} from "react-firebase-hooks/firestore";
import SimplePage from "../../SimplePage";
import {WonPrize} from "../../../utils/model/WonPrize";

interface Props {
    prize: PrizeWithUserEntries
}

export default function WinnersLoader({prize}: Props) {
    const wonPrizesQuery = query(
        collection(firestore, "wonPrizes"),
        where("prizeId", "==", prize.id)
    );

    const [wonPrizes, loading, error] = useCollectionOnce(wonPrizesQuery);

    const wonPrizesData: WonPrize[] = wonPrizes?.docs.map(wonPrize => wonPrize.data()) as WonPrize[] ?? [];

    return (
        <>
            {
                error &&
                <SimplePage>{error.message}</SimplePage>
            }
            {
                loading &&
                <CircularProgress />
            }
            {
                !loading && wonPrizes &&
                <WinnersList wonPrizes={wonPrizesData} prize={prize} />
            }
        </>
    )
}