const R_EARTH = 6378137;

export const getDistance = (location1: { lat: number, lng: number }, location2: { lat: number, lng: number }) => {
    const lat = [location1.lat, location2.lat];
    const lng = [location1.lng, location2.lng];
    const dLat = (lat[1]-lat[0]) * Math.PI / 180;
    const dLng = (lng[1]-lng[0]) * Math.PI / 180;
    const a = Math.sin(dLat/2) * Math.sin(dLat/2) +
        Math.cos(lat[0] * Math.PI / 180 ) * Math.cos(lat[1] * Math.PI / 180 ) *
        Math.sin(dLng/2) * Math.sin(dLng/2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a));
    const d = R_EARTH * c;
    return Math.round(d);
}
