import { DocumentData, QueryDocumentSnapshot, SnapshotOptions, WithFieldValue } from "firebase/firestore";
import { FiabilityLevel } from "../model/FiabilityLevel";

export const FiabilityLevelConverter = {
  toFirestore(FiabilityLevel: WithFieldValue<FiabilityLevel>): DocumentData {
    return { FiabilityLevel };
  },
  fromFirestore(
    snapshot: QueryDocumentSnapshot,
    options: SnapshotOptions
  ): FiabilityLevel {
    const data = snapshot.data(options)!;
    return FiabilityLevelConverter.fromData(data);
  },
  fromData(data: any): FiabilityLevel {
    return data as FiabilityLevel
  }
}
export default FiabilityLevelConverter
