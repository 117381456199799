import {useContests} from "../../context/ContestContext";
import WonPrizeItem from "./WonPrizeItem";
import {Grid, Paper, Typography} from "@mui/material";

export default function WonPrizeList() {
    const contestContext = useContests();

    return (
        <>
            <Typography variant='h3' sx={{ mt: 4, mb: 2 }}>Lots gagnés</Typography>
            <Paper elevation={1} sx={{ mb: 4, background: 'white', border: 0, boxShadow: 0 }}>
                {
                    !contestContext.isLoading && !contestContext.errors.length && (
                        <Grid container spacing={1} py={1} px={{ xs: 0, sm: 1 }} alignItems="stretch">
                            {contestContext.userWonContestPrizes
                                .map((contestPrize) => {
                                    return <Grid key={contestPrize.id} item xs={6} sm={4}>
                                        <WonPrizeItem
                                            key={contestPrize.id}
                                            contestPrize={contestPrize}
                                        />
                                    </Grid>
                                })
                            }
                        </Grid>
                    )
                }
            </Paper>
        </>
    )
}