import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ModeIcon from '@mui/icons-material/Mode';
import PersonRemoveIcon from '@mui/icons-material/PersonRemove';
import PowerSettingsNewIcon from '@mui/icons-material/PowerSettingsNew';
import {
  Avatar,
  Badge,
  Button,
  CircularProgress,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Switch,
  Typography
} from "@mui/material";
import { styled } from '@mui/material/styles';
import React, { useState } from 'react';
import { useTranslation } from "react-i18next";
import { useAuth } from '../../context/AuthContext';
import { useProfile } from '../../context/ProfileContext';
import { useRouter } from '../../context/RouterContext';
import { AvatarIcon } from "../../utils/assets/AvatarIcon";
import { UserAvatar } from '../../utils/model/UserAvatar';
import ErrorResponseDialog from '../station/components/ErrorResponseDialog';
import AvatarDialog from './components/AvatarDialog';
import DeleteDialog from './components/DeleteDialog';
import DisplayNameDialog from './components/DisplayNameDialog';
import {useContests} from "../../context/ContestContext";
import DialogLoader from "../common/DialogLoader";

const UsernameButton = styled(Button)({
  textTransform: 'none',
  fontSize: 18,
  letterSpacing: 0
})

const Profile = () => {
  const { navigate } = useRouter();
  const { user, logout } = useAuth();
  const { profile, updateAvatar, updateDisplayName, updateAcceptFuelMailing } = useProfile();
  const { t } = useTranslation();
  const contestContext = useContests();

  const [avatar, setAvatar] = useState<boolean>(false);
  const [displayName, setDisplayName] = useState<boolean>(false);
  const [openDelete, setOpenDelete] = useState<boolean>(false);
  const [error, setError] = useState<TemplateStringsArray | undefined>();
  const [loading, setLoading] = useState(false);

  const onAvatar = () => setAvatar(!avatar)
  const onDisplayName = () => setDisplayName(!displayName)

  if (!profile) {
    logout()
    navigate('/')
  }

  const onLogout = () => {
    logout()
    navigate('/')
  }
  const onChangePassword = () => {
    // TODO: Change this popup opening to a proper password changing route
    window.open('https://www.tcs.ch/global/wGlobal/scripts/php/ecare/auth/passwordReset.php', 'popup', 'width=600,height=600');
    return false;
  }

  const onSeeWonPrize = () => {
    navigate('/won-prizes');
  }

  const onDelete = () => setOpenDelete(!openDelete)

  const onClickAvatar = (userAvatar: UserAvatar) => {
    updateAvatar(userAvatar)
    setAvatar(false)
  }
  const onUpdateDisplayName = async (displayName: string) => {
    setLoading(true);
    try {
      await updateDisplayName(displayName)
      setDisplayName(false)
    } catch (error: any) {
      setError(error.message)
    }
    setLoading(false);
  }

  const onChangeAcceptFuelMailing = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (profile) {
      updateAcceptFuelMailing(event.target.checked)
    }
  }

  return (
    <>
      {
          loading &&
          <DialogLoader loading={loading} onClose={() => setLoading(!loading)}/>
      }
      {
        error &&
        <ErrorResponseDialog errorCode={error as TemplateStringsArray} onClose={() => {
          setError(undefined)
        }} />
      }
      {!profile && <CircularProgress />}
      {profile && <div className="profile">
        <div className="avatar-username">
          <div className="avatar">
            <IconButton onClick={onAvatar}>
              <Badge color="primary" badgeContent={<ModeIcon></ModeIcon>}>
                <Avatar src={AvatarIcon.forAvatar(profile?.publicProfile.avatar)}></Avatar>
              </Badge>
            </IconButton>
            {avatar && <AvatarDialog onClose={onAvatar} onSelect={onClickAvatar}></AvatarDialog>}
          </div>
          <div className="username">
            <UsernameButton onClick={onDisplayName} variant='text' color='secondary'>
              <Badge color="primary" badgeContent={<ModeIcon></ModeIcon>}>
                {profile && profile.publicProfile.displayName}
              </Badge>
            </UsernameButton>
            {displayName && <DisplayNameDialog onClose={onDisplayName} onUpdate={onUpdateDisplayName} defaultValue={profile.publicProfile.displayName!} ></DisplayNameDialog>}
          </div>
        </div>
        {profile?.tcsRef && <div className="tcs-account">
          <div>
            <p>
              <b>
                {t('TCS Online Account')}
              </b>
            </p>
            <p>
              N° {profile?.tcsRef}
            </p>
          </div>
          <div>
            <img alt='TCS Logo' width="48" height="48" src='/images/tcslogo.webp'/>
          </div>
        </div>
        }
        <div className="checkboxes">
          <div className="checkbox">
            <Switch inputProps={{ 'aria-label': t('I agree to receive information related to the Gas Price advisor contest') }} onChange={onChangeAcceptFuelMailing} defaultChecked={profile.acceptFuelMailing} />
            {t('I agree to receive information related to the Gas Price advisor contest')}
          </div>
        </div>
        <Typography variant={"caption"}>{t('I agree to receive an email if I win a prize in the Gas Price Advisor contest')}</Typography>
        <div className="actions">
            {
                contestContext.userWonContestPrizes.length > 0 &&
                <List>
                  <ListItem className={"see-won-prizes"}>
                    <ListItemButton onClick={onSeeWonPrize}>
                      <ListItemText primary={t('Prizes won')} />
                      <ListItemIcon>
                        <ChevronRightIcon fontSize='large' />
                      </ListItemIcon>
                    </ListItemButton>
                  </ListItem>
                </List>
            }
          <List>
            <ListItem>
              <ListItemButton onClick={onChangePassword}>
                <ListItemText primary={t('Change password')} />
                <ListItemIcon>
                  <ChevronRightIcon fontSize='large' />
                </ListItemIcon>
              </ListItemButton>
            </ListItem>
          </List>
          {user && <>
            <Divider />
            <List>
              <ListItem>
                <ListItemButton onClick={onLogout}>
                  <ListItemText primary={t('logout')} />
                  <ListItemIcon>
                    <PowerSettingsNewIcon fontSize='large' />
                  </ListItemIcon>
                </ListItemButton>
              </ListItem>
            </List>
          </>
          }
          {user && <>
            {openDelete && <DeleteDialog onClose={onDelete}></DeleteDialog>}
            <Divider />
            <List>
              <ListItem>
                <ListItemButton onClick={onDelete}>
                  <ListItemText primary={t('Delete account')} />
                  <ListItemIcon>
                    <PersonRemoveIcon fontSize='large' />
                  </ListItemIcon>
                </ListItemButton>
              </ListItem>
            </List>
          </>
          }
        </div>

      </div>
      }

    </>

  )
}

export default Profile
