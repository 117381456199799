import {Avatar, Button, Dialog, DialogActions, DialogContent, DialogTitle} from "@mui/material"

import {useTranslation} from "react-i18next"
import {UserAvatar, UserAvatarValues} from "../../../utils/model/UserAvatar"
import {AvatarIcon} from "../../../utils/assets/AvatarIcon";

interface Props {
    onClose(): void
    onSelect(key: UserAvatar): void
}

const AvatarDialog = ({onClose, onSelect}: Props) => {
    const {t} = useTranslation();

    const internalClose = () => {
        onClose();
    }

    const onClickAvatar = (key: UserAvatar) => {
        onSelect(key);
    }

    return (
        <Dialog open={true} onClose={internalClose} className="dialog">
            <DialogTitle>
                <div className='dialog-title'>
                    <div className='dialog-title-left'>
                        {t('Choose an avatar')}
                    </div>
                </div>
            </DialogTitle>
            <DialogContent>
                <div className="avatar-list">
                    {UserAvatarValues.map((userAvatar) => {
                        return <Avatar key={userAvatar} onClick={() => onClickAvatar(userAvatar)}
                                       src={AvatarIcon.forAvatar(userAvatar)}></Avatar>
                    })}
                </div>
            </DialogContent>
            <DialogActions sx={{paddingTop: 1}}>
                <Button variant="contained" color="secondary" onClick={internalClose}>{t('Close')}</Button>
            </DialogActions>
        </Dialog>
    )
}

export default AvatarDialog
