import { Button } from "@mui/material"
import { useTranslation } from "react-i18next"
import { useAuth } from "../../context/AuthContext"

const Login = () => {
  const { t } = useTranslation()
  const { tcsSignIn } = useAuth()

  const tcsLogin = async (_e: React.MouseEvent<HTMLElement>) => {
    _e.preventDefault();
    try {
      await tcsSignIn();
    } catch (error) {
      console.log(error);
    }
  }

  return (
    <div>
      <h1>{t('login')}</h1>
      <p>
        {t('The account creation is open to both TCS members and non-members')}
      </p>
      <p>
        {t('If you already have a login for the "TCSch website" or the "TCS mobile app", you can use it directly without creating a new account')}
      </p>
      <p>
        {t('New users can also register')}
      </p>
      <p>
        {t('Click the link below to login or create an account')}
      </p>
      <Button
        variant="contained"
        color="secondary"
        type="button"
        onClick={tcsLogin}
      >
        {t('TCS Online Account')}
      </Button>
    </div>
  )
}

export default Login
