import { Profile } from "../../utils/model/Profile"

export const LOCALSTORAGE_CONTESTOVER_PREFIX_KEY = "hasSeenContestOverBanner";
export const LOCALSTORAGE_CONTESTSTART_PREFIX_KEY = "hasSeenContestStartBanner";

export type LOCALSTORAGE_BANNER_KEYS = "hasSeenContestOverBanner"
    | "hasSeenContestStartBanner";

function buildKey(profile:Profile | null | undefined, key:LOCALSTORAGE_BANNER_KEYS) {
    const suffix = profile?.id ?? "guest"
    return key + "_" + suffix
}

export class BannerStateStorage {
    static getHasSeenContestBanner(profile:Profile | null | undefined, key:LOCALSTORAGE_BANNER_KEYS): boolean {        
        const storageString = localStorage.getItem(buildKey(profile, key))
        if (!storageString) { return false }
        return (storageString === "true" || storageString === "1")
    }

    static saveHasSeenContestBanner(state: boolean, profile:Profile | null | undefined, key:LOCALSTORAGE_BANNER_KEYS) {
        localStorage.setItem(buildKey(profile, key), String(state))
    }
}

