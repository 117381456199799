import { createContext, useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'
import { useAuth } from './AuthContext'
import { useProfile } from './ProfileContext'

const RouterContext = createContext<any>({})

export const useRouter = () => useContext(RouterContext)

export const RouterContextProvider = ({
  children,
}: {
  children: React.ReactNode
}) => {

  const internalNavigate = useNavigate()
  const { i18n } = useTranslation('translation', { useSuspense: false })
  const location = useLocation();

  const basePath = `/${i18n.language}`

  const [returnUrl, setReturnUrl] = useState<string | undefined>();

  const { profile } = useProfile()

  const { user } = useAuth();

  const changeLanguage = (lng: string) => {
    const url = location.pathname?.split('/').reduce((init, val, index) => {
      switch (index) {
        case 0:
          return '/'
        case 1:
          return lng
        default:
          return `${init}/${val}`
      }
    }, '')
    i18n.changeLanguage(lng)
    internalNavigate(url)
  }

  const isNoBottomMenuPage = (url: string): boolean => {
    // We remove bottomPage menu only on finalize page.
    if (url.match(/\/profile-finalize$/g)) return true;
    return false;
  }

  const isProtectedPage = (url: string): boolean => {
    if (
      url.match(/\/profile$/g)
      || url.match(/\/profile-finalize$/g)
      || url.match(/\/profile-thanks$/g)
      || url.match(/\/login$/g)
      || url.match(/\/admin$/g)
    ) {
      return true;
    }
    return false;
  }

  const isAdminPage = (url: string): boolean => {
    if (url.match(/^\/admin/g)) {
      return true;
    }
    return false;
  }

  // Restore local returnUrl from storage
  // and redirect to it if user is logged and on login page
  useEffect(() => {
    if (user && location.pathname.match(/\/login$/g)) {
      if (returnUrl === undefined) {
        const returnUrlStorage = localStorage.getItem('returnUrl') || '/';
        setReturnUrl(returnUrlStorage)
        navigate(returnUrlStorage)
        return
      }
      navigate(returnUrl)
    }
    // eslint-disable-next-line
  }, [user])

  // Force user to go to finalize page
  useEffect(() => {
    if (profile && !profile?.isCompleted) {
      navigate('/profile-finalize');
    }
    // eslint-disable-next-line
  }, [profile])

  const navigate = (url?: string) => {
    if (
      url !== undefined && !isProtectedPage(url) && !isAdminPage(url)
    ) {
      setReturnUrl(url);
      localStorage.setItem('returnUrl', url);
    }

    if (url === undefined && returnUrl) {
      internalNavigate(`${basePath}${returnUrl}`);
      return;
    }

    if (url === undefined && !returnUrl) {
      internalNavigate(basePath);
      return;
    }

    if (location.pathname.endsWith(url!)) { return; }

    if (url !== undefined && isAdminPage(url)) {
      internalNavigate(url);
      return;
    }

    internalNavigate(`${basePath}${url}`);
  }

  return (
    <RouterContext.Provider value={{ basePath, changeLanguage, navigate, isProtectedPage, isNoBottomMenuPage }}>
      {children}
    </RouterContext.Provider>
  )
}
