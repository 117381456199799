import { Badge, Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from "@mui/material"
import { useTranslation } from "react-i18next"
import { useProfile } from "../../../context/ProfileContext"
import { useRouter } from '../../../context/RouterContext';
import { ReactComponent as LotteryTicket } from '../../../icons/tcs-icon_lotteryticket.svg'
import { ReactComponent as WinIcon } from '../../../icons/tcs-icon_win.svg'

interface Props {
  onClose(): void
}

const TokenDialog = ({ onClose }: Props) => {
  const { t } = useTranslation()

  const { profile } = useProfile()

  const { navigate } = useRouter()

  const internalClose = () => {
    onClose();
  }

  const onClickContest = () => {
    navigate('/contest')
    onClose();
  }

  return (

    <Dialog open={true} onClose={internalClose} className="dialog token">
      <DialogTitle>
        <div className='dialog-title'>
          <Typography variant="h4">{t('Lottery tokens')}</Typography>
          <div className='dialog-title-right'>
            <Badge
              max={999}
              color='error'
              badgeContent={profile?.lotteryTickets || "0"}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
            >
              <LotteryTicket className={"customSVGIcon lotterySVG stroke1 size25"} />
            </Badge>
          </div>
        </div>
      </DialogTitle>
      <DialogContent>
        <div className="icon-text">
          <div className="icon"><LotteryTicket className={"customSVGIcon lotterySVG stroke1 size32"} /></div>
          <div className="text">{t('Earn 1 lottery token for every 100 points of participation')}</div>
        </div>
        <div className="icon-text">
          <div className="icon"><WinIcon className={"customSVGIcon lotterySVG stroke1 size32"} /></div>
          <div className="text">{t('Use your chips and increase your chances of winning the prizes')}</div>
        </div>
        <p></p>
        <p></p>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" color="secondary" onClick={onClickContest}>{t('Use tokens')}</Button>
        <Button variant="contained" color="secondary" onClick={internalClose}>{t('Close')}</Button>
      </DialogActions>
    </Dialog >
  )
}

export default TokenDialog
