import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useRouter } from '../../../context/RouterContext';
import { FuelType, FuelTypeValues } from '../../../utils/model/FuelType';
import { useParams } from "react-router-dom";
import React from "react";
import Tabs, { tabsClasses } from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { Chip } from '@mui/material';
import { fontFamilyMuseo700, Theme } from '../../../mui/Theme';

const FuelSelector = () => {
  const { t } = useTranslation();
  const { navigate } = useRouter();
  const { fuelId } = useParams();

  const fuelType = fuelId as FuelType
  if (!fuelType) { return null }

  const handleChange = (event: React.SyntheticEvent, value: FuelType) => {
    event.stopPropagation();
    changeFuelType(value)
  }

  const changeFuelType = (value: FuelType) => {
    if (value) {
      localStorage.setItem(`my-fuel`, value)
      navigate(`/map/${value}`)
    }
  }

  return (
    <Box className="fuel-selector">
      <Tabs
        value={fuelId}
        onChange={handleChange}
        variant="scrollable"
        scrollButtons={true}
        allowScrollButtonsMobile={true}
        sx={{
          p:0,
          [`& .${tabsClasses.scrollButtons}`]: {
            '&.Mui-disabled': { opacity: 0.3 },
          }
        }}
        TabIndicatorProps={{
          style: { height: 0 } //workaround to remove tabs selected default bottom border style
        }}
      >
        {FuelTypeValues.map((type, index) =>
          <Tab
          key={type}
          value={type}
            sx={{ px: 0.5, py: 0, minWidth: 0 }}
            label={
              <Chip
                color={type === fuelType ? "primary" : undefined}
                label={t(type)}
                onClick={() => {
                  changeFuelType(type)
                }}
                sx={
                  {
                    px: 0.5,
                    fontSize: 14,
                      fontWeight: 700,
                      fontFamily: fontFamilyMuseo700,
                    "&:focus": {
                      background: Theme.palette.primary.main,
                      fontWeight: 'bold'
                    }
                  }}
              />
            }
          />
        )}
      </Tabs>
    </Box>
  )
}

export default FuelSelector
