export interface Contest {
    id: string;

    displayName_DE: string;
    displayName_FR: string;
    displayName_IT: string;

    startDate: Date;
    endDate: Date;
}

export function isContest(object: unknown): object is Contest {
    return Object.prototype.hasOwnProperty.call(object, "displayName_DE")
        && Object.prototype.hasOwnProperty.call(object, "displayName_FR")
        && Object.prototype.hasOwnProperty.call(object, "displayName_IT")
        && Object.prototype.hasOwnProperty.call(object, "startDate")
        && Object.prototype.hasOwnProperty.call(object, "endDate")
}

export function isContestActive(contest:Contest) : boolean {
    const currentDate = new Date();
    return contest.startDate < currentDate && contest.endDate > currentDate;
}

export function doesContestEndsInFuture(contest:Contest) : boolean {
    const currentDate = new Date();
    return contest.endDate > currentDate;
}