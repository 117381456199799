import { Brand, Station } from "../model/Station";

export class BrandIcon {
    static forBrand(brand: Brand): string {
        if (brand === Brand.UNDEFINED || !Object.values(Brand).includes(brand)) {
            return "/images/brands/icons/default.webp"
        }
        return `/images/brands/icons/${brand.toLowerCase()}.webp`
    }

    static forStation(station: Station): string {
        return BrandIcon.forBrand(station.brand as Brand)
    }
}
