import { CircularProgress } from '@mui/material';
import { collection, limit, orderBy, query, where } from 'firebase/firestore';
import { useCollectionOnce } from 'react-firebase-hooks/firestore';
import { firestore } from '../../../../config/firebase';
import UserActionConverter from '../../../../utils/converters/UserActionConverter';
import { Fuel } from '../../../../utils/model/Fuel';
import { FuelType } from '../../../../utils/model/FuelType';
import { Station } from '../../../../utils/model/Station';
import SimplePage from '../../../SimplePage';
import HistoryList from './HistoryList';

interface Props {
  station: Station,
  fuel: Fuel,
  fuelType: FuelType
}



const UserActionLoader = ({ station, fuelType }: Props) => {
  const userActionQuery = query(
    collection(firestore, "userActions").withConverter(UserActionConverter),
    where("fuelType", "==", fuelType),
    where("stationId", "==", station.id),
    orderBy("date","desc"),
    limit(20)
  );


  const [userActions, loading, error] = useCollectionOnce(userActionQuery)

  return (
    <>
      {error && <SimplePage>UserActionLoader:{error.message}</SimplePage>}
      {loading && <CircularProgress />}
      {!loading && userActions && (<HistoryList userActions={userActions} />)}
    </>
  )
}
export default UserActionLoader
