import { Fiability } from '../../../utils/model/Fiability';
import { Chip } from '@mui/material';
import FiabilityIcon from '../../common/FiabilityIcon';
import { Text } from '../../../utils/assets/TextFromFiability';
import { Color } from '../../../utils/assets/ColorFromFiability';

interface Props {
    fiability: Fiability;
    onClick?: CallableFunction;
}
const FiabilityChip = ({ fiability, onClick }: Props) => {
    const click = (e: React.MouseEvent<HTMLElement>) => {
        if (onClick !== undefined) {
            onClick(e)
        }
    }
    
    return (
        <Chip className='fiability-chip'
            onClick={click}
            icon={<FiabilityIcon fiabilityLevel={fiability.level} iconSize={undefined} />}
            label={Text.forFiability(fiability)}
            sx={{
                py:0.5,
                px:0.5,
                height: 'auto',
                '.MuiChip-label': {
                    color:Color.forFiabilityLevel(undefined),
                    fontSize:11,
                    fontWeight:'bold',
                    py: 0,
                    pl: 0.5,
                    whiteSpace:'pre-wrap',
                    lineHeight:1
                }
            }} />

    )
}
export default FiabilityChip
