import { Badge, Box, Link, Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { ReactComponent as LotteryTicketIcon } from '../../icons/tcs-icon_lotteryticket.svg'
import { ReactComponent as RaffleIcon } from '../../icons/tcs-icon_raffle.svg'
import { ReactComponent as WinIcon } from '../../icons/tcs-icon_win.svg'
import { ReactComponent as TrophyIcon } from '../../icons/Icon_Pokal3.svg'
import { Theme } from '../../mui/Theme';

function HowItWorks() {
  const { t } = useTranslation();

  return (
    <Box className='how-it-works'>
      <Typography variant='h3' sx={{ mt: 4, mb: 1 }}>{t('Contests_HowItWorks_Title')}</Typography>

      <Stack direction='row' flexWrap={'wrap'} className='steps'>
        <Stack direction='row' sx={{ p: 1, flex: '1 1 50%', minWidth: '300px' }}>
          <Typography className="number">1</Typography>
          <Box className='step' px={1} pt={1}>
            <Typography variant='h4' sx={{ pb: 1.5 }}>{t('Contests_HowItWorks_Step1_Title')}</Typography>
            <Typography>{t('Contests_HowItWorks_Step1_Description')}</Typography>
            <div className="icon-text">
              <div className="icon">
                <Badge
                  badgeContent={'+10'}
                  color="success"
                  className='icon'
                  sx={{ zIndex: 0 }}
                ><TrophyIcon /></Badge>
              </div>
              <Typography>{t('Contests_HowItWorks_Step1_Update')}</Typography>
            </div>
            <div className="icon-text">
              <div className="icon">
                <Badge
                  badgeContent={'+5'}
                  color="success"
                  sx={{ zIndex: 0 }}
                ><TrophyIcon /></Badge></div>
              <Typography>{t('Contests_HowItWorks_Step1_Peer')}</Typography>
            </div>
          </Box>
        </Stack>

        <Stack direction='row' sx={{ p: 1, flex: '1 1 50%', minWidth: '300px' }}>
          <Typography className="number">2</Typography>

          <Box className='step' px={1} pt={1}>
            <Typography variant='h4'>{t('Contests_HowItWorks_Step2_Title')}</Typography>
            <div className="icon-text">
              <div className="icon">
                <Badge
                  badgeContent={'+1'}
                  color="error"
                  sx={{ zIndex: 0 }}
                ><LotteryTicketIcon className='lotterySVG' /></Badge></div>
              <Typography>{t('Contests_HowItWorks_Step2_Points')}</Typography>
            </div>
            <div className="icon-text">
              <div className="icon"><RaffleIcon className='lotterySVG' /></div>
              <Typography>{t('Contests_HowItWorks_Step2_Use')}</Typography>
            </div>
            <div className="icon-text">
              <div className="icon"><WinIcon className='lotterySVG' /></div>
              <Typography>{t('Contests_HowItWorks_Step2_Win')}</Typography>
            </div>
          </Box>
        </Stack>
      </Stack>

      <Stack direction="row" justifyContent="end" pt={2}>
        <Link
          href={t('general_conditions_link')}
          sx={{
            cursor: "pointer",
            color: Theme.palette.grey[800],
            lineHeight: 1,
            textDecoration: 'none',
            '&:hover': {
              textDecoration: 'underline',
            }
          }}
          fontSize={12}
        >
          {t('Contests_GeneralConditions_Link') + " >"}
        </Link>
      </Stack>
    </Box >
  )
}

export default HowItWorks
