import { DocumentData, QueryDocumentSnapshot, SnapshotOptions, WithFieldValue } from "firebase/firestore";
import { Fuel } from "../model/Fuel";
import DateConverter from "./DateConverter";
import FiabilityConverter from "./FiabilityConverter";

export const FuelConverter = {
  toFirestore(Fuel: WithFieldValue<Fuel>): DocumentData {
    return { ...Fuel };
  },
  fromFirestore(
    snapshot: QueryDocumentSnapshot,
    options: SnapshotOptions
  ): Fuel {
    const data = snapshot.data(options)! ;
    return FuelConverter.fromData(data);
  },
  fromData(data:any){
    return { ...data, lastCachedPriceRefresh: DateConverter.fromData(data.lastCachedPriceRefresh), fiability: FiabilityConverter.fromData(data.fiability) } as Fuel;
  }
}
export default FuelConverter
