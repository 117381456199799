import ProfileThanks from '../components/profile/ProfileThanks'
import SimplePage from '../components/SimplePage'

const ProfileThanksPage = () => {
  return (
    <SimplePage>
      <ProfileThanks></ProfileThanks>
    </SimplePage>
  )
}

export default ProfileThanksPage
