import { DocumentData, QueryDocumentSnapshot, SnapshotOptions, WithFieldValue } from "firebase/firestore";
import { UserAvatar } from "../model/UserAvatar";

export const UserAvatarConverter = {
  toFirestore(UserAvatar: WithFieldValue<UserAvatar>): DocumentData {
    return { UserAvatar };
  },
  fromFirestore(
    snapshot: QueryDocumentSnapshot,
    options: SnapshotOptions
  ): UserAvatar{
    const data = snapshot.data(options);
    return UserAvatarConverter.fromData(data );
  },
  fromData(data: any): UserAvatar {
    return data as UserAvatar
  }
}
export default UserAvatarConverter
